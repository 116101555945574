import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class SalesforceRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesforceRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Salesforce", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("SalesforceRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Salesforce";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesforceRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Salesforce/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesforceRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <div className="card">
                        <div className="card-header">Salesforce Links</div>
                        <div className="card-block">
                            <div className="form-inline">
                                <a className="btn btn-info dgdg-icon" href="https://delgrandedealergroup.my.salesforce.com/" target="_blank" rel="noreferrer">
                                    Sales Cloud <span className="fas fa-link dgdg-icon" />
                                </a>
                                <a className="btn btn-info dgdg-icon" href="https://myapps.microsoft.com/signin/Salesforce%20Marketing%20Cloud/80a27638-f565-4d45-ae4a-e8e8847bfd1c?tenantId=5130a840-e60d-4236-a32f-19de6ccb8e2e" target="_blank" rel="noreferrer">
                                    Marketing Cloud <span className="fas fa-link dgdg-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}