import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DirectoryEmployeeAddComponent } from "./DirectoryEmployeeAddComponent";
import { DirectoryEmployeeQueryComponent } from "./DirectoryEmployeeQueryComponent";

export class DirectoryEmployeeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            employeeList: null,
            selectedRow: null,
            showDialog: false,
            isLoadingData: false,
            stores: null,
            isAdd: false,
            title: null,
            legendText: null
        };

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DirectoryEmployeeComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Directory/Employee", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getEmployeeList();
            this.getLegendText();
            this.onGetReportClick();
            CommonService.clientAzureStorageLog("DirectoryEmployeeComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Directory";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "DirectoryEmployeeComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Directory/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getEmployeeList() {
        this.props.applicationInsights.trackTrace({ message: "DirectoryEmployeeComponent/getEmployeeList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Directory/GetEmployeeList")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ employeeList: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/GetEmployeeList", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-employee-bg-red" />&nbsp;&nbsp;&nbsp;Terminated</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetReportClick(id, value, showErrorsOnly) {
        try {
            let inputData = {
                filterValue: value,
                showErrorsOnly: showErrorsOnly
            };
            this.setState({
                isLoadingData: true
            });
            this.props.applicationInsights.trackTrace({ message: "DirectoryEmployeeComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/GetEmployees", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.employeeDisplayName = responseItem.employee + ", " + responseItem.store_web_name + (responseItem.job_title ? ", " + responseItem.job_title : "");
                        responseItem.current_office_store = responseItem.current_office_store_code ? (responseItem.current_office_store_number + " | " + responseItem.current_office_store_code) : "";
                        responseItem.override_office_store = responseItem.override_office_store_code ? (responseItem.override_office_store_number + " | " + responseItem.override_office_store_code) : "";
                        responseItem.rowCssName = responseItem.status === "T" ? "dgdg-directory-employee-bg-red" : null;
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)}>
                <i className="fas fa-edit" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({
                isAdd: false,
                selectedRow: selectedRow,
                showDialog: true,
                title: "Edit Employee"
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            this.setState({
                isAdd: true,
                selectedRow: null,
                showDialog: true,
                title: "Add Employee"
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, id, employeeNumber, officeStoreNumber, extension) {
        try {
            let inputData = {
                id: id,
                employeeNumber: employeeNumber,
                officeStoreNumber: parseInt(officeStoreNumber),
                extension: extension
            };
            this.props.applicationInsights.trackTrace({ message: "DirectoryEmployeeComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/SaveEmployee", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick();
                    this.props.onShowAlert("success", { message: "Employee directory saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/onSaveClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DirectoryEmployeeComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DirectoryEmployeeAddComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showDialog}
                rowData={this.state.selectedRow}
                title={this.state.title}
                stores={this.state.stores}
                employeeList={this.state.employeeList}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelClick}
                onShowAlert={this.props.onShowAlert}
                isAdd={this.state.isAdd}
            />
            <DirectoryEmployeeQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onAddClick={this.onAddClick}
                onLookupClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} headerText="Directory Employee" paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader legendText={this.state.legendText}
                    copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="dgdg-directory-employee-action-column" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_web_name" filterColumn="store_web_name" />
                        <DGDGTableColumnComponent headerText="Name" dataColumn="employee" sortColumn="employee" filterColumn="employee" />
                        <DGDGTableColumnComponent headerText="Email" dataColumn="work_email" sortColumn="work_email" filterColumn="work_email" />
                        <DGDGTableColumnComponent headerText="Position Code" dataColumn="position_code" sortColumn="position_code" filterColumn="position_code" />
                        <DGDGTableColumnComponent headerText="Logon Email" dataColumn="logon_email" sortColumn="logon_email" filterColumn="logon_email" />
                        <DGDGTableColumnComponent headerText="Employee Status" dataColumn="status" sortColumn="status" filterColumn="status" cssName="dgdg-directory-employee-status-column" />
                        <DGDGTableColumnComponent headerText="Current Office" dataColumn="current_office_store" sortColumn="current_office_store" filterColumn="current_office_store" />
                        <DGDGTableColumnComponent headerText="Override Office" dataColumn="override_office_store" sortColumn="override_office_store" filterColumn="override_office_store" />
                        <DGDGTableColumnComponent headerText="Phone Number" dataColumn="phone" dataType="phone" sortColumn="phone" filterColumn="phone" />
                        <DGDGTableColumnComponent headerText="Extension" dataColumn="extension" sortColumn="extension" filterColumn="extension" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}