import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { TeamMemberProfileQueryComponent } from "./TeamMemberProfileQueryComponent";

export class TeamMemberProfileRouteComponent extends React.Component {
    integrityScores = [
        { key: "Strength", value: "Strength" },
        { key: "Good", value: "Good" },
        { key: "Needs Improvement", value: "Needs Improvement" }
    ];

    caringScores = [
        { key: "Strength", value: "Strength" },
        { key: "Good", value: "Good" },
        { key: "Needs Improvement", value: "Needs Improvement" }
    ];

    performanceScores = [
        { key: "Strength", value: "Strength" },
        { key: "Good", value: "Good" },
        { key: "Needs Improvement", value: "Needs Improvement" }
    ];

    efficiencyScores = [
        { key: "Strength", value: "Strength" },
        { key: "Good", value: "Good" },
        { key: "Needs Improvement", value: "Needs Improvement" }
    ];

    potentialScores = [
        { key: "Current Level", value: "Current Level" },
        { key: "One Level", value: "One Level" },
        { key: "Two Levels", value: "Two Levels" }
    ];

    readinessScores = [
        { key: "Now", value: "Now" },
        { key: "6-12 Months", value: "6-12 Months" },
        { key: "1+ Years", value: "1+ Years" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            loggedInEmployeeNumber: "",
            profile: null,
            isInLeadershipRole: false,
            canContribute: false,
            canContributeInLeadership: false,

            workHistoryOrdinal: null,
            previousEmployer: "",
            previousJobTitle: "",
            timeInPosition: "",
            workHistoryList: [],
            showAddWorkHistory: false,
            showWorkHistorySpinner: false,
            deleteWorkHistoryItem: null,

            skillsToImproveOrdinal: null,
            skillsToImprove: "",
            skillsToImproveList: [],
            showAddSkillsToImprove: false,
            showSkillsToImproveSpinner: false,
            deleteSkillsToImproveItem: null,

            skillsToLearnMoreOrdinal: null,
            skillsToLearnMore: "",
            skillsToLearnMoreList: [],
            showAddSkillsToLearnMore: false,
            showSkillsToLearnMoreSpinner: false,
            deleteSkillsToLearnMoreItem: null,

            skillsWhichNeedExposureOrdinal: null,
            skillsWhichNeedExposure: "",
            skillsWhichNeedExposureList: [],
            showAddSkillsWhichNeedExposure: false,
            showSkillsWhichNeedExposureSpinner: false,
            deleteSkillsWhichNeedExposureItem: null,

            actionPlanOrdinal: null,
            actionPlan: "",
            actionPlanList: [],
            showAddActionPlan: false,
            showActionPlanSpinner: false,
            deleteActionPlanItem: null,

            strengthOrdinal: null,
            strength: "",
            strengthList: [],
            showAddStrength: false,
            showStrengthSpinner: false,
            deleteStrengthItem: null,

            developmentOpportunityOrdinal: null,
            developmentOpportunity: "",
            developmentOpportunityList: [],
            showAddDevelopmentOpportunity: false,
            showDevelopmentOpportunitySpinner: false,
            deleteDevelopmentOpportunityItem: null,

            developmentPlanOrdinal: null,
            developmentPlan: "",
            developmentPlanList: [],
            showAddDevelopmentPlan: false,
            showDevelopmentPlanSpinner: false,
            deleteDevelopmentPlanItem: null,

            leadershipNoteOrdinal: null,
            leadershipNote: "",
            leadershipNoteList: [],
            showAddLeadershipNote: false,
            showLeadershipNoteSpinner: false,
            deleteLeadershipNoteItem: null,

            longTermGoal: "",
            geographicalLimitation: "",
            otherDevelopment: "",
            selectedIntegrityScore: null,
            selectedCaringScore: null,
            selectedPerformanceScore: null,
            selectedEfficiencyScore: null,
            selectedPotentialScore: null,
            selectedReadinessScore: null,

            isLoadingData: false,
            showFindEmployee: false,
            employeeProfiles: []
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);

        this.onFindEmployeeClick = this.onFindEmployeeClick.bind(this);

        this.onToggleAddWorkHistoryList = this.onToggleAddWorkHistoryList.bind(this);
        this.onEditWorkHistoryItemClick = this.onEditWorkHistoryItemClick.bind(this);
        this.onDeleteWorkHistoryItemClick = this.onDeleteWorkHistoryItemClick.bind(this);
        this.onDeleteWorkHistoryOkClick = this.onDeleteWorkHistoryOkClick.bind(this);
        this.onDeleteWorkHistoryCancelClick = this.onDeleteWorkHistoryCancelClick.bind(this);
        this.onSaveWorkHistoryListClick = this.onSaveWorkHistoryListClick.bind(this);

        this.onToggleAddSkillsToImproveList = this.onToggleAddSkillsToImproveList.bind(this);
        this.onEditSkillsToImproveItemClick = this.onEditSkillsToImproveItemClick.bind(this);
        this.onDeleteSkillsToImproveItemClick = this.onDeleteSkillsToImproveItemClick.bind(this);
        this.onDeleteSkillsToImproveOkClick = this.onDeleteSkillsToImproveOkClick.bind(this);
        this.onDeleteSkillsToImproveCancelClick = this.onDeleteSkillsToImproveCancelClick.bind(this);
        this.onSaveSkillsToImproveListClick = this.onSaveSkillsToImproveListClick.bind(this);
        this.onSaveSkillsToImproveListKeyDown = this.onSaveSkillsToImproveListKeyDown.bind(this);

        this.onToggleAddSkillsToLearnMoreList = this.onToggleAddSkillsToLearnMoreList.bind(this);
        this.onEditSkillsToLearnMoreItemClick = this.onEditSkillsToLearnMoreItemClick.bind(this);
        this.onDeleteSkillsToLearnMoreItemClick = this.onDeleteSkillsToLearnMoreItemClick.bind(this);
        this.onDeleteSkillsToLearnMoreOkClick = this.onDeleteSkillsToLearnMoreOkClick.bind(this);
        this.onDeleteSkillsToLearnMoreCancelClick = this.onDeleteSkillsToLearnMoreCancelClick.bind(this);
        this.onSaveSkillsToLearnMoreListClick = this.onSaveSkillsToLearnMoreListClick.bind(this);
        this.onSaveSkillsToLearnMoreListKeyDown = this.onSaveSkillsToLearnMoreListKeyDown.bind(this);

        this.onToggleAddSkillsWhichNeedExposureList = this.onToggleAddSkillsWhichNeedExposureList.bind(this);
        this.onEditSkillsWhichNeedExposureItemClick = this.onEditSkillsWhichNeedExposureItemClick.bind(this);
        this.onDeleteSkillsWhichNeedExposureItemClick = this.onDeleteSkillsWhichNeedExposureItemClick.bind(this);
        this.onDeleteSkillsWhichNeedExposureOkClick = this.onDeleteSkillsWhichNeedExposureOkClick.bind(this);
        this.onDeleteSkillsWhichNeedExposureCancelClick = this.onDeleteSkillsWhichNeedExposureCancelClick.bind(this);
        this.onSaveSkillsWhichNeedExposureListClick = this.onSaveSkillsWhichNeedExposureListClick.bind(this);
        this.onSaveSkillsWhichNeedExposureListKeyDown = this.onSaveSkillsWhichNeedExposureListKeyDown.bind(this);

        this.onToggleAddActionPlanList = this.onToggleAddActionPlanList.bind(this);
        this.onEditActionPlanItemClick = this.onEditActionPlanItemClick.bind(this);
        this.onDeleteActionPlanItemClick = this.onDeleteActionPlanItemClick.bind(this);
        this.onDeleteActionPlanOkClick = this.onDeleteActionPlanOkClick.bind(this);
        this.onDeleteActionPlanCancelClick = this.onDeleteActionPlanCancelClick.bind(this);
        this.onSaveActionPlanListClick = this.onSaveActionPlanListClick.bind(this);
        this.onSaveActionPlanListKeyDown = this.onSaveActionPlanListKeyDown.bind(this);

        this.onToggleAddStrengthList = this.onToggleAddStrengthList.bind(this);
        this.onEditStrengthItemClick = this.onEditStrengthItemClick.bind(this);
        this.onDeleteStrengthItemClick = this.onDeleteStrengthItemClick.bind(this);
        this.onDeleteStrengthOkClick = this.onDeleteStrengthOkClick.bind(this);
        this.onDeleteStrengthCancelClick = this.onDeleteStrengthCancelClick.bind(this);
        this.onSaveStrengthListClick = this.onSaveStrengthListClick.bind(this);
        this.onSaveStrengthListKeyDown = this.onSaveStrengthListKeyDown.bind(this);

        this.onToggleAddDevelopmentOpportunityList = this.onToggleAddDevelopmentOpportunityList.bind(this);
        this.onEditDevelopmentOpportunityItemClick = this.onEditDevelopmentOpportunityItemClick.bind(this);
        this.onDeleteDevelopmentOpportunityItemClick = this.onDeleteDevelopmentOpportunityItemClick.bind(this);
        this.onDeleteDevelopmentOpportunityOkClick = this.onDeleteDevelopmentOpportunityOkClick.bind(this);
        this.onDeleteDevelopmentOpportunityCancelClick = this.onDeleteDevelopmentOpportunityCancelClick.bind(this);
        this.onSaveDevelopmentOpportunityListClick = this.onSaveDevelopmentOpportunityListClick.bind(this);
        this.onSaveDevelopmentOpportunityListKeyDown = this.onSaveDevelopmentOpportunityListKeyDown.bind(this);

        this.onToggleAddDevelopmentPlanList = this.onToggleAddDevelopmentPlanList.bind(this);
        this.onEditDevelopmentPlanItemClick = this.onEditDevelopmentPlanItemClick.bind(this);
        this.onDeleteDevelopmentPlanItemClick = this.onDeleteDevelopmentPlanItemClick.bind(this);
        this.onDeleteDevelopmentPlanOkClick = this.onDeleteDevelopmentPlanOkClick.bind(this);
        this.onDeleteDevelopmentPlanCancelClick = this.onDeleteDevelopmentPlanCancelClick.bind(this);
        this.onSaveDevelopmentPlanListClick = this.onSaveDevelopmentPlanListClick.bind(this);
        this.onSaveDevelopmentPlanListKeyDown = this.onSaveDevelopmentPlanListKeyDown.bind(this);

        this.onToggleAddLeadershipNoteList = this.onToggleAddLeadershipNoteList.bind(this);
        this.onEditLeadershipNoteItemClick = this.onEditLeadershipNoteItemClick.bind(this);
        this.onDeleteLeadershipNoteItemClick = this.onDeleteLeadershipNoteItemClick.bind(this);
        this.onDeleteLeadershipNoteOkClick = this.onDeleteLeadershipNoteOkClick.bind(this);
        this.onDeleteLeadershipNoteCancelClick = this.onDeleteLeadershipNoteCancelClick.bind(this);
        this.onSaveLeadershipNoteListClick = this.onSaveLeadershipNoteListClick.bind(this);
        this.onSaveLeadershipNoteListKeyDown = this.onSaveLeadershipNoteListKeyDown.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            console.devLog("TeamMemberProfileRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/OrgChart/Store", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            this.getEmployeeProfiles();
            CommonService.clientAzureStorageLog("TeamMemberProfileRouteComponent", "ProfileRouteComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Team Member Profile";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "TeamMemberProfileRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getProfile(employeeNumber) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                employeeNumber: employeeNumber
            };

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/getControllerPolicy", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/GetProfile", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.table.length === 1 && this.state.employeeProfiles.filter(employeeProfile => employeeProfile.employee_number === parsedResponse.table[0].employee_number).length === 1) {
                        let profile, isInLeadershipRole, canContribute, canContributeInLeadership, workHistoryList = [], longTermGoal = "", skillsToImproveList = [], skillsToLearnMoreList = [], skillsWhichNeedExposureList = [],
                            geographicalLimitation = "", otherDevelopment = "", actionPlanList = [], strengthList = [], developmentOpportunityList = [], developmentPlanList = [], leadershipNoteList = [],
                            selectedIntegrityScore = null, selectedCaringScore = null, selectedPerformanceScore = null, selectedEfficiencyScore = null, selectedPotentialScore = null, selectedReadinessScore = null;

                        profile = parsedResponse.table[0];
                        isInLeadershipRole = parsedResponse.isInLeadershipRole;
                        canContribute = parsedResponse.canContribute;
                        canContributeInLeadership = parsedResponse.canContributeInLeadership;

                        if (parsedResponse.table1.length > 0) {
                            longTermGoal = parsedResponse.table1.filter(item => item.attribute_name === "Long Term Goal")[0];
                            if (longTermGoal) {
                                longTermGoal = longTermGoal.attribute_value;
                            }

                            geographicalLimitation = parsedResponse.table1.filter(item => item.attribute_name === "Geographical Limitation")[0];
                            if (geographicalLimitation) {
                                geographicalLimitation = geographicalLimitation.attribute_value;
                            }

                            otherDevelopment = parsedResponse.table1.filter(item => item.attribute_name === "Other Development")[0];
                            if (otherDevelopment) {
                                otherDevelopment = otherDevelopment.attribute_value;
                            }

                            selectedIntegrityScore = parsedResponse.table1.filter(item => item.attribute_name === "Integrity")[0];
                            if (selectedIntegrityScore) {
                                selectedIntegrityScore = selectedIntegrityScore.attribute_value;
                                selectedIntegrityScore = this.integrityScores.filter(score => score.key === selectedIntegrityScore)[0];
                            }

                            selectedCaringScore = parsedResponse.table1.filter(item => item.attribute_name === "Caring")[0];
                            if (selectedCaringScore) {
                                selectedCaringScore = selectedCaringScore.attribute_value;
                                selectedCaringScore = this.caringScores.filter(score => score.key === selectedCaringScore)[0];
                            }

                            selectedPerformanceScore = parsedResponse.table1.filter(item => item.attribute_name === "Performance")[0];
                            if (selectedPerformanceScore) {
                                selectedPerformanceScore = selectedPerformanceScore.attribute_value;
                                selectedPerformanceScore = this.performanceScores.filter(score => score.key === selectedPerformanceScore)[0];
                            }

                            selectedEfficiencyScore = parsedResponse.table1.filter(item => item.attribute_name === "Efficiency")[0];
                            if (selectedEfficiencyScore) {
                                selectedEfficiencyScore = selectedEfficiencyScore.attribute_value;
                                selectedEfficiencyScore = this.efficiencyScores.filter(score => score.key === selectedEfficiencyScore)[0];
                            }

                            selectedPotentialScore = parsedResponse.table1.filter(item => item.attribute_name === "Potential")[0];
                            if (selectedPotentialScore) {
                                selectedPotentialScore = selectedPotentialScore.attribute_value;
                                selectedPotentialScore = this.potentialScores.filter(score => score.key === selectedPotentialScore)[0];
                            }

                            selectedReadinessScore = parsedResponse.table1.filter(item => item.attribute_name === "Readiness")[0];
                            if (selectedReadinessScore) {
                                selectedReadinessScore = selectedReadinessScore.attribute_value;
                                selectedReadinessScore = this.readinessScores.filter(score => score.key === selectedReadinessScore)[0];
                            }

                            workHistoryList = parsedResponse.table1.filter(item => (item.attribute_name === "Work History Emp") || (item.attribute_name === "Work History Title") || (item.attribute_name === "Work History Duration"));
                            skillsToImproveList = parsedResponse.table1.filter(item => item.attribute_name === "Skill To Improve");
                            skillsToLearnMoreList = parsedResponse.table1.filter(item => item.attribute_name === "Skill To Learn More");
                            skillsWhichNeedExposureList = parsedResponse.table1.filter(item => item.attribute_name === "Skill Which Needs Exposure");
                            actionPlanList = parsedResponse.table1.filter(item => item.attribute_name === "Action Plan");
                            strengthList = parsedResponse.table1.filter(item => item.attribute_name === "Strength");
                            developmentOpportunityList = parsedResponse.table1.filter(item => item.attribute_name === "Development Opportunity");
                            developmentPlanList = parsedResponse.table1.filter(item => item.attribute_name === "Development Plan");
                            leadershipNoteList = parsedResponse.table1.filter(item => item.attribute_name === "Leadership Note");
                        }

                        this.setState({
                            loggedInEmployeeNumber: parsedResponse.employeeNumber,
                            profile: profile,
                            isInLeadershipRole: isInLeadershipRole,
                            canContribute: canContribute,
                            canContributeInLeadership: canContributeInLeadership,
                            workHistoryList: workHistoryList,
                            longTermGoal: longTermGoal,
                            skillsToImproveList: skillsToImproveList,
                            skillsToLearnMoreList: skillsToLearnMoreList,
                            skillsWhichNeedExposureList: skillsWhichNeedExposureList,
                            geographicalLimitation: geographicalLimitation,
                            otherDevelopment: otherDevelopment,
                            actionPlanList: actionPlanList,
                            strengthList: strengthList,
                            developmentOpportunityList: developmentOpportunityList,
                            developmentPlanList: developmentPlanList,
                            leadershipNoteList: leadershipNoteList,
                            selectedIntegrityScore: selectedIntegrityScore,
                            selectedCaringScore: selectedCaringScore,
                            selectedPerformanceScore: selectedPerformanceScore,
                            selectedEfficiencyScore: selectedEfficiencyScore,
                            selectedPotentialScore: selectedPotentialScore,
                            selectedReadinessScore: selectedReadinessScore
                        });
                    }
                    else {
                        this.resetProfile(parsedResponse.isInLeadershipRole, parsedResponse.canContribute, parsedResponse.canContributeInLeadership);
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetProfile", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    resetProfile(isInLeadershipRole, canContribute, canContributeInLeadership) {
        this.setState({
            isInLeadershipRole: isInLeadershipRole,
            canContribute: canContribute,
            canContributeInLeadership: canContributeInLeadership,
            workHistoryList: [],
            longTermGoal: "",
            skillsToImproveList: [],
            skillsToLearnMoreList: [],
            skillsWhichNeedExposureList: [],
            geographicalLimitation: "",
            otherDevelopment: "",
            actionPlanList: [],
            strengthList: [],
            developmentOpportunityList: [],
            developmentPlanList: [],
            selectedIntegrityScore: null,
            selectedCaringScore: null,
            selectedPerformanceScore: null,
            selectedEfficiencyScore: null,
            selectedPotentialScore: null,
            leadershipNoteList: [],
            selectedReadinessScore: null,
        });
    }

    getEmployeeProfiles() {
        this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/getEmployeeProfiles", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/TeamMemberProfile/GetEmployeeProfiles")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse.forEach((item) => {
                    item.text = item.store_number + " | " + item.store_name + ", " + item.employee_first + " " + item.employee_last + ", " + item.position_description
                });
                this.setState({
                    employeeProfiles: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetEmployeeProfiles", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDropdownChange(id, selectedDdpData) {
        try {
            this.setState({ [id]: selectedDdpData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = [
                {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Long Term Goal",
                    attribute_value: this.state.longTermGoal
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Geographical Limitation",
                    attribute_value: this.state.geographicalLimitation
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Other Development",
                    attribute_value: this.state.otherDevelopment
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Integrity",
                    attribute_value: this.state.selectedIntegrityScore ? this.state.selectedIntegrityScore.value : null
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Caring",
                    attribute_value: this.state.selectedCaringScore ? this.state.selectedCaringScore.value : null
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Performance",
                    attribute_value: this.state.selectedPerformanceScore ? this.state.selectedPerformanceScore.value : null
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Efficiency",
                    attribute_value: this.state.selectedEfficiencyScore ? this.state.selectedEfficiencyScore.value : null
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Potential",
                    attribute_value: this.state.selectedPotentialScore ? this.state.selectedPotentialScore.value : null
                }, {
                    store_number: this.state.profile.store_number,
                    employee_number: this.state.profile.employee_number,
                    updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    updated_by: this.props.remoteUpn,
                    ordinal: 1,
                    attribute_name: "Readiness",
                    attribute_value: this.state.selectedReadinessScore ? this.state.selectedReadinessScore.value : null
                }
            ];

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onShowAlert("success", { message: "Employee Profile saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showSpinner: false,
                        isLoadingData: false
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFindEmployeeClick(event, employeeProfile) {
        try {
            this.getProfile(employeeProfile.employee_number);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getWorkHistoryList() {
        let htmlContent = [];
        for (let index = 0; index < this.state.workHistoryList.length;) {
            let itemOrdinal = this.state.workHistoryList[index].ordinal;
            htmlContent.push(
                <tr key={"WH" + itemOrdinal}>
                    <td style={{ "minWidth": "60px" }}>
                        <li>
                            {
                                this.state.canContribute
                                    ? <Fragment>
                                        <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditWorkHistoryItemClick(event, itemOrdinal)}>
                                            <i className="fas fa-edit" />
                                        </button>
                                        <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteWorkHistoryItemClick(event, itemOrdinal)}>
                                            <i className="fas fa-times text-danger" />
                                        </button>
                                    </Fragment>
                                    : null
                            }
                        </li>
                    </td>
                    <td style={{ "padding": "0px 5px" }}>{this.state.workHistoryList.filter(item => item.ordinal === itemOrdinal && item.attribute_name === 'Work History Emp')[0].attribute_value}.</td>
                    <td style={{ "padding": "0px 5px" }}>{this.state.workHistoryList.filter(item => item.ordinal === itemOrdinal && item.attribute_name === 'Work History Title')[0].attribute_value}.</td>
                    <td style={{ "padding": "0px 5px" }}>{this.state.workHistoryList.filter(item => item.ordinal === itemOrdinal && item.attribute_name === 'Work History Duration')[0].attribute_value}.</td>
                </tr>);

            index += 3;
        }

        return <ol>
            <table>
                <tbody>
                    <tr>
                        <td />
                        <td className="text-nowrap font-weight-bold" style={{ "padding": "0px 5px" }}>Previous Employer</td>
                        <td className="text-nowrap font-weight-bold" style={{ "padding": "0px 5px" }}>Previous Job Title</td>
                        <td className="text-nowrap font-weight-bold" style={{ "padding": "0px 5px" }}>Time in Position</td>
                    </tr>
                    {htmlContent}
                </tbody>
            </table>
        </ol>;
    }

    saveWorkHistoryList(workHistoryList, stateWorkHistoryList) {
        try {
            this.setState({ showWorkHistorySpinner: true });
            let inputData = workHistoryList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveWorkHistoryList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        workHistoryOrdinal: null,
                        workHistoryList: stateWorkHistoryList
                    });
                    this.props.onShowAlert("success", { message: "Work history saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showWorkHistorySpinner: false,
                        showAddWorkHistory: false,
                        previousEmployer: "",
                        previousJobTitle: "",
                        timeInPosition: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddWorkHistoryList(event) {
        this.setState({
            showAddWorkHistory: this.state.showAddWorkHistory ? false : true,
            workHistoryOrdinal: null
        });
    }

    onEditWorkHistoryItemClick(event, workHistoryOrdinal) {
        let employer = this.state.workHistoryList.filter(item => item.ordinal === workHistoryOrdinal && item.attribute_name === "Work History Emp")[0];
        let title = this.state.workHistoryList.filter(item => item.ordinal === workHistoryOrdinal && item.attribute_name === "Work History Title")[0];
        let duration = this.state.workHistoryList.filter(item => item.ordinal === workHistoryOrdinal && item.attribute_name === "Work History Duration")[0];
        this.setState({
            showAddWorkHistory: true,
            workHistoryOrdinal: workHistoryOrdinal,
            previousEmployer: employer.attribute_value,
            previousJobTitle: title.attribute_value,
            timeInPosition: duration.attribute_value
        });
    }

    onDeleteWorkHistoryItemClick(event, workHistoryOrdinal) {
        let deleteWorkHistoryItem = this.state.workHistoryList.filter(item => item.ordinal === workHistoryOrdinal && item.attribute_name === "Work History Emp")[0];
        this.setState({
            deleteWorkHistoryItem: deleteWorkHistoryItem,
            workHistoryOrdinal: workHistoryOrdinal
        });
    }

    onDeleteWorkHistoryOkClick(event) {
        let stateWorkHistoryList = this.state.workHistoryList.filter((item) => item.ordinal !== this.state.deleteWorkHistoryItem.ordinal);
        let deleteWorkHistoryList = this.state.workHistoryList.filter((item) => item.ordinal === this.state.deleteWorkHistoryItem.ordinal);

        stateWorkHistoryList.forEach((item) => {
            if (item.ordinal > this.state.deleteWorkHistoryItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteWorkHistoryList.forEach((item) => {
            item.delete = true;
        });

        this.saveWorkHistoryList(deleteWorkHistoryList, stateWorkHistoryList);
        this.onDeleteWorkHistoryCancelClick(event)
    }

    onDeleteWorkHistoryCancelClick(event) {
        this.setState({
            deleteWorkHistoryItem: null,
            workHistoryOrdinal: null
        });
    }

    onSaveWorkHistoryListClick(event) {
        let workHistoryList = [];
        let stateWorkHistoryList = this.state.workHistoryList;
        if (this.state.workHistoryOrdinal !== null) {
            let employer = this.state.workHistoryList.filter(item => item.ordinal === this.state.workHistoryOrdinal && item.attribute_name === "Work History Emp")[0];
            let title = this.state.workHistoryList.filter(item => item.ordinal === this.state.workHistoryOrdinal && item.attribute_name === "Work History Title")[0];
            let duration = this.state.workHistoryList.filter(item => item.ordinal === this.state.workHistoryOrdinal && item.attribute_name === "Work History Duration")[0];

            employer.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            employer.updated_by = this.props.remoteUpn;
            employer.attribute_value = this.state.previousEmployer;

            title.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            title.updated_by = this.props.remoteUpn;
            title.attribute_value = this.state.previousJobTitle;

            duration.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            duration.updated_by = this.props.remoteUpn;
            duration.attribute_value = this.state.timeInPosition;

            workHistoryList.push(employer);
            workHistoryList.push(title);
            workHistoryList.push(duration);
        } else {
            let ordinal = this.state.workHistoryList.length / 3;
            ordinal++;
            workHistoryList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Work History Emp",
                attribute_value: this.state.previousEmployer
            }, {
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Work History Title",
                attribute_value: this.state.previousJobTitle
            }, {
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Work History Duration",
                attribute_value: this.state.timeInPosition
            });

            stateWorkHistoryList.push(workHistoryList[0], workHistoryList[1], workHistoryList[2]);
        }

        this.saveWorkHistoryList(workHistoryList, stateWorkHistoryList);
    }

    getSkillsToImproveList() {
        let htmlContent = [];
        this.state.skillsToImproveList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STI" + itemIndex}>
                    {
                        this.state.canContribute
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditSkillsToImproveItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteSkillsToImproveItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveSkillsToImproveList(skillsToImproveList, stateSkillsToImproveList) {
        try {
            this.setState({ showSkillsToImproveSpinner: true });
            let inputData = skillsToImproveList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveWorkHistoryList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        skillsToImproveOrdinal: null,
                        skillsToImproveList: stateSkillsToImproveList
                    });
                    this.props.onShowAlert("success", { message: "Skills to Improve saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showSkillsToImproveSpinner: false,
                        showAddSkillsToImprove: false,
                        skillsToImprove: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddSkillsToImproveList(event) {
        this.setState({
            showAddSkillsToImprove: this.state.showAddSkillsToImprove ? false : true,
            skillsToImproveOrdinal: null
        });
    }

    onEditSkillsToImproveItemClick(event, skillsToImproveOrdinal) {
        let skillsToImprove = this.state.skillsToImproveList.filter(item => item.ordinal === skillsToImproveOrdinal)[0];
        this.setState({
            showAddSkillsToImprove: true,
            skillsToImproveOrdinal: skillsToImproveOrdinal,
            skillsToImprove: skillsToImprove.attribute_value
        });
    }

    onDeleteSkillsToImproveItemClick(event, skillsToImproveOrdinal) {
        let deleteSkillsToImproveItem = this.state.skillsToImproveList.filter(item => item.ordinal === skillsToImproveOrdinal)[0];
        this.setState({
            deleteSkillsToImproveItem: deleteSkillsToImproveItem,
            skillsToImproveOrdinal: skillsToImproveOrdinal
        });
    }

    onDeleteSkillsToImproveOkClick(event) {
        let stateSkillsToImproveList = this.state.skillsToImproveList.filter((item) => item.ordinal !== this.state.deleteSkillsToImproveItem.ordinal);
        let deleteSkillsToImproveList = this.state.skillsToImproveList.filter((item) => item.ordinal === this.state.deleteSkillsToImproveItem.ordinal);

        stateSkillsToImproveList.forEach((item) => {
            if (item.ordinal > this.state.deleteSkillsToImproveItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteSkillsToImproveList.forEach((item) => {
            item.delete = true;
        });

        this.saveSkillsToImproveList(deleteSkillsToImproveList, stateSkillsToImproveList);
        this.onDeleteSkillsToImproveCancelClick(event)
    }

    onDeleteSkillsToImproveCancelClick(event) {
        this.setState({
            deleteSkillsToImproveItem: null,
            skillsToImproveOrdinal: null
        });
    }

    onSaveSkillsToImproveListClick(event) {
        let skillsToImproveList = [];
        let stateSkillsToImproveList = this.state.skillsToImproveList;
        if (this.state.skillsToImproveOrdinal !== null) {
            let skillsToImprove = this.state.skillsToImproveList.filter(item => item.ordinal === this.state.skillsToImproveOrdinal)[0];

            skillsToImprove.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            skillsToImprove.updated_by = this.props.remoteUpn;
            skillsToImprove.attribute_value = this.state.skillsToImprove;

            skillsToImproveList.push(skillsToImprove);
        } else {
            let ordinal = this.state.skillsToImproveList.length + 1;
            skillsToImproveList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Skill To Improve",
                attribute_value: this.state.skillsToImprove
            });

            stateSkillsToImproveList.push(skillsToImproveList[0]);
        }

        this.saveSkillsToImproveList(skillsToImproveList, stateSkillsToImproveList);
    }

    onSaveSkillsToImproveListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveSkillsToImproveListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getSkillsToLearnMoreList() {
        let htmlContent = [];
        this.state.skillsToLearnMoreList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditSkillsToLearnMoreItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteSkillsToLearnMoreItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveSkillsToLearnMoreList(skillsToLearnMoreList, stateSkillsToLearnMoreList) {
        try {
            this.setState({ showSkillsToLearnMoreSpinner: true });
            let inputData = skillsToLearnMoreList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveSkillsToLearnMoreList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        skillsToLearnMoreOrdinal: null,
                        skillsToLearnMoreList: stateSkillsToLearnMoreList
                    });
                    this.props.onShowAlert("success", { message: "Skills to Learn More saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showSkillsToLearnMoreSpinner: false,
                        showAddSkillsToLearnMore: false,
                        skillsToLearnMore: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddSkillsToLearnMoreList(event) {
        this.setState({
            showAddSkillsToLearnMore: this.state.showAddSkillsToLearnMore ? false : true,
            skillsToLearnMoreOrdinal: null
        });
    }

    onEditSkillsToLearnMoreItemClick(event, skillsToLearnMoreOrdinal) {
        let skillsToLearnMore = this.state.skillsToLearnMoreList.filter(item => item.ordinal === skillsToLearnMoreOrdinal)[0];
        this.setState({
            showAddSkillsToLearnMore: true,
            skillsToLearnMoreOrdinal: skillsToLearnMoreOrdinal,
            skillsToLearnMore: skillsToLearnMore.attribute_value
        });
    }

    onDeleteSkillsToLearnMoreItemClick(event, skillsToLearnMoreOrdinal) {
        let deleteSkillsToLearnMoreItem = this.state.skillsToLearnMoreList.filter(item => item.ordinal === skillsToLearnMoreOrdinal)[0];
        this.setState({
            deleteSkillsToLearnMoreItem: deleteSkillsToLearnMoreItem,
            skillsToLearnMoreOrdinal: skillsToLearnMoreOrdinal
        });
    }

    onDeleteSkillsToLearnMoreOkClick(event) {
        let stateSkillsToLearnMoreList = this.state.skillsToLearnMoreList.filter((item) => item.ordinal !== this.state.deleteSkillsToLearnMoreItem.ordinal);
        let deleteSkillsToLearnMoreList = this.state.skillsToLearnMoreList.filter((item) => item.ordinal === this.state.deleteSkillsToLearnMoreItem.ordinal);

        stateSkillsToLearnMoreList.forEach((item) => {
            if (item.ordinal > this.state.deleteSkillsToLearnMoreItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteSkillsToLearnMoreList.forEach((item) => {
            item.delete = true;
        });

        this.saveSkillsToLearnMoreList(deleteSkillsToLearnMoreList, stateSkillsToLearnMoreList);
        this.onDeleteSkillsToLearnMoreCancelClick(event)
    }

    onDeleteSkillsToLearnMoreCancelClick(event) {
        this.setState({
            deleteSkillsToLearnMoreItem: null,
            skillsToLearnMoreOrdinal: null
        });
    }

    onSaveSkillsToLearnMoreListClick(event) {
        let skillsToLearnMoreList = [];
        let stateSkillsToLearnMoreList = this.state.skillsToLearnMoreList;
        if (this.state.skillsToLearnMoreOrdinal !== null) {
            let skillsToLearnMore = this.state.skillsToLearnMoreList.filter(item => item.ordinal === this.state.skillsToLearnMoreOrdinal)[0];

            skillsToLearnMore.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            skillsToLearnMore.updated_by = this.props.remoteUpn;
            skillsToLearnMore.attribute_value = this.state.skillsToLearnMore;

            skillsToLearnMoreList.push(skillsToLearnMore);
        } else {
            let ordinal = this.state.skillsToLearnMoreList.length + 1;
            skillsToLearnMoreList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Skill To Learn More",
                attribute_value: this.state.skillsToLearnMore
            });

            stateSkillsToLearnMoreList.push(skillsToLearnMoreList[0]);
        }

        this.saveSkillsToLearnMoreList(skillsToLearnMoreList, stateSkillsToLearnMoreList);
    }

    onSaveSkillsToLearnMoreListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveSkillsToLearnMoreListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getSkillsWhichNeedExposureList() {
        let htmlContent = [];
        this.state.skillsWhichNeedExposureList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditSkillsWhichNeedExposureItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteSkillsWhichNeedExposureItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveSkillsWhichNeedExposureList(skillsWhichNeedExposureList, stateSkillsWhichNeedExposureList) {
        try {
            this.setState({ showSkillsWhichNeedExposureSpinner: true });
            let inputData = skillsWhichNeedExposureList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveSkillsWhichNeedExposureList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        skillsWhichNeedExposureOrdinal: null,
                        skillsWhichNeedExposureList: stateSkillsWhichNeedExposureList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showSkillsWhichNeedExposureSpinner: false,
                        showAddSkillsWhichNeedExposure: false,
                        skillsWhichNeedExposure: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddSkillsWhichNeedExposureList(event) {
        this.setState({
            showAddSkillsWhichNeedExposure: this.state.showAddSkillsWhichNeedExposure ? false : true,
            skillsWhichNeedExposureOrdinal: null
        });
    }

    onEditSkillsWhichNeedExposureItemClick(event, skillsWhichNeedExposureOrdinal) {
        let skillsWhichNeedExposure = this.state.skillsWhichNeedExposureList.filter(item => item.ordinal === skillsWhichNeedExposureOrdinal)[0];
        this.setState({
            showAddSkillsWhichNeedExposure: true,
            skillsWhichNeedExposureOrdinal: skillsWhichNeedExposureOrdinal,
            skillsWhichNeedExposure: skillsWhichNeedExposure.attribute_value
        });
    }

    onDeleteSkillsWhichNeedExposureItemClick(event, skillsWhichNeedExposureOrdinal) {
        let deleteSkillsWhichNeedExposureItem = this.state.skillsWhichNeedExposureList.filter(item => item.ordinal === skillsWhichNeedExposureOrdinal)[0];
        this.setState({
            deleteSkillsWhichNeedExposureItem: deleteSkillsWhichNeedExposureItem,
            skillsWhichNeedExposureOrdinal: skillsWhichNeedExposureOrdinal
        });
    }

    onDeleteSkillsWhichNeedExposureOkClick(event) {
        let stateSkillsWhichNeedExposureList = this.state.skillsWhichNeedExposureList.filter((item) => item.ordinal !== this.state.deleteSkillsWhichNeedExposureItem.ordinal);
        let deleteSkillsWhichNeedExposureList = this.state.skillsWhichNeedExposureList.filter((item) => item.ordinal === this.state.deleteSkillsWhichNeedExposureItem.ordinal);

        stateSkillsWhichNeedExposureList.forEach((item) => {
            if (item.ordinal > this.state.deleteSkillsWhichNeedExposureItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteSkillsWhichNeedExposureList.forEach((item) => {
            item.delete = true;
        });

        this.saveSkillsWhichNeedExposureList(deleteSkillsWhichNeedExposureList, stateSkillsWhichNeedExposureList);
        this.onDeleteSkillsWhichNeedExposureCancelClick(event)
    }

    onDeleteSkillsWhichNeedExposureCancelClick(event) {
        this.setState({
            deleteSkillsWhichNeedExposureItem: null,
            skillsWhichNeedExposureOrdinal: null
        });
    }

    onSaveSkillsWhichNeedExposureListClick(event) {
        let skillsWhichNeedExposureList = [];
        let stateSkillsWhichNeedExposureList = this.state.skillsWhichNeedExposureList;
        if (this.state.skillsWhichNeedExposureOrdinal !== null) {
            let skillsWhichNeedExposure = this.state.skillsWhichNeedExposureList.filter(item => item.ordinal === this.state.skillsWhichNeedExposureOrdinal)[0];

            skillsWhichNeedExposure.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            skillsWhichNeedExposure.updated_by = this.props.remoteUpn;
            skillsWhichNeedExposure.attribute_value = this.state.skillsWhichNeedExposure;

            skillsWhichNeedExposureList.push(skillsWhichNeedExposure);
        } else {
            let ordinal = this.state.skillsWhichNeedExposureList.length + 1;
            skillsWhichNeedExposureList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Skill Which Needs Exposure",
                attribute_value: this.state.skillsWhichNeedExposure
            });

            stateSkillsWhichNeedExposureList.push(skillsWhichNeedExposureList[0]);
        }

        this.saveSkillsWhichNeedExposureList(skillsWhichNeedExposureList, stateSkillsWhichNeedExposureList);
    }

    onSaveSkillsWhichNeedExposureListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveSkillsWhichNeedExposureListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getActionPlanList() {
        let htmlContent = [];
        this.state.actionPlanList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditActionPlanItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteActionPlanItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveActionPlanList(actionPlanList, stateActionPlanList) {
        try {
            this.setState({ showActionPlanSpinner: true });
            let inputData = actionPlanList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveActionPlanList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        actionPlanOrdinal: null,
                        actionPlanList: stateActionPlanList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showActionPlanSpinner: false,
                        showAddActionPlan: false,
                        actionPlan: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddActionPlanList(event) {
        this.setState({
            showAddActionPlan: this.state.showAddActionPlan ? false : true,
            actionPlanOrdinal: null
        });
    }

    onEditActionPlanItemClick(event, actionPlanOrdinal) {
        let actionPlan = this.state.actionPlanList.filter(item => item.ordinal === actionPlanOrdinal)[0];
        this.setState({
            showAddActionPlan: true,
            actionPlanOrdinal: actionPlanOrdinal,
            actionPlan: actionPlan.attribute_value
        });
    }

    onDeleteActionPlanItemClick(event, actionPlanOrdinal) {
        let deleteActionPlanItem = this.state.actionPlanList.filter(item => item.ordinal === actionPlanOrdinal)[0];
        this.setState({
            deleteActionPlanItem: deleteActionPlanItem,
            actionPlanOrdinal: actionPlanOrdinal
        });
    }

    onDeleteActionPlanOkClick(event) {
        let stateActionPlanList = this.state.actionPlanList.filter((item) => item.ordinal !== this.state.deleteActionPlanItem.ordinal);
        let deleteActionPlanList = this.state.actionPlanList.filter((item) => item.ordinal === this.state.deleteActionPlanItem.ordinal);

        stateActionPlanList.forEach((item) => {
            if (item.ordinal > this.state.deleteActionPlanItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteActionPlanList.forEach((item) => {
            item.delete = true;
        });

        this.saveActionPlanList(deleteActionPlanList, stateActionPlanList);
        this.onDeleteActionPlanCancelClick(event)
    }

    onDeleteActionPlanCancelClick(event) {
        this.setState({
            deleteActionPlanItem: null,
            actionPlanOrdinal: null
        });
    }

    onSaveActionPlanListClick(event) {
        let actionPlanList = [];
        let stateActionPlanList = this.state.actionPlanList;
        if (this.state.actionPlanOrdinal !== null) {
            let actionPlan = this.state.actionPlanList.filter(item => item.ordinal === this.state.actionPlanOrdinal)[0];

            actionPlan.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            actionPlan.updated_by = this.props.remoteUpn;
            actionPlan.attribute_value = this.state.actionPlan;

            actionPlanList.push(actionPlan);
        } else {
            let ordinal = this.state.actionPlanList.length + 1;
            actionPlanList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Action Plan",
                attribute_value: this.state.actionPlan
            });

            stateActionPlanList.push(actionPlanList[0]);
        }

        this.saveActionPlanList(actionPlanList, stateActionPlanList);
    }

    onSaveActionPlanListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveActionPlanListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStrengthList() {
        let htmlContent = [];
        this.state.strengthList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute && this.state.canContributeInLeadership
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditStrengthItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteStrengthItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveStrengthList(strengthList, stateStrengthList) {
        try {
            this.setState({ showStrengthSpinner: true });
            let inputData = strengthList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveStrengthList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        strengthOrdinal: null,
                        strengthList: stateStrengthList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showStrengthSpinner: false,
                        showAddStrength: false,
                        strength: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddStrengthList(event) {
        this.setState({
            showAddStrength: this.state.showAddStrength ? false : true,
            strengthOrdinal: null
        });
    }

    onEditStrengthItemClick(event, strengthOrdinal) {
        let strength = this.state.strengthList.filter(item => item.ordinal === strengthOrdinal)[0];
        this.setState({
            showAddStrength: true,
            strengthOrdinal: strengthOrdinal,
            strength: strength.attribute_value
        });
    }

    onDeleteStrengthItemClick(event, strengthOrdinal) {
        let deleteStrengthItem = this.state.strengthList.filter(item => item.ordinal === strengthOrdinal)[0];
        this.setState({
            deleteStrengthItem: deleteStrengthItem,
            strengthOrdinal: strengthOrdinal
        });
    }

    onDeleteStrengthOkClick(event) {
        let stateStrengthList = this.state.strengthList.filter((item) => item.ordinal !== this.state.deleteStrengthItem.ordinal);
        let deleteStrengthList = this.state.strengthList.filter((item) => item.ordinal === this.state.deleteStrengthItem.ordinal);

        stateStrengthList.forEach((item) => {
            if (item.ordinal > this.state.deleteStrengthItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteStrengthList.forEach((item) => {
            item.delete = true;
        });

        this.saveStrengthList(deleteStrengthList, stateStrengthList);
        this.onDeleteStrengthCancelClick(event)
    }

    onDeleteStrengthCancelClick(event) {
        this.setState({
            deleteStrengthItem: null,
            strengthOrdinal: null
        });
    }

    onSaveStrengthListClick(event) {
        let strengthList = [];
        let stateStrengthList = this.state.strengthList;
        if (this.state.strengthOrdinal !== null) {
            let strength = this.state.strengthList.filter(item => item.ordinal === this.state.strengthOrdinal)[0];

            strength.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            strength.updated_by = this.props.remoteUpn;
            strength.attribute_value = this.state.strength;

            strengthList.push(strength);
        } else {
            let ordinal = this.state.strengthList.length + 1;
            strengthList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Strength",
                attribute_value: this.state.strength
            });

            stateStrengthList.push(strengthList[0]);
        }

        this.saveStrengthList(strengthList, stateStrengthList);
    }

    onSaveStrengthListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveStrengthListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getDevelopmentOpportunityList() {
        let htmlContent = [];
        this.state.developmentOpportunityList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute && this.state.canContributeInLeadership
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditDevelopmentOpportunityItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteDevelopmentOpportunityItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveDevelopmentOpportunityList(developmentOpportunityList, stateDevelopmentOpportunityList) {
        try {
            this.setState({ showDevelopmentOpportunitySpinner: true });
            let inputData = developmentOpportunityList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveDevelopmentOpportunityList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        developmentOpportunityOrdinal: null,
                        developmentOpportunityList: stateDevelopmentOpportunityList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showDevelopmentOpportunitySpinner: false,
                        showAddDevelopmentOpportunity: false,
                        developmentOpportunity: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddDevelopmentOpportunityList(event) {
        this.setState({
            showAddDevelopmentOpportunity: this.state.showAddDevelopmentOpportunity ? false : true,
            developmentOpportunityOrdinal: null
        });
    }

    onEditDevelopmentOpportunityItemClick(event, developmentOpportunityOrdinal) {
        let developmentOpportunity = this.state.developmentOpportunityList.filter(item => item.ordinal === developmentOpportunityOrdinal)[0];
        this.setState({
            showAddDevelopmentOpportunity: true,
            developmentOpportunityOrdinal: developmentOpportunityOrdinal,
            developmentOpportunity: developmentOpportunity.attribute_value
        });
    }

    onDeleteDevelopmentOpportunityItemClick(event, developmentOpportunityOrdinal) {
        let deleteDevelopmentOpportunityItem = this.state.developmentOpportunityList.filter(item => item.ordinal === developmentOpportunityOrdinal)[0];
        this.setState({
            deleteDevelopmentOpportunityItem: deleteDevelopmentOpportunityItem,
            developmentOpportunityOrdinal: developmentOpportunityOrdinal
        });
    }

    onDeleteDevelopmentOpportunityOkClick(event) {
        let stateDevelopmentOpportunityList = this.state.developmentOpportunityList.filter((item) => item.ordinal !== this.state.deleteDevelopmentOpportunityItem.ordinal);
        let deleteDevelopmentOpportunityList = this.state.developmentOpportunityList.filter((item) => item.ordinal === this.state.deleteDevelopmentOpportunityItem.ordinal);

        stateDevelopmentOpportunityList.forEach((item) => {
            if (item.ordinal > this.state.deleteDevelopmentOpportunityItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteDevelopmentOpportunityList.forEach((item) => {
            item.delete = true;
        });

        this.saveDevelopmentOpportunityList(deleteDevelopmentOpportunityList, stateDevelopmentOpportunityList);
        this.onDeleteDevelopmentOpportunityCancelClick(event)
    }

    onDeleteDevelopmentOpportunityCancelClick(event) {
        this.setState({
            deleteDevelopmentOpportunityItem: null,
            developmentOpportunityOrdinal: null
        });
    }

    onSaveDevelopmentOpportunityListClick(event) {
        let developmentOpportunityList = [];
        let stateDevelopmentOpportunityList = this.state.developmentOpportunityList;
        if (this.state.developmentOpportunityOrdinal !== null) {
            let developmentOpportunity = this.state.developmentOpportunityList.filter(item => item.ordinal === this.state.developmentOpportunityOrdinal)[0];

            developmentOpportunity.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            developmentOpportunity.updated_by = this.props.remoteUpn;
            developmentOpportunity.attribute_value = this.state.developmentOpportunity;

            developmentOpportunityList.push(developmentOpportunity);
        } else {
            let ordinal = this.state.developmentOpportunityList.length + 1;
            developmentOpportunityList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Development Opportunity",
                attribute_value: this.state.developmentOpportunity
            });

            stateDevelopmentOpportunityList.push(developmentOpportunityList[0]);
        }

        this.saveDevelopmentOpportunityList(developmentOpportunityList, stateDevelopmentOpportunityList);
    }

    onSaveDevelopmentOpportunityListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveDevelopmentOpportunityListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getDevelopmentPlanList() {
        let htmlContent = [];
        this.state.developmentPlanList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute && this.state.canContributeInLeadership
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditDevelopmentPlanItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteDevelopmentPlanItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveDevelopmentPlanList(developmentPlanList, stateDevelopmentPlanList) {
        try {
            this.setState({ showDevelopmentPlanSpinner: true });
            let inputData = developmentPlanList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveDevelopmentPlanList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        developmentPlanOrdinal: null,
                        developmentPlanList: stateDevelopmentPlanList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showDevelopmentPlanSpinner: false,
                        showAddDevelopmentPlan: false,
                        developmentPlan: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddDevelopmentPlanList(event) {
        this.setState({
            showAddDevelopmentPlan: this.state.showAddDevelopmentPlan ? false : true,
            developmentPlanOrdinal: null
        });
    }

    onEditDevelopmentPlanItemClick(event, developmentPlanOrdinal) {
        let developmentPlan = this.state.developmentPlanList.filter(item => item.ordinal === developmentPlanOrdinal)[0];
        this.setState({
            showAddDevelopmentPlan: true,
            developmentPlanOrdinal: developmentPlanOrdinal,
            developmentPlan: developmentPlan.attribute_value
        });
    }

    onDeleteDevelopmentPlanItemClick(event, developmentPlanOrdinal) {
        let deleteDevelopmentPlanItem = this.state.developmentPlanList.filter(item => item.ordinal === developmentPlanOrdinal)[0];
        this.setState({
            deleteDevelopmentPlanItem: deleteDevelopmentPlanItem,
            developmentPlanOrdinal: developmentPlanOrdinal
        });
    }

    onDeleteDevelopmentPlanOkClick(event) {
        let stateDevelopmentPlanList = this.state.developmentPlanList.filter((item) => item.ordinal !== this.state.deleteDevelopmentPlanItem.ordinal);
        let deleteDevelopmentPlanList = this.state.developmentPlanList.filter((item) => item.ordinal === this.state.deleteDevelopmentPlanItem.ordinal);

        stateDevelopmentPlanList.forEach((item) => {
            if (item.ordinal > this.state.deleteDevelopmentPlanItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteDevelopmentPlanList.forEach((item) => {
            item.delete = true;
        });

        this.saveDevelopmentPlanList(deleteDevelopmentPlanList, stateDevelopmentPlanList);
        this.onDeleteDevelopmentPlanCancelClick(event)
    }

    onDeleteDevelopmentPlanCancelClick(event) {
        this.setState({
            deleteDevelopmentPlanItem: null,
            developmentPlanOrdinal: null
        });
    }

    onSaveDevelopmentPlanListClick(event) {
        let developmentPlanList = [];
        let stateDevelopmentPlanList = this.state.developmentPlanList;
        if (this.state.developmentPlanOrdinal !== null) {
            let developmentPlan = this.state.developmentPlanList.filter(item => item.ordinal === this.state.developmentPlanOrdinal)[0];

            developmentPlan.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            developmentPlan.updated_by = this.props.remoteUpn;
            developmentPlan.attribute_value = this.state.developmentPlan;

            developmentPlanList.push(developmentPlan);
        } else {
            let ordinal = this.state.developmentPlanList.length + 1;
            developmentPlanList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Development Plan",
                attribute_value: this.state.developmentPlan
            });

            stateDevelopmentPlanList.push(developmentPlanList[0]);
        }

        this.saveDevelopmentPlanList(developmentPlanList, stateDevelopmentPlanList);
    }

    onSaveDevelopmentPlanListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveDevelopmentPlanListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLeadershipNoteList() {
        let htmlContent = [];
        this.state.leadershipNoteList.forEach((item, itemIndex) => {
            htmlContent.push(
                <li key={"STLM" + itemIndex}>
                    {
                        this.state.canContribute && this.state.canContributeInLeadership
                            ? <Fragment>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onEditLeadershipNoteItemClick(event, item.ordinal)}>
                                    <i className="fas fa-edit" />
                                </button>
                                <button type="button" className="btn btn-link" style={{ "padding": "5px" }} onClick={(event) => this.onDeleteLeadershipNoteItemClick(event, item.ordinal)}>
                                    <i className="fas fa-times text-danger" />
                                </button>
                                &emsp;
                            </Fragment>
                            : null
                    }
                    {item.attribute_value}
                </li>);
        });

        return <ol>
            {htmlContent}
        </ol>;
    }

    saveLeadershipNoteList(leadershipNoteList, stateLeadershipNoteList) {
        try {
            this.setState({ showLeadershipNotepinner: true });
            let inputData = leadershipNoteList;

            this.props.applicationInsights.trackTrace({ message: "/TeamMemberProfileRouteComponent/saveLeadershipNoteList", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TeamMemberProfile/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        leadershipNoteOrdinal: null,
                        leadershipNoteList: stateLeadershipNoteList
                    });
                    this.props.onShowAlert("success", { message: "Skills which need exposure saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({
                        showLeadershipNotepinner: false,
                        showAddLeadershipNote: false,
                        leadershipNote: ""
                    });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAddLeadershipNoteList(event) {
        this.setState({
            showAddLeadershipNote: this.state.showAddLeadershipNote ? false : true,
            leadershipNoteOrdinal: null
        });
    }

    onEditLeadershipNoteItemClick(event, leadershipNoteOrdinal) {
        let leadershipNote = this.state.leadershipNoteList.filter(item => item.ordinal === leadershipNoteOrdinal)[0];
        this.setState({
            showAddLeadershipNote: true,
            leadershipNoteOrdinal: leadershipNoteOrdinal,
            leadershipNote: leadershipNote.attribute_value
        });
    }

    onDeleteLeadershipNoteItemClick(event, leadershipNoteOrdinal) {
        let deleteLeadershipNoteItem = this.state.leadershipNoteList.filter(item => item.ordinal === leadershipNoteOrdinal)[0];
        this.setState({
            deleteLeadershipNoteItem: deleteLeadershipNoteItem,
            leadershipNoteOrdinal: leadershipNoteOrdinal
        });
    }

    onDeleteLeadershipNoteOkClick(event) {
        let stateLeadershipNoteList = this.state.leadershipNoteList.filter((item) => item.ordinal !== this.state.deleteLeadershipNoteItem.ordinal);
        let deleteLeadershipNoteList = this.state.leadershipNoteList.filter((item) => item.ordinal === this.state.deleteLeadershipNoteItem.ordinal);

        stateLeadershipNoteList.forEach((item) => {
            if (item.ordinal > this.state.deleteLeadershipNoteItem.ordinal) {
                item.ordinal--;
            }
        });

        deleteLeadershipNoteList.forEach((item) => {
            item.delete = true;
        });

        this.saveLeadershipNoteList(deleteLeadershipNoteList, stateLeadershipNoteList);
        this.onDeleteLeadershipNoteCancelClick(event)
    }

    onDeleteLeadershipNoteCancelClick(event) {
        this.setState({
            deleteLeadershipNoteItem: null,
            leadershipNoteOrdinal: null
        });
    }

    onSaveLeadershipNoteListClick(event) {
        let leadershipNoteList = [];
        let stateLeadershipNoteList = this.state.leadershipNoteList;
        if (this.state.leadershipNoteOrdinal !== null) {
            let leadershipNote = this.state.leadershipNoteList.filter(item => item.ordinal === this.state.leadershipNoteOrdinal)[0];

            leadershipNote.updated_date = moment().format("YYYY-MM-DD HH:mm:ss");
            leadershipNote.updated_by = this.props.remoteUpn;
            leadershipNote.attribute_value = this.state.leadershipNote;

            leadershipNoteList.push(leadershipNote);
        } else {
            let ordinal = this.state.leadershipNoteList.length + 1;
            leadershipNoteList.push({
                store_number: this.state.profile.store_number,
                employee_number: this.state.profile.employee_number,
                updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_by: this.props.remoteUpn,
                ordinal: ordinal,
                attribute_name: "Leadership Note",
                attribute_value: this.state.leadershipNote
            });

            stateLeadershipNoteList.push(leadershipNoteList[0]);
        }

        this.saveLeadershipNoteList(leadershipNoteList, stateLeadershipNoteList);
    }

    onSaveLeadershipNoteListKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onSaveLeadershipNoteListClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Work History: " + (this.state.deleteWorkHistoryItem ? this.state.deleteWorkHistoryItem.previous_employer : "")}
                showConfirmation={this.state.deleteWorkHistoryItem !== null}
                onOkClick={this.onDeleteWorkHistoryOkClick}
                onCancelClick={this.onDeleteWorkHistoryCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Still To Improve: " + (this.state.deleteSkillsToImproveItem ? this.state.deleteSkillsToImproveItem.skills_to_improve : "")}
                showConfirmation={this.state.deleteSkillsToImproveItem !== null}
                onOkClick={this.onDeleteSkillsToImproveOkClick}
                onCancelClick={this.onDeleteSkillsToImproveCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Still To Learn More: " + (this.state.deleteSkillsToLearnMoreItem ? this.state.deleteSkillsToLearnMoreItem.skills_to_learn_more : "")}
                showConfirmation={this.state.deleteSkillsToLearnMoreItem !== null}
                onOkClick={this.onDeleteSkillsToLearnMoreOkClick}
                onCancelClick={this.onDeleteSkillsToLearnMoreCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Still Need Exposure: " + (this.state.deleteSkillsWhichNeedExposureItem ? this.state.deleteSkillsWhichNeedExposureItem.skill_need_exposure : "")}
                showConfirmation={this.state.deleteSkillsWhichNeedExposureItem !== null}
                onOkClick={this.onDeleteSkillsWhichNeedExposureOkClick}
                onCancelClick={this.onDeleteSkillsWhichNeedExposureCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Action Plan: " + (this.state.deleteActionPlanItem ? this.state.deleteActionPlanItem.action_plan : "")}
                showConfirmation={this.state.deleteActionPlanItem !== null}
                onOkClick={this.onDeleteActionPlanOkClick}
                onCancelClick={this.onDeleteActionPlanCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Strength: " + (this.state.deleteStrengthItem ? this.state.deleteStrengthItem.strength : "")}
                showConfirmation={this.state.deleteStrengthItem !== null}
                onOkClick={this.onDeleteStrengthOkClick}
                onCancelClick={this.onDeleteStrengthCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Development Opportunity: " + (this.state.deleteDevelopmentOpportunityItem ? this.state.deleteDevelopmentOpportunityItem.development_opportunity : "")}
                showConfirmation={this.state.deleteDevelopmentOpportunityItem !== null}
                onOkClick={this.onDeleteDevelopmentOpportunityOkClick}
                onCancelClick={this.onDeleteDevelopmentOpportunityCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Development Plan: " + (this.state.deleteDevelopmentPlanItem ? this.state.deleteDevelopmentPlanItem.development_plan : "")}
                showConfirmation={this.state.deleteDevelopmentPlanItem !== null}
                onOkClick={this.onDeleteDevelopmentPlanOkClick}
                onCancelClick={this.onDeleteDevelopmentPlanCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={this.pageTitle}
                bodyText={"Do you want to delete Leadership Note: " + (this.state.deleteLeadershipNoteItem ? this.state.deleteLeadershipNoteItem.leadership_Note : "")}
                showConfirmation={this.state.deleteLeadershipNoteItem !== null}
                onOkClick={this.onDeleteLeadershipNoteOkClick}
                onCancelClick={this.onDeleteLeadershipNoteCancelClick}
            />
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <TeamMemberProfileQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} employeeProfiles={this.state.employeeProfiles}
                    onFindEmployeeClick={this.onFindEmployeeClick} />
                <div className="dgdg-site-scrollable-content">
                    {
                        this.state.profile
                            ? <div className="card dgdg-card-gutter">
                                <div className="card-header">
                                    {this.state.profile.employee_first + " " + this.state.profile.employee_last + " (" + this.state.profile.employee_number + ")"}
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Title:&emsp;{this.state.profile.job_title}
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Store:&emsp;{this.state.profile.store_web_name}
                                </div>
                                <div className="card-block" style={{ "padding": "5px 10px" }}>
                                    <table className="dgdg-team-member-table">
                                        <tbody>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold">
                                                    Work Histories
                                                </td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showWorkHistorySpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.showAddWorkHistory
                                                    ? <tr>
                                                        <td style={{ "paddingLeft": "15px" }}>
                                                            <table cellPadding="5px" style={{ "width": "100%" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-nowrap dgdg-font-blue" style={{ "maxWidth": "55px" }}>Previous Employer:</td>
                                                                        <td>
                                                                            <DGDGFormInputComponent id="previousEmployer" value={this.state.previousEmployer} onChange={this.onTextChange} disabled={!this.state.canContribute} controlSpan="col-12" enterToTab />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-nowrap dgdg-font-blue" style={{ "maxWidth": "55px" }}>Previous Job Title:</td>
                                                                        <td>
                                                                            <DGDGFormInputComponent id="previousJobTitle" value={this.state.previousJobTitle} onChange={this.onTextChange} disabled={!this.state.canContribute} controlSpan="col-12" enterToTab />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-nowrap dgdg-font-blue" style={{ "maxWidth": "55px" }}>Time In Position:</td>
                                                                        <td>
                                                                            <DGDGFormInputComponent id="timeInPosition" value={this.state.timeInPosition} onChange={this.onTextChange} disabled={!this.state.canContribute} controlSpan="col-12" enterToTab />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.previousEmployer.trim() && !this.state.previousJobTitle.trim() && !this.state.timeInPosition.trim()} onClick={(event) => this.onSaveWorkHistoryListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddWorkHistoryList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }

                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.state.canContribute && !this.state.showAddWorkHistory && this.state.workHistoryList.length < 15
                                                            ? <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddWorkHistoryList(event)}>
                                                                Add Work History <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getWorkHistoryList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>What is your long-term career goal?
                                                    <DGDGFormInputComponent id="longTermGoal" value={this.state.longTermGoal} onKeyDown={this.onKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} cssName="dgdg-team-member-title dgdg-icon" />
                                                </td>
                                                <td className="text-nowrap">
                                                    {
                                                        this.state.canContribute
                                                            ? <button type="button" className="btn btn-link dgdg-font-size-18" style={{ "paddingTop": "50px" }} onClick={(event) => this.onSaveClick(event)}>
                                                                <span className="fas fa-save" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>What skills, behaviors and experiences do you think you need to develop to continue to grow?</td>
                                            </tr>
                                            <tr>
                                                <td style={{ "padding": "5px 24px 0px 24px" }}>
                                                    Skills To Improve
                                                    {
                                                        this.state.canContribute && !this.state.showAddSkillsToImprove && this.state.skillsToImproveList.length < 3
                                                            ? <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddSkillsToImproveList(event)}>
                                                                Add Skills To Improve <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showSkillsToImproveSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.showAddSkillsToImprove
                                                    ? <tr>
                                                        <td style={{ "padding": "10px 0px 0px 19px" }}>
                                                            <DGDGFormInputComponent id="skillsToImprove" value={this.state.skillsToImprove} onKeyDown={this.onSaveSkillsToImproveListKeyDown} onChange={this.onTextChange} cssName="dgdg-team-member-title" disabled={!this.state.canContribute} />
                                                        </td>
                                                        <td className="text-nowrap" style={{ "paddingTop": "15px" }}>
                                                            {
                                                                this.state.canContribute
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.skillsToImprove.trim()} onClick={(event) => this.onSaveSkillsToImproveListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddSkillsToImproveList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getSkillsToImproveList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ "padding": "5px 24px 0px 24px" }}>
                                                    Skills To Learn More
                                                    {
                                                        this.state.canContribute && !this.state.showAddSkillsToLearnMore && this.state.skillsToLearnMoreList.length < 3
                                                            ? <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddSkillsToLearnMoreList(event)}>
                                                                Add Skills To Learn More <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showSkillsToLearnMoreSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.showAddSkillsToLearnMore
                                                    ? <tr>
                                                        <td style={{ "padding": "10px 0px 0px 19px" }}>
                                                            <DGDGFormInputComponent id="skillsToLearnMore" value={this.state.skillsToLearnMore} onKeyDown={this.onSaveSkillsToLearnMoreListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} />
                                                        </td>
                                                        <td className="text-nowrap" style={{ "paddingTop": "15px" }}>
                                                            {
                                                                this.state.canContribute
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.skillsToLearnMore.trim()} onClick={(event) => this.onSaveSkillsToLearnMoreListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddSkillsToLearnMoreList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getSkillsToLearnMoreList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ "padding": "5px 24px 0px 24px" }}>
                                                    Skills Which Need Exposure
                                                    {
                                                        this.state.canContribute && !this.state.showAddSkillsWhichNeedExposure && this.state.skillsWhichNeedExposureList.length < 3
                                                            ? <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddSkillsWhichNeedExposureList(event)}>
                                                                Add Skills Which Need Exposure <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showSkillsWhichNeedExposureSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.showAddSkillsWhichNeedExposure
                                                    ? <tr>
                                                        <td style={{ "padding": "10px 0px 0px 19px" }}>
                                                            <DGDGFormInputComponent id="skillsWhichNeedExposure" value={this.state.skillsWhichNeedExposure} onKeyDown={this.onSaveSkillsWhichNeedExposureListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} />
                                                        </td>
                                                        <td className="text-nowrap" style={{ "paddingTop": "15px" }}>
                                                            {
                                                                this.state.canContribute
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.skillsWhichNeedExposure.trim()} onClick={(event) => this.onSaveSkillsWhichNeedExposureListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddSkillsWhichNeedExposureList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>

                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getSkillsWhichNeedExposureList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>As we continue to grow across the Bay Area, are you limited to where you can work geographically?
                                                    <DGDGFormInputComponent id="geographicalLimitation" value={this.state.geographicalLimitation} onKeyDown={this.onKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} cssName="dgdg-team-member-title dgdg-icon" />
                                                </td>
                                                <td className="text-nowrap">
                                                    {
                                                        this.state.canContribute
                                                            ? <button type="button" className="btn btn-link dgdg-font-size-18" style={{ "paddingTop": "50px" }} onClick={(event) => this.onSaveClick(event)}>
                                                                <span className="fas fa-save" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Any other thoughts about your development:
                                                    <DGDGFormInputComponent id="otherDevelopment" value={this.state.otherDevelopment} onKeyDown={this.onKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} cssName="dgdg-team-member-title dgdg-icon" />
                                                </td>
                                                <td className="text-nowrap">
                                                    {
                                                        this.state.canContribute
                                                            ? < button type="button" className="btn btn-link dgdg-font-size-18" style={{ "paddingTop": "50px" }} onClick={(event) => this.onSaveClick(event)}>
                                                                <span className="fas fa-save" />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Action Plans</td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showActionPlanSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.canContribute && !this.state.showAddActionPlan && this.state.actionPlanList.length < 5
                                                    ? <tr>
                                                        <td colSpan="2">
                                                            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddActionPlanList(event)}>
                                                                Add Action Plan <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            {
                                                this.state.showAddActionPlan
                                                    ? <tr>
                                                        <td style={{ "padding": "10px 0px 0px 19px" }}>
                                                            <DGDGFormInputComponent id="actionPlan" value={this.state.actionPlan} onKeyDown={this.onSaveActionPlanListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute} />
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.actionPlan.trim()} onClick={(event) => this.onSaveActionPlanListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddActionPlanList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getActionPlanList()
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null
                    }
                    {
                        this.state.isInLeadershipRole && this.state.profile && this.state.loggedInEmployeeNumber !== this.state.profile.employee_number
                            ? <div className="card">
                                <div className="card-header">
                                    Leadership Notes
                                    {
                                        this.state.profile
                                            ? <span>
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{this.state.profile.employee_first + " " + this.state.profile.employee_last + " (" + this.state.profile.employee_number + ")"}
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Title:&emsp;{this.state.profile.job_title}
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Store:&emsp;{this.state.profile.store_web_name}
                                            </span>
                                            : null
                                    }
                                </div>
                                <div className="card-block" style={{ "padding": "10px" }}>
                                    <table className="dgdg-team-member-table">
                                        <tbody>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold">Strengths</td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showStrengthSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.canContribute && this.state.canContributeInLeadership && !this.state.showAddStrength && this.state.strengthList.length < 5
                                                    ? <tr>
                                                        <td colSpan="2">
                                                            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddStrengthList(event)}>
                                                                Add Strength <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            {
                                                this.state.showAddStrength
                                                    ? <tr>
                                                        <td style={{ "paddingLeft": "19px" }}>
                                                            <DGDGFormInputComponent id="strength" value={this.state.strength} onKeyDown={this.onSaveStrengthListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} />
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute && this.state.canContributeInLeadership
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.strength.trim()} onClick={(event) => this.onSaveStrengthListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddStrengthList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getStrengthList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Development Opportunities</td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showDevelopmentOpportunitySpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.canContribute && this.state.canContributeInLeadership && !this.state.showAddDevelopmentOpportunity && this.state.developmentOpportunityList.length < 5
                                                    ? <tr>
                                                        <td colSpan="2">
                                                            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddDevelopmentOpportunityList(event)}>
                                                                Add Development Opportunity <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            {
                                                this.state.showAddDevelopmentOpportunity
                                                    ? <tr>
                                                        <td style={{ "paddingLeft": "19px" }}>
                                                            <DGDGFormInputComponent id="developmentOpportunity" value={this.state.developmentOpportunity} onKeyDown={this.onSaveDevelopmentOpportunityListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} />
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute && this.state.canContributeInLeadership && this.state.showAddDevelopmentOpportunity
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.developmentOpportunity.trim()} onClick={(event) => this.onSaveDevelopmentOpportunityListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddDevelopmentOpportunityList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getDevelopmentOpportunityList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Scores</td>
                                                <td />
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{ "paddingLeft": "27px" }}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-nowrap dgdg-font-blue">Integrity Score:
                                                                    <DGDGFormDropdownComponent id="selectedIntegrityScore" data={this.integrityScores} itemKey="value" value={this.state.selectedIntegrityScore ? this.state.selectedIntegrityScore.value : "Integrity Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td className="text-nowrap dgdg-font-blue">Caring Score:
                                                                    <DGDGFormDropdownComponent id="selectedCaringScore" data={this.caringScores} itemKey="value" value={this.state.selectedCaringScore ? this.state.selectedCaringScore.value : "Caring Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td className="text-nowrap dgdg-font-blue">Performance Score:
                                                                    <DGDGFormDropdownComponent id="selectedPerformanceScore" data={this.performanceScores} itemKey="value" value={this.state.selectedPerformanceScore ? this.state.selectedPerformanceScore.value : "Performance Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td className="text-nowrap dgdg-font-blue">Efficiency Score:
                                                                    <DGDGFormDropdownComponent id="selectedEfficiencyScore" data={this.efficiencyScores} itemKey="value" value={this.state.selectedEfficiencyScore ? this.state.selectedEfficiencyScore.value : "Efficiency Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td style={{ "paddingTop": "25px" }}>
                                                                    {
                                                                        this.state.canContribute && this.state.canContributeInLeadership
                                                                            ? <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveClick(event)}>
                                                                                <span className="fas fa-save" />
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-nowrap dgdg-font-blue">Potential Score:
                                                                    <DGDGFormDropdownComponent id="selectedPotentialScore" data={this.potentialScores} itemKey="value" value={this.state.selectedPotentialScore ? this.state.selectedPotentialScore.value : "Potential Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td className="text-nowrap dgdg-font-blue">Readiness Score:
                                                                    <DGDGFormDropdownComponent id="selectedReadinessScore" data={this.readinessScores} itemKey="value" value={this.state.selectedReadinessScore ? this.state.selectedReadinessScore.value : "Readiness Score"} onItemClick={this.onDropdownChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} cssName="dgdg-team-member-title dgdg-team-member-ddp" controlSpan="col-12" />
                                                                </td>
                                                                <td colSpan="3" style={{ "paddingTop": "25px" }}>
                                                                    {
                                                                        this.state.canContribute && this.state.canContributeInLeadership
                                                                            ? <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveClick(event)}>
                                                                                <span className="fas fa-save" />
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Development Plans</td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showDevelopmentPlanSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.canContribute && this.state.canContributeInLeadership && !this.state.showAddDevelopmentPlan && this.state.developmentPlanList.length < 5
                                                    ? <tr>
                                                        <td colSpan="2">
                                                            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddDevelopmentPlanList(event)}>
                                                                Add Development Plan <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            {
                                                this.state.showAddDevelopmentPlan
                                                    ? <tr>
                                                        <td style={{ "paddingLeft": "19px" }}>
                                                            <DGDGFormInputComponent id="developmentPlan" value={this.state.developmentPlan} onKeyDown={this.onSaveDevelopmentPlanListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} />
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute && this.state.canContributeInLeadership
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.developmentPlan.trim()} onClick={(event) => this.onSaveDevelopmentPlanListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddDevelopmentPlanList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getDevelopmentPlanList()
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-font-blue font-weight-bold" style={{ "paddingTop": "20px" }}>Leadership Note</td>
                                                <td>
                                                    <DGDGSpinnerComponent showSpinner={this.state.showLeadershipNoteSpinner} />
                                                </td>
                                            </tr>
                                            {
                                                this.state.canContribute && this.state.canContributeInLeadership && !this.state.showAddLeadershipNote
                                                    ? <tr>
                                                        <td colSpan="2">
                                                            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddLeadershipNoteList(event)}>
                                                                Add Leadership Note <span className="fas fa-plus dgdg-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            {
                                                this.state.showAddLeadershipNote
                                                    ? <tr>
                                                        <td style={{ "paddingLeft": "19px" }}>
                                                            <DGDGFormInputComponent id="leadershipNote" value={this.state.leadershipNote} onKeyDown={this.onSaveLeadershipNoteListKeyDown} onChange={this.onTextChange} disabled={!this.state.canContribute || !this.state.canContributeInLeadership} />
                                                        </td>
                                                        <td className="text-nowrap">
                                                            {
                                                                this.state.canContribute && this.state.canContributeInLeadership
                                                                    ? <Fragment>
                                                                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!this.state.leadershipNote.trim()} onClick={(event) => this.onSaveLeadershipNoteListClick(event)}>
                                                                            <span className="fas fa-save" />
                                                                        </button>
                                                                        <button type="button" className="btn btn-link" onClick={(event) => this.onToggleAddLeadershipNoteList(event)}>
                                                                            <span className="fas fa-times text-danger" />
                                                                        </button>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan="2">
                                                    {
                                                        this.getLeadershipNoteList()
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
