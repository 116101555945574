import React from "react";

export class DGDGWidgetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        if (this.props.isMaximized) {
            switch (event.keyCode) {
                case 27:
                    this.props.onRestoreClick(event);
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return <div className={(this.props.isMaximized ? this.props.maximizedCss : this.props.restoredCss) + (this.props.isVisible ? "" : " d-none")}>
            <div className="card dgdg-box-shadow" style={{ height: "100%" }}>
                {
                    this.props.title
                        ? <div className="card-header">
                            <span>{this.props.title}</span>
                            {
                                (this.props.onRestoreClick || this.props.onMaximizeClick)
                                    ? this.props.isMaximized
                                        ? <button type="button" className="btn btn-link float-right" style={{ "padding": "0px" }} onClick={(event) => this.props.onRestoreClick(event)}>
                                            <i className="far fa-window-restore" style={{ color: "black" }} />
                                        </button>
                                        : <button type="button" className="btn btn-link float-right" style={{ "padding": "0px" }} onClick={(event) => this.props.onMaximizeClick(event)}>
                                            <i className="far fa-window-maximize" style={{ color: "black" }} />
                                        </button>
                                    : null
                            }
                        </div>
                        : null
                }
                <div className="card-block" style={{ height: "calc(100% - 35px)" }}>
                    {this.props.children}
                </div>
            </div>
        </div>;
    }
}
