import React from "react";
import moment from "moment";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

export class DGDGFormDropdownComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    formatData(data) {
        let value = "";
        if (data) {
            switch (this.props.displayFormat) {
                case "monthYear":
                    value = (data === "Last 30 Days" ? data : CommonService.formatMonthYear(moment(data)));
                    break;

                case "Year":
                    if (data === "All") {
                        value = data;
                    }
                    else {
                        value = CommonService.formatYear(moment(data));
                    }
                    break;

                default:
                    value = data;
                    break;
            }
        }
        else {
            switch (this.props.displayFormat) {
                case "Year":
                    value = "All";
                    break;

                default:
                    value = data;
                    break;
            }
        }

        return value;
    }

    onItemClick(event, item) {
        if (this.props.onItemClick) {
            this.props.onItemClick(this.props.id, item, this.props.additionalData);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.id, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.labelCssName) {
            labelSpan += " " + this.props.labelCssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let ddlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                ddlElements.push(<button key={"ddlItem" + index} className="btn btn-link dropdown-item" onClick={(event) => this.onItemClick(event, item)}>
                    {
                        item?.colorBox
                            ? <div className="dropdown-dgdg-color-box" style={{ color: item.colorBox, backgroundColor: item.colorBox }}>&nbsp;</div>
                            : null
                    }
                    {
                        this.props.itemKey
                            ? this.formatData(item[this.props.itemKey])
                            : this.formatData(item)
                    }
                </button>);
            });
        }

        let txtStyle = {
            "cursor": "pointer",
            "zIndex": "100"
        };

        if (!this.props.disabled) {
            txtStyle.backgroundColor = "white";
        }

        return <div className={"form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }

            <div className={controlSpan}>
                <div className="dropdown">
                    <div className="input-group" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <input className={"form-control" + (this.props.inputCssName ? " " + this.props.inputCssName : "")} value={this.formatData(this.props.value)} style={txtStyle} disabled={this.props.disabled} tabIndex="-1" readOnly />
                        <span className="input-group-append">
                            <button className="btn btn-secondary dropdown-toggle" type="button" disabled={this.props.disabled} style={{ "zIndex": "100" }} />
                        </span>
                    </div>
                    <div className="dropdown-menu dgdg-dropdown-menu" hidden={this.props.disabled} onKeyDown={(event) => this.onKeyDown(event)}>
                        {ddlElements}
                    </div>
                </div>
            </div>
        </div>;
    }
}
