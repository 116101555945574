import React from "react";

export class DGDGFormCheckboxComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.checked, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelCssName = this.props.labelCssName ?? "col-form-label dgdg-form-label";

        return <div className={"form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelCssName} htmlFor={this.props.id}>{this.props.label}&nbsp;:</label>
                    : null
            }
            <div className={colSpan}>
                <input type="checkbox" style={{ "marginTop": "5px" }} className={this.props.controlCssName ? this.props.controlCssName : ""} id={this.props.id} disabled={this.props.disabled} checked={this.props.value ? this.props.value : false} onChange={(event) => this.onChange(event)} />
                {this.props.children}
            </div>
        </div>;
    }
}
