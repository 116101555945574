import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class DealIssuesAddComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DealIssuesAddComponent - getDerivedStateFromProps");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            id: null,
            dealNumber: "",
            isSaveEnabled: false,
            firstName: "",
            lastName: "",
            stockNumber: "",
            vin: "",
            make: "",
            model: "",
            year: "",
            comments: "",
            salesDetails: null
        };

        this.onDealNumberChange = this.onDealNumberChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveNewClick = this.onSaveNewClick.bind(this);
        this.onSaveCloseClick = this.onSaveCloseClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Add Exception Deal";

    onLookupClick(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.getSalesDetails);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveNewClick(event) {
        this.onSaveClick(event, ".SaveNew");
        this.setState({
            dealNumber: null
        })
    }

    onSaveCloseClick(event) {
        this.onSaveClick(event, ".SaveClose");
        this.onCancelClick(event);
    }

    onSaveClick(event, buttonName) {
        try {
            this.props.onSaveClick(event, {
                id: this.state.id,
                storeNumber: this.props.selectedStore.store_number,
                dealNumber: this.state.dealNumber,
                stockNumber: this.state.stockNumber,
                vin: this.state.vin,
                make: this.state.vin,
                year: this.state.year,
                model: this.state.model,
                comments: this.state.comments,
                buttonName: buttonName
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                dealNumber: "",
                firstName: "",
                lastName: "",
                stockNumber: "",
                vin: "",
                make: "",
                year: "",
                model: "",
                comments: ""
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getSalesDetails() {
        if (isNaN(parseInt(this.state.dealNumber)) || this.props.selectedStore === null) {
            this.setState({
                salesDetails: null
            }, this.setData);
        }
        else {
            let inputData = {
                storeNumber: parseInt(this.props.selectedStore.store_number),
                dealNumber: parseInt(this.state.dealNumber)
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/getDashboardItems", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetSalesDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        salesDetails: parsedResponse[0]
                    }, this.setData);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/GetSalesDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
    }

    validateForm() {
        this.setState({
            isSaveEnabled: this.state.dealNumber !== "" && this.state.salesDetails
        });
    }

    setData() {
        if (!isNaN(parseInt(this.state.dealNumber)) && this.state.salesDetails) {
            this.setState({
                stockNumber: this.state.salesDetails.stock_number,
                vin: this.state.salesDetails.vin,
                make: this.state.salesDetails.make,
                year: this.state.salesDetails.year,
                model: this.state.salesDetails.model
            }, this.validateForm);
        } else {
            this.setState({
                stockNumber: "",
                vin: "",
                make: "",
                year: "",
                model: ""
            });
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-deal-issues-add-dialog"
            title={this.props.title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveNewClick} disabled={!this.state.isSaveEnabled}>
                    Save & New  <i className="fas fa-save dgdg-icon" />
                </button>
                <button className="btn btn-primary" onClick={this.onSaveCloseClick} disabled={!this.state.isSaveEnabled} style={{ "marginLeft": "15px" }}>
                    Save & Close  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-deal-issues-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Deal Number:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="dealNumber" value={this.state.dealNumber} onChange={this.onDealNumberChange} inputType="number" onLookupClick={this.onLookupClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        First Name:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="firstName" value={this.state.firstName} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Last Name:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="lastName" value={this.state.lastName} onChange={this.onTextChange} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Stock #:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="stockNumber" value={this.state.stockNumber} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        VIN:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="vin" value={this.state.vin} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Make:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="make" value={this.state.make} onChange={this.onTextChange} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Year:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="year" value={this.state.year} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Model:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="model" value={this.state.model} onChange={this.onTextChange} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Comments:
                                    </td>
                                    <td />
                                    <td colSpan="9">
                                        <DGDGFormInputComponent id="comments" value={this.state.comments} onChange={this.onTextChange} disabled={!this.state.isSaveEnabled} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
