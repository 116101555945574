import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class DGDGDataRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('month'),
            endDate: moment(),
            offerNumber: "20230504T104902.58261ee2-74d7-b436-60dc-7898cc48f3b0"
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSalesDownloadClick = this.onSalesDownloadClick.bind(this);
        this.onSellToDGDGDownloadClick = this.onSellToDGDGDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "DGDG Data";

    componentDidMount() {
        try {
            console.devLog("DGDGDataRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DGDGData", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("DGDGDataRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }


    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DGDGDataRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DGDGData/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "DGDGDataRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DGDGData/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onDownloadClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, text) {
        try {
            this.setState({ [id]: text });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSalesDownloadClick() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DGDGDataRouteComponent/onSalesDownloadClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/api/DGDGData/GetSalesCsv/" + CommonService.formatServerDateTime(this.state.startDate) + "/" + CommonService.formatServerDateTime(this.state.endDate))
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSellToDGDGDownloadClick() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DGDGDataRouteComponent/onSellToDGDGDownloadClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/api/DGDGData/GetSellToDGDGOffer/" + this.state.offerNumber)
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DGDGData/GetSellToDGDGOffer", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body">
            <br />
            <div className="card">
                <div className="card-block">
                    <table className="dgdg-data-table" style={{ "width": "100%" }}>
                        <tbody>
                            <tr className="text-center dgdg-data-table-header">
                                <td>Dataset</td>
                                <td width="500px">URL</td>
                                <td>Parameters</td>
                                <td>Action</td>
                            </tr>
                            <tr>
                                <td>Sales Data</td>
                                <td>https://dgdgdash.com/api/DGDGData/GetSalesCsv/{CommonService.formatServerDateTime(this.state.startDate)}/{CommonService.formatServerDateTime(this.state.endDate)}</td>
                                <td className="form-inline">
                                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Start Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={1} onKeyDown={this.onKeyDown} />
                                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="endDate" label="End Date" value={this.state.endDate} onDateChange={this.onDateChange} numberOfMonths={1} onKeyDown={this.onKeyDown} />
                                </td>
                                <td>
                                    <button className="btn btn-primary" onClick={(event) => this.onSalesDownloadClick(event)}>
                                        Download <i className="far fa-download dgdg-icon" />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Sell To DGDG Offer</td>
                                <td>https://dgdgdash.com/api/DGDGData/GetSellToDGDGOffer/{this.state.offerNumber}</td>
                                <td className="form-inline">
                                    <DGDGFormInputComponent id="offerNumber" label="Offer Number" value={this.state.offerNumber} onChange={this.onTextChange} />
                                </td>
                                <td>
                                    <button className="btn btn-primary" onClick={(event) => this.onSellToDGDGDownloadClick(event)}>
                                        Download <i className="far fa-download dgdg-icon" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
}
