import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { VinSolutionsLegacyNotesQueryComponent } from "./VinSolutionsLegacyNotesQueryComponent";


export class VinSolutionsLegacyNotesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autoLeadId: "",
            filterValue: "",
            notesTableData: [],
            paintTable: false,
            textsTableData: [],
            isLoadingData: false
        };

        this.onLookupClick = this.onLookupClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("VinSolutionsLegacyNotesRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/VinSolutionsLegacyNotes", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            if (this.props.match.params.autoLeadId) {
                this.props.onSalesforceMount();
                this.onLookupClick(this.props.match.params.autoLeadId);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        try {
            console.devLog("VinSolutionsLegacyNotesRouteComponent - componentWillUnmount");
            if (this.props.match.params.autoLeadId)
                this.props.onSalesforceUnmount();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "VinSolutions Legacy Notes";

    onLookupClick(autoLeadId) {
        try {
            let inputData = {
                autoLeadId: autoLeadId,
            };
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/VinSolutionsLegacyNotesRouteComponent/onLookupClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/VinSolutionsLegacyNotes/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.VSTextMessages.forEach((responseItem, index) => {
                        responseItem.TextDirectionBound = responseItem.TextDirection === "1" ? "Outbound" : responseItem.TextDirection === "2" ? "Inbound" : "";
                    });
                    this.setState({
                        notesTableData: CommonService.addOrdinalColumn(parsedResponse.VSNotes),
                        textsTableData: CommonService.addOrdinalColumn(parsedResponse.VSTextMessages),
                        autoLeadId: "",
                        paintTable: true,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        notesTableData: [],
                        textsTableData: [],
                        paintTable: true,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VinSolutionsLegacyNotes/GetUSWUpload", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({ autoLeadId: autoLeadId })
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("VinSolutionsLegacyNotesRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                {
                    !this.props.match.params.autoLeadId
                        ? <VinSolutionsLegacyNotesQueryComponent
                            applicationInsights={this.props.applicationInsights}
                            isLoadingData={this.state.isLoadingData}
                            onLookupClick={this.onLookupClick}
                        />
                        : null
                }
                {
                    this.state.notesTableData.length > 0
                        ? <div className="dgdg-site-scrollable-content">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-card-gutter" headerText="Vin Solutions Legacy Notes" tableData={this.state.notesTableData} paintTable={this.state.paintTable}
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4], onCopyTableCallBack: this.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="Note Date" dataColumn="NoteCreatedDate" sortColumn="NoteCreatedDate" filterColumn="NoteCreatedDate" dataType="date" headerCssName="dgdg-vin-solutions-legacy-notes-note-date-col" />
                                    <DGDGTableColumnComponent headerText="Note Type" dataColumn="NoteType" sortColumn="NoteType" filterColumn="NoteType" cssName="dgdg-vin-solutions-legacy-notes-note-type-col" />
                                    <DGDGTableColumnComponent headerText="Note Subject" dataColumn="NoteSubject" sortColumn="NoteSubject" filterColumn="NoteSubject" cssName="dgdg-vin-solutions-legacy-notes-note-subject-col" />
                                    <DGDGTableColumnComponent headerText="Note Comment" dataColumn="NoteComment" sortColumn="NoteComment" filterColumn="NoteComment" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Vin Solutions Legacy Text Messages" tableData={this.state.textsTableData} paintTable={this.state.paintTable}
                                copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="Text Date" dataColumn="TextCreatedDate" sortColumn="TextCreatedDate" filterColumn="TextCreatedDate" dataType="date" headerCssName="dgdg-vin-solutions-legacy-notes-text-date-col" />
                                    <DGDGTableColumnComponent headerText="Text Direction" dataColumn="TextDirectionBound" sortColumn="TextDirectionBound" filterColumn="TextDirectionBound" headerCssName="dgdg-vin-solutions-legacy-notes-text-dir-col" />
                                    <DGDGTableColumnComponent headerText="Text Message" dataColumn="TextMessage" sortColumn="TextMessage" filterColumn="TextMessage" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                        : this.state.autoLeadId
                            ? <div className="badge badge-light dgdg-badge text" style={{ "marginLeft": "35%" }}>
                                No VinSolutions Legacy Notes found for AutoLeadID: {this.state.autoLeadId}
                            </div>
                            : null
                }
            </div>
        </DGDGPageComponent>
    }
}
