import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableFooterComponent } from "../common/dgdgtable/DGDGTableFooterComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class FinancialStatementAccountDetailComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("FinancialStatementAccountDetailComponent - getDerivedStateFromProps");
            let totalAmount = 0;
            props.tableData.forEach((row) => {
                let value = CommonService.parseDecimal(row.amt);
                if (!isNaN(value)) {
                    totalAmount += value;
                }
            });

            let totalAmountDifference = totalAmount - CommonService.parseDecimal(props.lineItemAmount);
            if (totalAmountDifference > 1 || totalAmountDifference < -1) {
                newState.unAccountDetailRow = <tr className="dgdg-fs-bg-total-diff font-weight-bold">
                    <td />
                    <td colSpan="3" className="overflow-wrap text-left font-weight-bold">Unaccounted Detail</td>
                    <td colSpan="1" className="text-left font-weight-bold">{CommonService.getAmountType(totalAmountDifference)}</td>
                    <td colSpan="1" className="text-right font-weight-bold">{CommonService.formatNumber_2(totalAmountDifference)}</td>
                    <td colSpan="5" />
                </tr>;
            }
            else {
                newState.unAccountDetailRow = null;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            unAccountDetailRow: null
        };

        this.onCopyClick = this.onCopyClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Financial Statement";

    onCopyClick(event) {
        try {
            let totalAmount = 0;
            let tableText = "Store #\tDate\tAccount Number\tAccount Description\tType\tAmount\tDetail Description\tControl 1\tControl 2\tReference\r\n";
            this.props.tableData.forEach((row) => {
                totalAmount += CommonService.parseDecimal(row.amt);
                tableText += row.sn + "\t" + (row.dt ? row.dt : "") + "\t" + (row.an ? row.an : "") + "\t" + (row.ad ? row.ad : "") +
                    "\t" + (row.amt ? CommonService.getAmountType(row.amt) : "") + "\t" + (row.amt ? row.amt : "") +
                    "\t" + (row.dd ? row.dd : "") + "\t" + (row.c1 ? row.c1 : "") + "\t" + (row.c2 ? row.c2 : "") + "\t" + (row.re ? row.re : "") + "\r\n";
            });

            tableText += "Total\t\t\t\t" + CommonService.getAmountType(totalAmount) + "\t" + totalAmount + "\t\t\t\t";
            let totalAmountDifference = totalAmount - CommonService.parseDecimal(this.props.lineItemAmount);
            if (totalAmountDifference > 1 || totalAmountDifference < -1) {
                tableText += "\nUnaccounted Detail\t\t\t" + CommonService.getAmountType(totalAmountDifference) + "\t" + totalAmountDifference + "\t\t\t\t";
            }

            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("FinancialStatementAccountDetailComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showSpinner || this.props.tableData.length > 0}
            title={this.props.tableTitle}
            cssName="dgdg-fsad-detail-dialog"
            modalClassName="dgdg-fin-stmt"
            onCancelClick={this.props.onCancelClick}>

            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={(event) => this.onCopyClick(event)}>
                    Copy<span className="far fa-copy dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showSpinner={this.props.showSpinner} infoText={this.props.downloadedBlobCount}
                    scrollElement=".dgdg-fin-stmt" tablePageSize={CommonService.tablePageSize} showFooter>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store #" dataColumn="sn" sortColumn="sn" filterColumn="sn" />
                        <DGDGTableColumnComponent headerText="Date" dataColumn="dt" sortColumn="dt" filterColumn="dt" dataType="date" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Account Number" dataType="string" dataColumn="an" sortColumn="an" filterColumn="an" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="ad" sortColumn="ad" filterColumn="ad" />
                        <DGDGTableColumnDrCrComponent headerText="Type" dataColumn="amt" sortColumn="amt" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="amt" sortColumn="amt" filterColumn="amt" dataType="formatted_number_2" isDrCr footerFunction="sum" footerCssName="text-right" />
                        <DGDGTableColumnComponent headerText="Detail Description" dataColumn="dd" sortColumn="dd" filterColumn="dd" />
                        <DGDGTableColumnComponent headerText="Control 1" dataColumn="c1" sortColumn="c1" filterColumn="c1" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Control 2" dataColumn="c2" sortColumn="c2" filterColumn="c2" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Reference" dataColumn="re" sortColumn="re" filterColumn="re" />
                    </DGDGTableRowComponent>
                    <DGDGTableFooterComponent position="PostFooter">
                        {this.state.unAccountDetailRow}
                    </DGDGTableFooterComponent>
                </DGDGTableV3Component>
            </div>
            {
                this.props.accountDetailBlobName && this.props.isDeveloper
                    ? <div className="text-success">
                        {this.props.accountDetailBlobName}
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
