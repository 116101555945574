import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { PartsDetailChartComponent } from "./PartsDetailChartComponent";

export class PartsDetailReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            tableTitle: "",
            tableData: [],
            paintTable: false,
            chartData: null,
            paintChart: false,
            invoiceTableTitle: "",
            invoiceTableData: [],
            invoicePaintTable: false,
            topTitle: "",
            showPartsReport: false
        };

        this.onInvoiceClick = this.onInvoiceClick.bind(this);
        this.onInvoiceCancelClick = this.onInvoiceCancelClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.selectedMonth && this.props.rowData
                && (prevProps.selectedMonth !== this.props.selectedMonth
                    || prevProps.rowData !== this.props.rowData)) {
                this.getSummaryData(this.props.selectedMonth, this.props.rowData.customer_number,
                    this.props.rowData.store_number, this.props.rowData.store_name_web, this.props.rowData.store_name);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Parts Report";

    getSummaryData(selectedMonth, customerNumber, storeNumber, storeNameWeb, storeName) {
        this.setState({
            showSpinner: true,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });

        let inputData = {
            startDate: selectedMonth,
            customerNumber: customerNumber,
            storeNumber: storeNumber
        };

        this.props.applicationInsights.trackTrace({ message: "/PartsDetailReportComponent/getSummaryData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/PartsReport/GetSummary", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                if (parsedResponse.length === 0) {
                    let message = "Details Not Found. Invoice Summary: " + storeName + " - " + CommonService.formatMonthYear(moment(selectedMonth));
                    this.props.onShowAlert("danger", { message: message });
                }
                else {
                    let tableTile = "", storeName = "";
                    if (this.props.type === "Customer") {
                        tableTile = "Invoice Summary";
                        storeName = storeNameWeb;
                    } else {
                        tableTile = "Store - " + storeName + " - " + CommonService.formatMonthYear(moment(selectedMonth));
                    }

                    this.setState({
                        showSpinner: false,
                        tableTitle: tableTile,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true,
                        topTitle: "Store Summary by Invoice - " + storeName + " - " + CommonService.formatMonthYear(moment(this.props.selectedMonth))
                    }, () => {
                        this.setState({ paintTable: false });
                        this.getChartData();
                    });
                }
            })
            .catch(notOKResponse => {
                this.setState({
                    showSpinner: false,
                    tableTile: "",
                    tableData: [],
                    paintTable: true,
                    topTitle: ""
                }, () => {
                    this.setState({ paintTable: false });
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/GetSummary", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getChartData() {
        let inputData = {
            startDate: this.props.selectedMonth,
            customerNumber: this.props.rowData.customer_number,
            storeNumber: this.props.rowData.store_number
        };

        this.props.applicationInsights.trackTrace({ message: "/PartsDetailReportComponent/getChartData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/PartsReport/GetChartData", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                if (parsedResponse && parsedResponse.length > 1) {
                    this.setState({
                        chartData: parsedResponse,
                        paintChart: true
                    });
                }
            })
            .catch(notOKResponse => {
                this.setState({
                    chartData: null,
                    paintChart: true
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/GetChartData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onInvoiceClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                invoiceNumber: rowData.invoice_number
            };

            this.props.applicationInsights.trackTrace({ message: "/PartsDetailReportComponent/onInvoiceClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/PartsReport/GetPartsInvoice", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        invoiceTableTitle: "Invoice Detail - " + rowData.invoice_number,
                        invoiceTableData: CommonService.addOrdinalColumn(parsedResponse),
                        invoicePaintTable: true
                    });
                }, () => {
                    this.setState({ invoicePaintTable: false });
                })
                .catch(notOKResponse => {
                    this.setState({
                        invoiceTableTitle: "",
                        invoiceTableData: [],
                        invoicePaintTable: true
                    }, () => {
                        this.setState({ invoicePaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/GetPartsInvoice", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onInvoiceCancelClick(event) {
        try {
            this.setState({
                invoiceTableTitle: "",
                invoiceTableData: [],
                invoicePaintTable: true
            }, () => {
                this.setState({ invoicePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                paintTable: true,
                tableData: [],
                tableTile: ""
            }, () => {
                this.setState({ paintTable: false });
                this.props.onCancelClick(event);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("PartsDetailReportComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let charTitle = this.props.type === "Store" ? "Parts Sales by Store" : "Parts Cost and Sales by Customer";
        if (this.props.selectedMonth) {
            charTitle += " - " + CommonService.formatMonthYear(moment(this.props.selectedMonth));
        }
        return <Fragment>
            <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.rowData && this.state.tableData.length > 0} cssName="dgdg-parts-detail-dialog" title={this.state.topTitle} onCancelClick={this.onCancelClick}>
                <div className="dgdg-report-body">
                    <DGDGModalDialogComponent
                        applicationInsights={this.props.applicationInsights}
                        showDialog={this.state.invoiceTableData.length > 0}
                        cssName="dgdg-parts-invoice-dialog"
                        title={this.state.invoiceTableTitle}
                        onCancelClick={this.onInvoiceCancelClick}
                    >
                        <div className="dgdg-report-body">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.invoiceTableData} paintTable={this.state.invoicePaintTable} showFooter
                                defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc"
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn="ordinal" sortColumn="ordinal" dataType="number" />
                                    <DGDGTableColumnComponent headerText="Invoice #" dataColumn="invoice_number" sortColumn="invoice_number" />
                                    <DGDGTableColumnComponent headerText="Part #" dataColumn="part_number" sortColumn="part_number" />
                                    <DGDGTableColumnComponent headerText="Part Description" dataColumn="description" sortColumn="description" />
                                    <DGDGTableColumnComponent headerText="Quantity" dataColumn="qty" sortColumn="qty" footerText="Total" />
                                    <DGDGTableColumnComponent headerText="Cost" dataColumn="cost" sortColumn="cost" dataType="money" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Sale" dataColumn="sale" sortColumn="sale" dataType="money" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Salesperson" dataColumn="salesperson_name" sortColumn="salesperson_name" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                    </DGDGModalDialogComponent>
                    <div className="overflow-auto dgdg-flex dgdg-flex-row">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} showSpinner={this.state.showSpinner} showFooter
                            defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc"
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} footerText="Total" />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name" sortColumn="store_name" filterColumn="store_name" cssName="text-nowrap" />
                                <DGDGTableColumnComponent headerText="Invoice #" dataColumn="invoice_number" onCellClick={this.onInvoiceClick} sortColumn="invoice_number" filterColumn="invoice_number" />
                                <DGDGTableColumnComponent headerText="Customer #" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                                <DGDGTableColumnComponent headerText="Sale Type" dataColumn="sale_type" sortColumn="sale_type" filterColumn="sale_type" />
                                <DGDGTableColumnComponent headerText="Closed Date" dataColumn="closed" dataType="date" sortColumn="closed" />
                                <DGDGTableColumnComponent headerText="Total Cost" dataColumn="total_cost" dataType="money_2" sortColumn="total_cost" footerFunction="sum" />
                                <DGDGTableColumnComponent headerText="Total Sale" dataColumn="total_sale" dataType="money_2" sortColumn="total_sale" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                        <PartsDetailChartComponent
                            applicationInsights={this.props.applicationInsights}
                            headerText={charTitle}
                            chartData={this.state.chartData}
                            paintChart={this.state.paintChart}
                        />
                    </div>
                </div>
            </DGDGModalDialogComponent>
        </Fragment>;
    }
}
