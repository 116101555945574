import React, { Fragment } from "react";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../dgdginput/DGDGFormInputComponent";
import { DGDGFormTextAreaComponent } from "../dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGModalDialogComponent } from "../dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGSpinnerComponent } from "../dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableRowComponent } from "../dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../dgdgtable/DGDGTableV3Component";
import { DGDGFormDropdownComponent } from "../dgdgdropdown/DGDGFormDropdownComponent";

export class DGDGImportWizardComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner && props.importStatus !== "") {
                newState.showSpinner = false;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            textData: "",
            isTextChanged: false,
            showSpinner: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onVerifyClick = this.onVerifyClick.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (!this.props.showDialog && prevProps.showDialog) {
                this.setState({ textData: "" });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Import Wizard"

    onCopyClick(event) {
        try {
            navigator.clipboard.writeText(this.props.tsvColumnHeader);
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DGDGImportWizardComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value,
                isTextChanged: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVerifyClick(event) {
        try {
            this.setState({
                isTextChanged: false
            });
            this.props.onVerifyClick(event, this.state.textData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onImportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onImportClick(event, this.state.textData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            cssName="dgdg-import-wizard-dialog" showDialog={this.props.showDialog} title={this.props.title}
            onCancelClick={this.props.onCancelClick}
        >
            {
                this.props.showBillingDate
                    ? <Fragment>
                        <div className="card">
                            <div className="card-header dgdg-font-navy">Data Entry</div>
                            <div className="card-block">
                                <div className="dgdg-report-body">
                                    <div className="card">
                                        <div className="card-block">
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormDropdownComponent label="Billing Date" displayFormat="monthYear" data={this.props.months} value={this.props.selectedMonth} onItemClick={this.props.onMonthClick} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </Fragment>
                    : null
            }
            <div className="card">
                <div className="card-header dgdg-font-navy">Step 1:  Prepare your data in a Spreadsheet by copying and pasting the following headers</div>
                <div className="card-block">
                    <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form-inline">
                                    <div className="form-row">
                                        {
                                            this.props.isMultipleLines
                                                ? <DGDGFormTextAreaComponent value={this.props.tsvColumnHeader} rows="5" colSpan="col-11" controlSpan="col-12" readOnly />
                                                : <DGDGFormInputComponent value={this.props.tsvColumnHeader} colSpan="col-11" controlSpan="col-12" readOny hideClear />
                                        }
                                        <div className="col-1">
                                            <button className="btn btn-primary float-right" onClick={(event) => this.onCopyClick(event)}>
                                                Copy<span className="far fa-copy dgdg-icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header dgdg-font-navy">
                    <div className="form-inline">
                        Step 2: Paste your table data below (including the headers from the previous step) and click Verify
                        <button className="btn btn-primary" disabled={this.state.textData === ""} style={{ "position": "absolute", "right": "20px" }} onClick={(event) => this.onVerifyClick(event)}>
                            Verify<i className="fas fa-clipboard-check dgdg-icon" />
                        </button>
                    </div>
                </div>
                <div className="card-block">
                    <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form-inline">
                                    <div className="form-row">
                                        <DGDGFormTextAreaComponent id="textData" value={this.state.textData} onChange={this.onTextChange} rows="5" colSpan="col-12" controlSpan="col-12" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header dgdg-font-navy">
                    <div className="form-inline">
                        Step 3: Preview Data
                        <div style={{ "position": "absolute", "right": "130px" }}>
                            <div className="d-inline-block dgdg-import-wizard-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-import-wizard-good-col" />&nbsp;:&nbsp;&nbsp;Good Data</div>
                            <div className="d-inline-block dgdg-import-wizard-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-import-wizard-invalid-col" />&nbsp;:&nbsp;&nbsp;Data Invalid</div>
                            <div className="d-inline-block dgdg-import-wizard-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-import-wizard-missing-col" />&nbsp;:&nbsp;&nbsp;Column required</div>
                            <div className="d-inline-block dgdg-import-wizard-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-import-wizard-ignored-col" />&nbsp;:&nbsp;&nbsp;Column Ignored</div>
                        </div>
                        <button className="btn btn-primary" disabled={!this.props.isValid || this.state.isTextChanged} style={{ "position": "absolute", "right": "20px" }} onClick={(event) => this.onImportClick(event)}>
                            Import <span className="fas fa-save dgdg-icon" />
                        </button>
                    </div>
                </div>
                <div className="card-block">
                    <div className="dgdg-report-body" style={{ "maxHeight": "200px" }}>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width dgdg-import-wizard-verify-table" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showSpinner={this.props.showTableSpinner}>
                            <DGDGTableRowComponent>
                                {this.props.tableColumns}
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
                <div className="card-block">
                    <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block" dangerouslySetInnerHTML={{ __html: this.props.verifyStatus }} />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header dgdg-font-navy">
                    <div className="form-inline">
                        Step 4: Import Results
                    </div>
                </div>
                <div className="card-block">
                    <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block" dangerouslySetInnerHTML={{ __html: this.props.importStatus }} />
                            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} cssName="sk-cube-center" />
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
