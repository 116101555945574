import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGImportWizardComponent } from "../common/dgdgimportwizard/DGDGImportWizardComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnCheckboxComponent } from "../common/dgdgtable/DGDGTableColumnCheckboxComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGToggleBoxComponent } from "../common/dgdgtogglebox/DGDGToggleBoxComponent";

import { UsedCarMerchandisingQueryComponent } from "./UsedCarMerchandisingQueryComponent";

export class UsedCarMerchandisingRouteComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("UsedCarMerchandisingRouteComponent - getDerivedStateFromProps");
            if (state.tableData.filter((rowData) => { return rowData.isSelected; }).length > 0) {
                newState.isUsedCarSelected = true;
            } else {
                newState.isUsedCarSelected = false;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isPublishing: false,
            isUsedCarSelected: false,
            tableTitle: "",
            tableData: [],
            paintTable: false,

            showBulkUpdateDataDialog: false,
            bulkUpdateDataIsValid: false,
            bulkUpdateDataTableColumns: null,
            bulkUpdateDataTableData: [],
            bulkUpdateDataShowTableSpinner: false,
            bulkUpdateDataPaintTable: false,
            bulkUpdateDataStatus: "",
            bulkUpdateDataVerifyStatus: "",

            undoDetail: null,
            showPublishConfirmation: false,
            publishConfirmationMessage: "",
            modifiedRows: [],

            cdnPhotoRowData: null,
            cdnPhotoUrls: null,
            selectedCdnPhoto: null,
            selectedCdnPhotoName: null,
            selectedCdnPhotoIndex: -1,

            showToggle: false,
            toggleId: null,
            editTop: null,
            editLeft: null,
            toggleValue: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onUndoInventoryClick = this.onUndoInventoryClick.bind(this);
        this.onOkUndoClick = this.onOkUndoClick.bind(this);
        this.onCancelUndoClick = this.onCancelUndoClick.bind(this);
        this.onPublishCellClick = this.onPublishCellClick.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.onOkPublishClick = this.onOkPublishClick.bind(this);
        this.onCancelPublishClick = this.onCancelPublishClick.bind(this);
        this.onBulkUpdateDataClick = this.onBulkUpdateDataClick.bind(this);
        this.onVerifyBulkUpdateDataClick = this.onVerifyBulkUpdateDataClick.bind(this);
        this.onConfirmBulkUpdateDataClick = this.onConfirmBulkUpdateDataClick.bind(this);
        this.onCancelBulkUpdateDataClick = this.onCancelBulkUpdateDataClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onPriceBumberChange = this.onPriceBumberChange.bind(this);
        this.onMerchVehicleTypeChange = this.onMerchVehicleTypeChange.bind(this);
        this.onHtmlDescriptionChange = this.onHtmlDescriptionChange.bind(this);
        this.onTextDescriptionChange = this.onTextDescriptionChange.bind(this);
        this.onInventoryStateChange = this.onInventoryStateChange.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.onHideEditClick = this.onHideEditClick.bind(this);
        this.onShowCDNPhotosClick = this.onShowCDNPhotosClick.bind(this);
        this.onCdnPhotoThumbnailClick = this.onCdnPhotoThumbnailClick.bind(this);
        this.onCancelCDNPhotosClick = this.onCancelCDNPhotosClick.bind(this);
        this.onPrevCdnPhotoClick = this.onPrevCdnPhotoClick.bind(this);
        this.onNextCdnPhotoClick = this.onNextCdnPhotoClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("UsedCarMerchandisingRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/    ", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("UsedCarMerchandisingRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Used Car Merchandising";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "UsedCarMerchandisingRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    publishUsedCarMerchandising(modifiedRows, message) {
        this.setState({ isPublishing: true });
        let inputData = [];
        modifiedRows.forEach((rowData) => {
            inputData.push({
                stockNumber: rowData.stock_number
            });
        });

        this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/publishUsedCarMerchandising", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/UsedCarMerchandising/Publish", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                modifiedRows.forEach((rowData) => {
                    rowData.rowCssName = null;
                    rowData.isSelected = false;
                });

                this.setState({
                    isPublishing: false,
                    modifiedRows: []
                }, this.onCancelPublishClick);
                this.props.onShowAlert("success", { message: "Selected inventory records published" }, 4000);
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/Publish", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onActionColumnCallback(rowData) {
        if (rowData.rowCssName === "dgdg-inventory-merch-bg-modified") {
            return <div>
                {/* <button type="button" className="btn btn-link" onClick={(event) => this.onUndoInventoryClick(event, rowData)}>
                    <span className="fas fa-undo" />
                </button> */}
                <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onPublishCellClick(event, rowData)}>
                    <span className="fas fa-upload" />
                </button>
            </div>;
        }
        else {
            return <div>
                <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onPublishCellClick(event, rowData)}>
                    <span className="fas fa-upload" />
                </button>
            </div>;
        }
    }

    onGetReportClick(vin, stockNumber) {
        try {
            vin = vin === "" || vin === undefined ? null : vin;
            stockNumber = stockNumber === "" || stockNumber === undefined ? null : stockNumber;
            this.setState({ isLoadingData: true });

            let inputData = {
                vin: vin,
                stockNumber: stockNumber
            };

            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.original_price_bumper = responseItem.price_bumper;
                        responseItem.original_merch_vehicle_type = responseItem.merch_vehicle_type;
                        responseItem.original_html_vehicle_description = responseItem.html_vehicle_description;
                        responseItem.original_text_vehicle_description = responseItem.text_vehicle_description;
                        responseItem.original_inventory_state = responseItem.inventory_state;
                        responseItem.edit_header_description = "HTML Description. Stock #: " + responseItem.stock_number;
                        responseItem.edit_header_text_description = "Text Description. Stock #: " + responseItem.stock_number;
                        responseItem.inventory_state_toggle = responseItem.inventory_state === "Live" ? true : false;
                    });
                    this.setState({
                        isLoadingData: false,
                        tableTitle: (vin === null ? "All VIN" : vin) + " - " + (stockNumber === null ? "All Stock Number" : stockNumber),
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPublishClick(event) {
        try {
            if (this.state.isUsedCarSelected) {
                this.setState({
                    showPublishConfirmation: true,
                    publishConfirmationMessage: "Do you want to publish descriptions and pricing for the selected used cars to Homnet?"
                });
            } else {
                this.setState({
                    showPublishConfirmation: true,
                    publishConfirmationMessage: "Do you want to publish descriptions and pricing for all used cars to Homnet?"
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSelectChange(event, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.isSelected = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkPublishClick(event) {
        let modifiedRows = [];
        try {
            if (this.state.modifiedRows.length === 0) {
                this.state.tableData.forEach((rowData, index) => {
                    if (rowData.isSelected)
                        modifiedRows.push(rowData);
                });
            }
            else
                modifiedRows = this.state.modifiedRows;
            this.publishUsedCarMerchandising(modifiedRows);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelPublishClick() {
        try {
            this.setState({ showPublishConfirmation: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPublishCellClick(event, rowData) {
        let modifiedRows = []
        modifiedRows.push(rowData)
        try {
            this.setState({
                showPublishConfirmation: true,
                modifiedRows: modifiedRows,
                publishConfirmationMessage: "Do you want to publish descriptions and pricing for Stock Number: " + rowData.stock_number + " to Homnet?"
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoInventoryClick(event, rowData) {
        try {
            this.setState({ undoDetail: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkUndoClick(event) {
        try {
            let tableData = this.state.tableData;
            let rowData = this.state.undoDetail;

            rowData.price_bumper = this.state.undoDetail.original_price_bumper;
            rowData.merch_vehicle_type = this.state.undoDetail.original_merch_vehicle_type;
            rowData.html_vehicle_description = this.state.undoDetail.original_html_vehicle_description;
            rowData.text_vehicle_description = this.state.undoDetail.original_text_vehicle_description;
            rowData.inventory_state = this.state.undoDetail.original_inventory_state;
            rowData.rowCssName = null;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
                this.onCancelUndoClick();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelUndoClick(event) {
        try {
            this.setState({ undoDetail: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onBulkUpdateDataClick(event) {
        try {
            this.setState({ showBulkUpdateDataDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    updatesTSVHeader = "StockNo\tVehicleType\tHTMLDescription\tTextDescription\tPriceBumper";
    onVerifyBulkUpdateDataClick(event, tsvText) {
        try {
            this.setState({
                bulkUpdateDataShowTableSpinner: true,
                bulkUpdateDataPaintTable: true
            }, () => {
                this.setState({ bulkUpdateDataPaintTable: false });
            });
            let inputData = { tsvText: tsvText };

            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onVerifyBulkUpdateDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/VerifyBulkUpdateData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableColumns = [];
                    tableColumns.push(<DGDGTableColumnComponent key="Ordinal" headerText="#" dataColumn={CommonService.ordinalColumnName} sortColumn={CommonService.ordinalColumnName} dataType="number" />);
                    this.updatesTSVHeader.split("\t").forEach((header, key) => {
                        if (parsedResponse.missingFields && parsedResponse.missingFields.includes(header)) {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-used-car-merchandising-missing-col" />);
                        }
                        else {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} backgroundColor={header + "BgColor"} />);
                        }
                    });

                    if (parsedResponse.additionalFields && parsedResponse.additionalFields.length > 0) {
                        parsedResponse.additionalFields.forEach((header, key) => {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-used-car-merchandising-extra-col" />);
                        });
                    }

                    this.setState({
                        bulkUpdateDataIsValid: parsedResponse.isValid,
                        bulkUpdateDataTableColumns: tableColumns,
                        bulkUpdateDataTableData: CommonService.addOrdinalColumn(parsedResponse.tsvDataTable),
                        bulkUpdateDataVerifyStatus: parsedResponse.message,
                        bulkUpdateDataShowTableSpinner: false,
                        bulkUpdateDataPaintTable: true
                    }, () => {
                        this.setState({ bulkUpdateDataPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        bulkUpdateDataShowTableSpinner: true,
                        bulkUpdateDataPaintTable: true
                    }, () => {
                        this.setState({ bulkUpdateDataPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/VerifyBulkUpdateData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    bulkUpdateDataVerifyStatus: "Failed to import data. Reason: " + parsedError.message
                                });
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmBulkUpdateDataClick(event, tsvText) {
        try {
            let inputData = { tsvText: tsvText };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onConfirmBulkUpdateDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/ConfirmBulkUpdateData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ bulkUpdateDataStatus: parsedResponse.message });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/ConfirmBulkUpdateData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    bulkUpdateDataStatus: "Failed to import data. Reason: " + parsedError.message
                                });
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelBulkUpdateDataClick(event) {
        try {
            this.setState({
                showBulkUpdateDataDialog: false,
                bulkUpdateDataIsValid: false,
                bulkUpdateDataTableColumns: null,
                bulkUpdateDataTableData: [],
                bulkUpdateDataShowTableSpinner: false,
                bulkUpdateDataPaintTable: true,
                bulkUpdateDataVerifyStatus: "",
                bulkUpdateDataStatus: ""
            }, () => {
                this.setState({ bulkUpdateDataPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("UsedCarMerchandisingRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPriceBumberChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.price_bumper_showSpinner = true;
            rowData.price_bumper = text;
            rowData.rowCssName = "dgdg-inventory-merch-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                priceBumper: rowData.price_bumper,
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onPriceBumberChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/SavePriceBumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.price_bumper_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/SavePriceBumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMerchVehicleTypeChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.merch_vehicle_type_showSpinner = true;
            rowData.merch_vehicle_type = text;
            rowData.rowCssName = "dgdg-inventory-merch-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                MerchVehicleType: rowData.merch_vehicle_type,
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onPriceBumberChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/SaveMerchVehicleType", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.merch_vehicle_type_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/SaveMerchVehicleType", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onHtmlDescriptionChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.html_vehicle_description_showSpinner = true;
            rowData.html_vehicle_description = text;
            rowData.rowCssName = "dgdg-inventory-merch-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                htmlVehicleDescription: rowData.html_vehicle_description,
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onHtmlDescriptionChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/SaveHtmlVehicleDescription", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.html_vehicle_description_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/SaveHtmlVehicleDescription", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextDescriptionChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.text_vehicle_description_showSpinner = true;
            rowData.text_vehicle_description = text;
            rowData.rowCssName = "dgdg-inventory-merch-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                textVehicleDescription: rowData.text_vehicle_description,
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onTextDescriptionChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/SaveTextVehicleDescription", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.text_vehicle_description_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/SaveTextVehicleDescription", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onInventoryStateChange(event, id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData.inventory_state = rowData.inventory_state_toggle ? "Live" : "InPrep";
            rowData.rowCssName = "dgdg-inventory-merch-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                homenetState: rowData.inventory_state,
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedCarMerchandisingRouteComponent/onInventoryStateChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedCarMerchandising/SaveHomenetState", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.rowCssName = null;

                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                        this.onCancelUndoClick();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedCarMerchandising/SaveHomenetState", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        this.onHideEditClick(event);
    }

    onToggleChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];

            rowData.inventory_state_toggle = value;
            this.setState({
                tableData: tableData,
                toggleValue: rowData.inventory_state_toggle,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onHideEditClick(event) {
        if (this.state.showToggle) {
            this.setState({
                showToggle: false,
                toggleId: null,
                editTop: null,
                editLeft: null,
                toggleValue: false
            });
        }
    }

    onShowCDNPhotosClick(event, rowData) {
        try {
            if (rowData.cdn_image_list) {
                let cdnPhotoUrls = rowData.cdn_image_list.split(',');
                this.setState({
                    cdnPhotoRowData: rowData,
                    cdnPhotoUrls: cdnPhotoUrls,
                    selectedCdnPhoto: cdnPhotoUrls[0],
                    selectedCdnPhotoIndex: 0,
                    selectedCdnPhotoName: this.getCDNPhotoNameFromUrl(cdnPhotoUrls[0])
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCdnPhotoThumbnailClick(event, selectedValue) {
        try {
            this.setState({
                selectedCdnPhoto: selectedValue,
                selectedCdnPhotoName: this.getCDNPhotoNameFromUrl(selectedValue),
                selectedCdnPhotoIndex: this.state.cdnPhotoUrls.indexOf(selectedValue)
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelCDNPhotosClick() {
        this.setState({
            cdnPhotoRowData: null,
            cdnPhotoUrls: null,
            selectedCdnPhoto: null,
            selectedCdnPhotoName: null,
            selectedCdnPhotoIndex: -1
        });
    }

    getCDNPhotoNameFromUrl(url) {
        if (url) {
            let urlFragments = url.split('/');
            return urlFragments[urlFragments.length - 1];
        }

        return null;
    }

    onPrevCdnPhotoClick() {
        if (this.state.selectedCdnPhotoIndex > 0) {
            this.setState({
                selectedCdnPhoto: this.state.cdnPhotoUrls[this.state.selectedCdnPhotoIndex - 1],
                selectedCdnPhotoIndex: this.state.selectedCdnPhotoIndex - 1,
                selectedCdnPhotoName: this.getCDNPhotoNameFromUrl(this.state.cdnPhotoUrls[this.state.selectedCdnPhotoIndex - 1])
            });
        }
    }

    onNextCdnPhotoClick() {
        if (this.state.selectedCdnPhotoIndex < this.state.cdnPhotoUrls.length - 1) {
            this.setState({
                selectedCdnPhoto: this.state.cdnPhotoUrls[this.state.selectedCdnPhotoIndex + 1],
                selectedCdnPhotoIndex: this.state.selectedCdnPhotoIndex + 1,
                selectedCdnPhotoName: this.getCDNPhotoNameFromUrl(this.state.cdnPhotoUrls[this.state.selectedCdnPhotoIndex + 1])
            });
        }
    }

    render() {
        let cdnPhotoThumbnails = [];
        if (this.state.cdnPhotoUrls && this.state.cdnPhotoUrls.length > 0) {
            this.state.cdnPhotoUrls.forEach((url) => {
                cdnPhotoThumbnails.push(
                    <div className="d-inline-block">
                        <div className="dgdg-used-car-merchandising-thumbnail-image">
                            <img src={url} role="button" onClick={(event) => this.onCdnPhotoThumbnailClick(event, url)} alt="Not Available" />
                        </div>
                    </div>
                );
            });
        }

        return <DGDGPageComponent>
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Undo changes to Stock #: " + (this.state.undoDetail !== null ? this.state.undoDetail.stock_number : "")}
                bodyText="Are you sure?"
                showConfirmation={this.state.undoDetail !== null}
                onOkClick={this.onOkUndoClick}
                onCancelClick={this.onCancelUndoClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Publish Inventory"}
                bodyText={this.state.publishConfirmationMessage}
                showConfirmation={this.state.showPublishConfirmation}
                onOkClick={this.onOkPublishClick}
                onCancelClick={this.onCancelPublishClick}
            />
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.cdnPhotoRowData}
                cssName="dgdg-used-car-merchandising-cdn-photos-dialog"
                title={"CDN Photos for Stock Number: " + (this.state.cdnPhotoRowData ? this.state.cdnPhotoRowData.stock_number : "")}
                onCancelClick={this.onCancelCDNPhotosClick}
            >
                <div style={{ "minHeight": "480px" }}>
                    {
                        this.state.cdnPhotoUrls
                            ? this.state.cdnPhotoUrls.length === 0
                                ? <div className="dgdg-sell-trade-photo-water-mark">No Image Available.</div>
                                : <Fragment>
                                    <div className="container-fluid text-center">
                                        <div className="row">
                                            <div className="col-1 p-2 dgdg-used-car-merchandising-cdn-photo-nav">
                                                <button type="button" className="btn btn-link" onClick={this.onPrevCdnPhotoClick}>
                                                    <span className="fas fa-angle-left" />
                                                </button>
                                            </div>
                                            <div className="col-10 dgdg-used-car-merchandising-cdn-photo">
                                                <img src={this.state.selectedCdnPhoto} alt="Not Available" />
                                            </div>
                                            <div className="col-1 p-2 dgdg-used-car-merchandising-cdn-photo-nav">
                                                <button type="button" className="btn btn-link" onClick={this.onNextCdnPhotoClick}>
                                                    <span className="fas fa-angle-right" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <span className="col-12">{this.state.selectedCdnPhotoName}</span>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="text-left">
                                        {cdnPhotoThumbnails}
                                    </div>
                                </Fragment>
                            : null}
                </div>
            </DGDGModalDialogComponent>
            <DGDGToggleBoxComponent
                showToggle={this.state.showToggle}
                top={this.state.editTop}
                left={this.state.editLeft}
                value={this.state.toggleValue}
                id={this.state.toggleId}
                label="InPrep"
                label2="Live"
                cssName="dgdg-inventory-merch-homenet-state"
                onHideClick={this.onHideEditClick}
                onClick={this.onInventoryStateChange}
                onChange={this.onToggleChange}
            />
            <DGDGImportWizardComponent
                title="Bulk Update Inventory Data"
                tsvColumnHeader={this.updatesTSVHeader}
                showDialog={this.state.showBulkUpdateDataDialog} importStatus={this.state.bulkUpdateDataStatus} verifyStatus={this.state.bulkUpdateDataVerifyStatus} isValid={this.state.bulkUpdateDataIsValid}
                tableColumns={this.state.bulkUpdateDataTableColumns} tableData={this.state.bulkUpdateDataTableData} showTableSpinner={this.state.bulkUpdateDataShowTableSpinner} paintTable={this.state.bulkUpdateDataPaintTable}
                onVerifyClick={this.onVerifyBulkUpdateDataClick}
                onImportClick={this.onConfirmBulkUpdateDataClick}
                onCancelClick={this.onCancelBulkUpdateDataClick}
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
            />
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <UsedCarMerchandisingQueryComponent
                    isLoadingData={this.state.isLoadingData}
                    isPublishing={this.state.isPublishing}
                    isUsedCarSelected={this.state.isUsedCarSelected}
                    applicationInsights={this.props.applicationInsights}
                    onGetReportClick={this.onGetReportClick}
                    onPublishClick={this.onPublishClick}
                    onBulkUpdateDataClick={this.onBulkUpdateDataClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} onCellEdited={this.onPriceBumberChange}
                        copyTableConfig={{ "columns": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnCheckboxComponent dataColumn="isSelected" disableSelectAll={true} onChange={this.onSelectChange} />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} headerCssName="dgdg-used-car-merchandising-action-column" />
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="Vin" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_name_web" filterColumn="store_name_web" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="CDK Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                            <DGDGTableColumnComponent headerText="Age" dataColumn="age" sortColumn="age" dataType="number" />
                            <DGDGTableColumnComponent headerText="Odometer" dataColumn="odometer" sortColumn="odometer" dataType="number" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" dataType="number" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                            <DGDGTableColumnComponent headerText="Trim" dataColumn="trim" sortColumn="trim" />
                            <DGDGTableColumnComponent headerText="Certified" dataColumn="certified" sortColumn="certified" dataType="bool" />
                            <DGDGTableColumnComponent headerText="Homenet Photo Count" dataColumn="photo_count" sortColumn="photo_count" dataType="number" />
                            <DGDGTableColumnComponent headerText="CDN Photo Count" dataColumn="cdn_image_count" sortColumn="cdn_image_count" dataType="number" onCellClick={this.onShowCDNPhotosClick} />
                            <DGDGTableColumnComponent headerText="Selling Price" dataColumn="selling_price" sortColumn="selling_price" filterColumn="selling_price" dataType="money" />
                            <DGDGTableColumnComponent headerText="Price Bumper" dataColumn="price_bumper" sortColumn="price_bumper" filterColumn="price_bumper" dataType="money" cssName="text-nowrap" isEditable onChange={this.onPriceBumberChange} showSpinner />
                            <DGDGTableColumnComponent headerText="Vehicle Type" dataColumn="merch_vehicle_type" cssName="dgdg-used-car-merchandising-vehicle-type-col" isEditable onChange={this.onMerchVehicleTypeChange} showSpinner />
                            <DGDGTableColumnComponent headerText="HTML Description" dataColumn="html_vehicle_description" maxLength={50} sortColumn="html_vehicle_description" filterColumn="html_vehicle_description" editHeaderColumn="edit_header_description" dataType="comment" cssName="text-nowrap" isEditable onChange={this.onHtmlDescriptionChange} showSpinner />
                            <DGDGTableColumnComponent headerText="Text Description" dataColumn="text_vehicle_description" maxLength={50} sortColumn="text_vehicle_description" filterColumn="text_vehicle_description" editHeaderColumn="edit_header_text_description" dataType="comment" cssName="text-nowrap" isEditable onChange={this.onTextDescriptionChange} showSpinner />
                            <DGDGTableColumnComponent headerText="Homenet State" dataColumn="homenet_state" sortColumn="homenet_state" filterColumn="homenet_state" cssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
