import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { PartsCustomerSummaryQueryComponent } from "./PartsCustomerSummaryQueryComponent";
import { PartsCustomerStoreSummaryComponent } from "./PartsCustomerStoreSummaryComponent";

export class PartsCustomerSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            tableData: null,
            tableTitle: "",
            isLoadingData: false,
            paintTable: false,
            selectedMonth: null,
            rowData: null
        };

        this.onGetCustomerSummaryClick = this.onGetCustomerSummaryClick.bind(this);
        this.onCustomerCellClick = this.onCustomerCellClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Parts Report";

    componentDidMount() {
        try {
            console.devLog("PartsCustomerSummaryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/PartsReport/CustomerSummary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("PartsCustomerSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetCustomerSummaryClick(selectedMonth) {
        try {
            if (selectedMonth) {
                this.setState({ isLoadingData: true });
                let inputData = {
                    startDate: CommonService.formatServerDateTime(selectedMonth)
                };

                this.props.applicationInsights.trackTrace({ message: "/PartsCustomerSummaryComponent/onGetCustomerSummaryClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/PartsReport/GetCustomerSummary", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: CommonService.formatMonthYear(moment(selectedMonth)),
                            tableData: CommonService.addOrdinalColumn(parsedResponse),
                            paintTable: true,
                            selectedMonth: selectedMonth
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "",
                            tableData: null,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/GetCustomerSummary", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerCellClick(event, rowData) {
        try {
            this.setState({ rowData: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({ rowData: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("PartsCustomerSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <PartsCustomerStoreSummaryComponent type="Customer" applicationInsights={this.props.applicationInsights} selectedMonth={this.state.selectedMonth} rowData={this.state.rowData} onCancelClick={this.onCancelClick} onShowAlert={this.props.onShowAlert} />
            <PartsCustomerSummaryQueryComponent
                months={this.state.months}
                applicationInsights={this.props.applicationInsights}
                onGetCustomerSummaryClick={this.onGetCustomerSummaryClick}
                isLoadingData={this.state.isLoadingData}

            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} headerText={this.state.tableTitle} paintTable={this.state.paintTable}
                    tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Customer #" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                        <DGDGTableColumnComponent headerText="Customer Name" onCellClick={this.onCustomerCellClick} dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Closed Date" dataType="date" dataColumn="closed_date" sortColumn="closed_date" filterColumn="closed_date" />
                        <DGDGTableColumnComponent headerText="Sale" dataColumn="sale" dataType="money" sortColumn="sale" />
                        <DGDGTableColumnComponent headerText="Cost" dataColumn="cost" dataType="money" sortColumn="cost" />
                        <DGDGTableColumnComponent headerText="Gross" dataColumn="gross" dataType="money" sortColumn="gross" />
                        <DGDGTableColumnComponent headerText="Gross Margin %" dataColumn="gross_margin_pct" dataType="percent" sortColumn="gross_margin_pct" />
                        <DGDGTableColumnComponent headerText="Credit" dataColumn="credit" dataType="money" sortColumn="credit" />
                        <DGDGTableColumnComponent headerText="Credit %" dataColumn="credit_pct" dataType="percent" sortColumn="credit_pct" />
                        <DGDGTableColumnComponent headerText="Sale Ytd" dataColumn="sale_ytd" dataType="money" sortColumn="sale_ytd" />
                        <DGDGTableColumnComponent headerText="Cost Ytd" dataColumn="cost_ytd" dataType="money" sortColumn="cost_ytd" />
                        <DGDGTableColumnComponent headerText="Gross Ytd" dataColumn="gross_ytd" dataType="money" sortColumn="gross_ytd" />
                        <DGDGTableColumnComponent headerText="Gross Margin %" dataColumn="gross_margin_pct_ytd" dataType="percent" sortColumn="gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="Credit Ytd" dataColumn="credit_ytd" dataType="money" sortColumn="credit_ytd" />
                        <DGDGTableColumnComponent headerText="Credit Ytd %" dataColumn="credit_pct_ytd" dataType="percent" sortColumn="credit_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Sale" dataColumn="py_sale" dataType="money" sortColumn="py_sale" />
                        <DGDGTableColumnComponent headerText="PY Cost" dataColumn="py_cost" dataType="money" sortColumn="py_cost" />
                        <DGDGTableColumnComponent headerText="PY Gross" dataColumn="py_gross" dataType="money" sortColumn="py_gross" />
                        <DGDGTableColumnComponent headerText="PY Gross Margin %" dataColumn="py_gross_margin_pct_ytd" dataType="percent" sortColumn="py_gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit" dataColumn="py_credit" dataType="money" sortColumn="py_credit" />
                        <DGDGTableColumnComponent headerText="PY Credit %" dataColumn="py_credit_pct" dataType="percent" sortColumn="py_credit_pct" />
                        <DGDGTableColumnComponent headerText="PY Sale Ytd" dataColumn="py_sale_ytd" dataType="money" sortColumn="py_sale_ytd" />
                        <DGDGTableColumnComponent headerText="PY Cost Ytd" dataColumn="py_cost_ytd" dataType="money" sortColumn="py_cost_ytd" />
                        <DGDGTableColumnComponent headerText="PY Gross Ytd" dataColumn="py_gross_ytd" dataType="money" sortColumn="py_gross_ytd" />
                        <DGDGTableColumnComponent headerText="PY Gross Margin %" dataColumn="py_gross_margin_pct_ytd" dataType="percent" sortColumn="py_gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit Ytd" dataColumn="py_credit_ytd" dataType="money" sortColumn="py_credit_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit Ytd %" dataColumn="py_credit_pct_ytd" dataType="percent" sortColumn="py_credit_pct_ytd" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
