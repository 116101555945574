import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { CITDetailQueryComponent } from "./CITDetailQueryComponent";
import { CITDetailJournalEntryDetailsComponent } from "./CITDetailJournalEntryDetailsComponent";

export class CITDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            selectedStore: null,
            canEditIsHidden: false,
            tableTitle: "",
            lastSavedRowData: null,
            tableData: [],
            paintTable: false,
            journalEntryDetailsTitle: "",
            journalEntryDetailsTableData: [],
            journalEntryDetailsPaintTable: false,
            showjournalEntryDetails: false,
            totalPostingAmount: 0,
            showHidden: false,
            StatusData: [
                { key: "Not Funded", value: "Not Funded" },
                { key: "Funded", value: "Funded" },
                { key: "Fail", value: "Fail" },
                { key: "Potential Fail", value: "Potential Fail" },
                { key: "Missing Down Payment", value: "Missing Down Payment" },
                { key: "Need Clean Air Rebate", value: "Need Clean Air Rebate" },
                { key: "Rollback", value: "Rollback" },
                { key: "Out For Repo", value: "Out For Repo" },
                { key: "Factory Buy Back", value: "Factory Buy Back" },
                { key: "Holding Funds", value: "Holding Funds" },
                { key: "Short Funded", value: "Short Funded" },
                { key: "Down Payment Not Posted", value: "Down Payment Not Posted" },
                { key: "Fraud", value: "Fraud" }
            ],
            legendText: null
        };

        this.onShowHiddenChange = this.onShowHiddenChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCustomerNumberCellClick = this.onCustomerNumberCellClick.bind(this);
        this.onCustomerNumberCancelClick = this.onCustomerNumberCancelClick.bind(this);

        this.onCopyRowColumnCallback = this.onCopyRowColumnCallback.bind(this);
        this.onCopyRowClick = this.onCopyRowClick.bind(this);

        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onCommentBlur = this.onCommentBlur.bind(this);

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSaveCITCommentClick = this.onSaveCITCommentClick.bind(this);
        this.onIsHiddenChange = this.onIsHiddenChange.bind(this);

        this.onDownloadClick = this.onDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Contracts-In-Transit"

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    componentDidMount() {
        try {
            console.devLog("CITDetailComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CIT/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLegendText();
            CommonService.clientAzureStorageLog("CITDetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-not-funded" />&nbsp;&nbsp;&nbsp;Not Funded </div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-funded" />&nbsp;&nbsp;&nbsp;Funded</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-fail" />&nbsp;&nbsp;&nbsp;Fail</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-rollback" />&nbsp;&nbsp;&nbsp;Rollback</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-out-for-repo" />&nbsp;&nbsp;&nbsp;Out For Repo</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-factory-buy-back" />&nbsp;&nbsp;&nbsp;Factory Buy Back</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-holding-funds" />&nbsp;&nbsp;&nbsp;Holding Funds</div>
            <div className="d-inline-block dgdg-cit-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-cit-detail-status-potential-fail" />&nbsp;&nbsp;&nbsp;Potential Fail, Short Funded, Clean Air Rebate, Down Payment Issue</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onShowHiddenChange(isChecked) {
        this.setState({ showHidden: isChecked });
    }

    onGetReportClick(selectedStore, showHidden) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });

            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                showHidden: showHidden
            };

            this.onGetLastUpdatedDate();

            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    if (!showHidden) {
                        tableData = tableData.filter(rowData => !rowData.is_hidden);
                    }

                    tableData.forEach((responseItem, index) => {
                        responseItem.is_hidden_label = "Hide";
                        responseItem.finance_mgr_web_name = responseItem.finance_mgr_number ? responseItem.finance_mgr_name + " (" + responseItem.finance_mgr_number + ")" : "";
                        responseItem.original_comment = responseItem.comment;
                        responseItem.age_sort = responseItem.age;
                        responseItem.is_hidden_disabled = !responseItem.status;
                        responseItem.is_status_disabled = !responseItem.edit_status;
                        responseItem.age = responseItem.age ? responseItem.age + " Days" : null;
                        if (responseItem.balance < 0)
                            responseItem.balance_cellCssName += " dgdg-font-red";
                        this.setRowColor(responseItem);
                    });
                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        canEditIsHidden: parsedResponse.canEditIsHidden,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.props.onLastUpdatedDateChanged(lastUpdatedDate);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("warning", parsedError, 4000);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("CITDetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                customerNumber: rowData.customer_number,
                contractDate: rowData.contract_date
            };

            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onCustomerNumberCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/GetCITJournalEntryDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalPostingAmount = 0;
                    parsedResponse.forEach((responseItem, index) => {
                        totalPostingAmount += responseItem.posting_amount;
                    });
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.customer_name + " (" + rowData.customer_number + ") - Finance Deal (" + rowData.deal_number + ") - Funding Amount: " + CommonService.formatCurrency(rowData.funding_amount),
                        journalEntryDetailsTableData: CommonService.addOrdinalColumn(parsedResponse),
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true,
                        totalPostingAmount: totalPostingAmount
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.customer_name + " (" + rowData.customer_number ? rowData.customer_number : ") - Finance Deal (" + rowData.deal_number ? rowData.deal_number : ") - Funding Amount: " + CommonService.formatCurrency(rowData.funding_amount),
                        journalEntryDetailsTableData: [],
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetCITJournalEntryDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberCancelClick(event) {
        try {
            this.setState({
                journalEntryDetailsTitle: "",
                journalEntryDetailsTableData: [],
                journalEntryDetailsPaintTable: true,
                showjournalEntryDetails: false,
                totalPostingAmount: 0
            }, () => {
                this.setState({ journalEntryDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyRowColumnCallback(rowData) {
        return <Fragment>
            <div className="form-inline">
                <button type="button" className="btn btn-link" onClick={(event) => this.onCopyRowClick(event, rowData)}>
                    <span className="far fa-copy dgdg-icon" />
                </button>
            </div>
        </Fragment>;
    }

    onCopyRowClick(event, rowData) {
        try {
            let rowText = "Store\tStatus\tCust. Number\tCustomer\tContract Date\tFinance Institution\tStock Number\tDeal Number\tDeal Status\tDeal Type\tF&I Manager\tAge (Days)\tFunding Amount\tAccounting CIT\tAccounting CIT Posted Date\tBalance\r\n";

            if (rowData) {
                rowText += (rowData.store_name ? rowData.store_name : "") + "\t" + (rowData.status ? rowData.status : "") + "\t" + (rowData.customer_number ? rowData.customer_number : "") + "\t" +
                    (rowData.customer_name ? rowData.customer_name : "") + "\t" + (rowData.contract_date ? CommonService.formatDate(rowData.contract_date) : "") + "\t" + (rowData.finance_source ? (rowData.finance_source) : "") + "\t" +
                    (rowData.stock_number ? rowData.stock_number : "") + "\t" + (rowData.deal_number ? rowData.deal_number : "") + "\t" +
                    (rowData.fi_wip_status_code ? rowData.fi_wip_status_code : "") + "\t" + (rowData.sale_type ? rowData.sale_type : "") + "\t" +
                    (rowData.finance_mgr_web_name ? rowData.finance_mgr_web_name : "") + "\t" + (rowData.age ? rowData.age : "") + "\t" +
                    (rowData.funding_amount ? CommonService.formatCurrency_2(rowData.funding_amount) : "") + "\t" + (rowData.accounting_amount_cr ? CommonService.formatCurrency_2(rowData.accounting_amount_cr) : "") + "\t" +
                    (rowData.posting_date_time_cr ? CommonService.formatDate(rowData.posting_date_time_cr) : "") + "\t" + (rowData.balance ? CommonService.formatCurrency_2_simple(rowData.balance) : "") + "\r\n";
            }

            navigator.clipboard.writeText(rowText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("CITDetailComponent", "onCopyRowCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        return <Fragment>
            <div className="form-inline">
                <DGDGFormTextAreaComponent id={"comment_" + rowData.store_number + "_" + rowData.deal_number} value={rowData.comment} rows="3" disabled={!rowData.deal_number || !rowData.status || !rowData.edit_comment} additionalData={rowData} onChange={this.onCommentTextChange} colSpan="col-10" controlSpan="col-12" onBlur={this.onCommentBlur} />
                <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!rowData.deal_number || !rowData.status || !rowData.edit_comment} onClick={(event) => this.onSaveCITCommentClick(event, rowData)}>
                    <span className="fas fa-save" />
                </button>
                <DGDGSpinnerComponent showSpinner={rowData.comment_showSpinner} />
            </div>
        </Fragment>;
    }

    onCommentTextChange(id, value, rowData) {
        try {
            rowData.comment = value;
            this.setState({
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCommentBlur(id, value, rowData) {
        if (rowData.original_comment !== value) {
            setTimeout(() => {
                this.saveCITComment(rowData);
            }, 500);
        }
    }

    onSaveCITCommentClick(event, rowData) {
        this.saveCITComment(rowData);
    }

    saveCITComment(rowData) {
        if (this.state.lastSavedRowData
            && this.state.lastSavedRowData.deal_number === rowData.deal_number
            && moment().diff(this.state.lastSavedRowData.last_updated_on) < 500) {

            return;
        }

        let tableData = this.state.tableData;
        try {
            rowData.comment_showSpinner = true;
            rowData.last_updated_on = moment();
            this.setState({
                lastSavedRowData: rowData,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deal_number,
                comment: rowData.comment ? rowData.comment.replace(/\n|\r/g, " ") : rowData.comment,
                status: rowData.status
            };
            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/saveCITComment", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/SaveCITComment", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.original_comment = rowData.comment;
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.comment_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "CIT deal # " + rowData.deal_number + " comment saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.comment_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/SaveCITComment", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deal_number,
                comment: rowData.comment,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/SaveCITStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.status_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "CIT deal # " + rowData.deal_number + " status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/SaveCITStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onIsHiddenChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.is_hidden = isChecked;
            rowData.is_hidden_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deal_number,
                isHidden: rowData.is_hidden
            };

            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onIsHiddenChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/SaveCITIsHidden", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (!this.state.showHidden && isChecked) {
                        tableData = tableData.filter(row => row.deal_number !== rowData.deal_number);
                    }
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "CIT deal # " + rowData.deal_number + " hide saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/SaveCITIsHidden", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Funded":
                rowData.rowCssName = "dgdg-cit-detail-status-funded";
                break;
            case "Fail":
                rowData.rowCssName = "dgdg-cit-detail-status-fail";
                break;
            case "Potential Fail":
                rowData.rowCssName = "dgdg-cit-detail-status-potential-fail";
                break;
            case "Missing Down Payment":
                rowData.rowCssName = "custo";
                break;
            case "Need Clean Air Rebate":
                rowData.rowCssName = "dgdg-cit-detail-status-need-clean-air-rebate";
                break;
            case "Rollback":
                rowData.rowCssName = "dgdg-cit-detail-status-rollback";
                break;
            case "Out For Repo":
                rowData.rowCssName = "dgdg-cit-detail-status-out-for-repo";
                break;
            case "Factory Buy Back":
                rowData.rowCssName = "dgdg-cit-detail-status-factory-buy-back";
                break;
            case "Holding Funds":
                rowData.rowCssName = "dgdg-cit-detail-status-holding-funds";
                break;
            case "Short Funded":
                rowData.rowCssName = "dgdg-cit-detail-status-short-funded";
                break;
            case "Down Payment Not Posted":
                rowData.rowCssName = "dgdg-cit-detail-status-down-payment-not-posted";
                break;
            default:
                rowData.rowCssName = null;
        }
    }

    onDownloadClick(selectedStore, showHidden) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                showHidden: showHidden
            };

            this.props.applicationInsights.trackTrace({ message: "/CITDetailComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <CITDetailJournalEntryDetailsComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showjournalEntryDetails}
                tableData={this.state.journalEntryDetailsTableData}
                tableTitle={this.state.journalEntryDetailsTitle}
                paintTable={this.state.journalEntryDetailsPaintTable}
                totalPostingAmount={this.state.totalPostingAmount}
                onCancelClick={this.onCustomerNumberCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <CITDetailQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading} canEditIsHidden={this.state.canEditIsHidden}
                onShowHiddenChange={this.onShowHiddenChange}
                onGetReportClick={this.onGetReportClick}
                onDownloadClick={this.onDownloadClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText} stickyHeader
                    tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 17, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" data={this.state.StatusData} itemKey="value" disabledColumn="is_status_disabled" onItemClick={this.onStatusChange} headerCssName="dgdg-cit-acc-desc-col" showSpinner />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" headerCssName="dgdg-cit-acc-desc-col" />
                        <DGDGTableColumnComponent headerText="Customer #" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" onCellClick={this.onCustomerNumberCellClick} />
                        <DGDGTableColumnComponent headerText="Customer" dataColumn="customer_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Finance Institution" dataColumn="finance_source" sortColumn="finance_source" filterColumn="finance_source" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Deal Status" dataColumn="fi_wip_status_code" sortColumn="fi_wip_status_code" filterColumn="fi_wip_status_code" />
                        <DGDGTableColumnComponent headerText="Deal Type" dataColumn="sale_type" sortColumn="sale_type" filterColumn="sale_type" />
                        <DGDGTableColumnComponent headerText="F&I Manager" dataColumn="finance_mgr_web_name" sortColumn="finance_mgr_web_name" filterColumn="finance_mgr_web_name" />
                        <DGDGTableColumnComponent headerText="Age (Days)" dataColumn="age" sortColumn="age_sort" filterColumn="age_sort" />
                        <DGDGTableColumnComponent headerText="Funding Amount" dataColumn="funding_amount" sortColumn="funding_amount" filterColumn="funding_amount" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="CIT Acct Date" dataColumn="posting_date_time_drcr_1020" sortColumn="posting_date_time_drcr_1020" filterColumn="posting_date_time_drcr_1020" dataType="date" />
                        <DGDGTableColumnComponent headerText="CIT Balance" dataColumn="accounting_amount_drcr_1020" sortColumn="accounting_amount_drcr_1020" filterColumn="accounting_amount_drcr_1020" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Cash Down Acct Date" dataColumn="posting_date_time_drcr_1110" sortColumn="posting_date_time_drcr_1110" filterColumn="posting_date_time_drcr_1110" dataType="date" />
                        <DGDGTableColumnComponent headerText="Cash Down Balance" dataColumn="accounting_amount_drcr_1110" sortColumn="accounting_amount_drcr_1110" filterColumn="accounting_amount_drcr_1110" dataType="money_2_simple" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_name" isHidden />
                    </DGDGTableRowComponent>
                    {
                        this.state.canEditIsHidden
                            ? <DGDGTableRowComponent cssName="dgdg-no-border-top">
                                <DGDGTableColumnComponent dataColumnCallback={this.onCopyRowColumnCallback} />
                                <DGDGTableColumnCheckboxV2Component colSpan="5" headerText="Hide" dataColumn="is_hidden" labelColumn="is_hidden_label" dataType="bool" disabledColumn="is_hidden_disabled" onChange={this.onIsHiddenChange} showSpinner />
                                <DGDGTableColumnComponent colSpan="5" headerText="Last Updated By" dataColumn="last_updated_by" showHeader />
                                <DGDGTableColumnComponent colSpan="7" dataColumnCallback={this.onAddCommentColumnCallback} />
                            </DGDGTableRowComponent>
                            : <DGDGTableRowComponent cssName="dgdg-no-border-top">
                                <DGDGTableColumnComponent dataColumnCallback={this.onCopyRowColumnCallback} />
                                <DGDGTableColumnComponent colSpan="5" dataText="" />
                                <DGDGTableColumnComponent colSpan="5" headerText="Last Updated By" dataColumn="last_updated_by" showHeader />
                                <DGDGTableColumnComponent colSpan="7" dataColumnCallback={this.onAddCommentColumnCallback} />
                            </DGDGTableRowComponent>
                    }
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
