import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { OrgChartStoreComponent } from "./OrgChartStoreComponent";
import { OrgChartDGDGComponent } from "./OrgChartDGDGComponent";
import { OrgChartGroupSummaryComponent } from "./OrgChartGroupSummaryComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class OrgChartRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            isDownLoading: false,
            months: CommonService.getMonths()
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("OrgChartRouteComponent - componentDidMount");
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("OrgChartRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Org Chart";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OrgChartRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OrgChart/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "OrgChartRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/OrgChartRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OrgChart/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onDownloadClick(selectedMonth) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/OrgChartRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OrgChart/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/onDownloadClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/OrgChart/Store" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Store") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Store</Link>
                    <Link to="/OrgChart/DGDG" className={"nav-item nav-link" + (window.location.pathname.indexOf("/DGDG") !== -1 ? " btn btn-dark active" : " btn btn-link")}>DGDG</Link>
                    <Link to="/OrgChart/GroupSummary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/GroupSummary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Group Summary</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/OrgChart/Store" render={(routeProps) => <OrgChartStoreComponent {...this.props} stores={this.state.stores} months={this.state.months} isDownLoading={this.state.isDownLoading} onDownloadClick={this.onDownloadClick} />} />
                        <Route exact path="/OrgChart/DGDG" render={(routeProps) => <OrgChartDGDGComponent {...this.props} stores={this.state.stores} months={this.state.months} isDownLoading={this.state.isDownLoading} onDownloadClick={this.onDownloadClick} />} />
                        <Route exact path="/OrgChart/GroupSummary" render={(routeProps) => <OrgChartGroupSummaryComponent {...this.props} stores={this.state.stores} months={this.state.months} isDownLoading={this.state.isDownLoading} onDownloadClick={this.onDownloadClick} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
