import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { UsedReconditioningEditInventoryMobileComponent } from "./UsedReconditioningEditInventoryMobileComponent";
import { DGDGCarouselComponent } from "../common/dgdgcarousel/DGDGCarouselComponent"

export class UsedReconditioningInventoryMobileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
            showDialog: false,
        }
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onServiceOutCarousalCallback = this.onServiceOutCarousalCallback.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)}>
                <i className="far fa-ellipsis-h" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({ selectedRow: selectedRow, showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }
    onServiceOutCarousalCallback(rowData) {
        let text = "Pending";
        let status = "pending";
        let statusOut = "";
        if (rowData.service_out_date_time) {
            text = "Completed";
            status = "completed";
            if (rowData.body_out_date_time || rowData.detail_in_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusOut={statusOut} />;
    }

    render() {
        return <div>
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Retail/Wholesale"}
                bodyText={"Do you want to change vehicle " + (this.props.wholesaleRow !== null ? this.props.wholesaleRow.stock_number : "") + " to from " + (this.props.wholesaleRow !== null ? (this.props.wholesaleRow.is_wholesale ? "Retail" : "Wholesale") : "") + " to " + (this.props.wholesaleRow !== null ? (this.props.wholesaleRow.is_wholesale ? "Wholesale" : "Retail") : "") + "?"}
                showConfirmation={this.props.showWholesaleConfirmation}
                onOkClick={this.props.onOkWholesaleChange}
                onCancelClick={this.props.onCancelWholesaleClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"OK to Publish/Do Not Publish"}
                bodyText={((this.props.publishRow !== null && !this.props.publishRow.ok_publish) ? "OK to publish vehicle " : "Do not publish vehicle ") + (this.props.publishRow !== null ? this.props.publishRow.stock_number : "") + "?"}
                showConfirmation={this.props.showPublishConfirmation}
                cssName={"dgdg-used-reconditioning-ok-to-publish-dialog"}
                onOkClick={this.props.onOkPublishChange}
                onCancelClick={this.props.onCancelPublishClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change CPO/Not CPO"}
                bodyText={"Do you want to change vehicle " + (this.props.cpoRow !== null ? this.props.cpoRow.stock_number : "") + " from " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "Not CPO" : "CPO") : "") + " to " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "CPO" : "Not CPO") : "") + "?"}
                showConfirmation={this.props.showCPOConfirmation}
                onOkClick={this.props.onOkCPOChange}
                onCancelClick={this.props.onCancelCPOClick}
            />

            <UsedReconditioningEditInventoryMobileComponent
                showDialog={this.state.showDialog}
                rowData={this.state.selectedRow}
                onServiceOutCarousalCallback={this.props.onServiceOutCarousalCallback}
                onBodyOutCarousalCallback={this.props.onBodyOutCarousalCallback}
                onDetailOutCarousalCallback={this.props.onDetailOutCarousalCallback}
                onReconOutCarousalCallback={this.props.onReconOutCarousalCallback}
                onPhotoOutCarousalCallback={this.props.onPhotoOutCarousalCallback}
                onServiceOutColumnCallback={this.props.onServiceOutColumnCallback}
                onBodyOutColumnCallback={this.props.onBodyOutColumnCallback}
                onDetailoutColumnCallback={this.props.onDetailOutColumnCallback}
                onReconOutColimnCallback={this.props.onReconOutColumnCallback}
                onPhotoOutColumnCallback={this.props.onPhotoOutColumnCallback}
                onAddCommentColumnCallback={this.props.onAddCommentColumnCallback}
                onPublishSelectChange={this.props.onPublishSelectChange}
                onWholesaleSelectChange={this.props.onWholesaleSelectChange}
                onOkServiceOut={this.props.onOkServiceOut}
                onCancelDateChange={this.props.onCancelDateChange}
                serviceOutData={this.props.serviceOutData}
                publishRow={this.props.publishRow}
                showPublishConfirmation={this.props.showPublishConfirmation}
                onOkPublishChange={this.props.onOkPublishChange}
                onCancelPublishClick={this.props.onCancelPublishClick}
                onCancelClick={this.onCancelClick}
                onCancelWholesaleClick={this.props.onCancelWholesaleClick}
                onOkWholesaleChange={this.props.onOkWholesaleChange}
                showWholesaleConfirmation={this.props.showWholesaleConfirmation}
                wholesaleRow={this.props.wholesaleRow}
                onCPOSelectChange={this.props.onCPOSelectChange}
                onOkCPOChange={this.props.onOkCPOChange}
                onCancelCPOClick={this.props.onCancelCPOClick}
                cpoRow={this.props.cpoRow}
                showCPOConfirmation={this.props.showCPOConfirmation}
                detailOutData={this.props.detailOutData}
                onOkDetailOut={this.props.onOkDetailOut}
                reconOutData={this.props.reconOutData}
                onOkReconOut={this.props.onOkReconOut}
                photoOutData={this.props.photoOutData}
                onOkPhotoOut={this.props.onOkPhotoOut}
                onUndoOkClick={this.props.onUndoOkClick}
                onCancelUndoClick={this.props.onCancelUndoClick}
                activeStores={this.props.activeStores}
                roNumberColumnCallback={this.props.roNumberColumnCallback}
                onServiceOutDateChange={this.props.onServiceOutDateChange}
                onUndoInventoryClick={this.props.onUndoInventoryClick}
                onSmogHoldChange={this.props.onSmogHoldChange}
                onVinHoldChange={this.props.onVinHoldChange}
                onWeightHoldChange={this.props.onWeightHoldChange}
                onServiceHoldChange={this.props.onServiceHoldChange}
                onServiceOutChange={this.props.onServiceOutChange}
                onBodyOutChange={this.props.onBodyOutChange}
                onBodyOutClick={this.props.onBodyOutClick}
                onDetailOutChange={this.props.onDetailOutChange}
                onDetailOutClick={this.props.onDetailOutClick}
                onPaintHoldChange={this.props.onPaintHoldChange}
                onReconOutChange={this.props.onReconOutChange}
                onReconInteriorOnlyChange={this.props.onReconInteriorOnlyChange}
                onReconOutClick={this.props.onReconOutClick}
                onPhotoOutChange={this.props.onPhotoOutChange}
                onPhotoOutClick={this.props.onPhotoOutClick}
                selectedStore={this.props.selectedStore}
                serviceHoldList={this.props.serviceHoldList}
                undoDetail={this.props.undoDetail}
                onToggleViewComments={this.props.onToggleViewComments}
                onToggleAddComments={this.props.onToggleAddComments}
                onCommentTextChange={this.props.onCommentTextChange}
                onSaveCommentClick={this.props.onSaveCommentClick}
                actionName={this.props.actionName}
            />
            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.props.tableTitle} tableData={this.props.tableData} paintTable={this.props.paintTable} legendText={this.props.legendText} cssName="dgdg-used-reconditioning-table dgdg-used-reconditioning-edit" tablePageSize={CommonService.tablePageSize} stickyHeader
                copyTableConfig={{ "columns": (this.props.selectedStore && this.props.selectedStore.store_number !== 11) ? [0, 1, 2, 3, 4, 5, 14, 15, 16, 8, 17, 9, 18, 10, 19, 11, 20, 12, 21] : [0, 1, 2, 3, 4, 5, 6, 14, 15, 16, 8, 17, 9, 18, 10, 19, 11, 20, 12, 21], onCopyTableCallBack: this.props.onCopyTableCallBack }}
            >
                <DGDGTableRowComponent hasHeader>
                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                    <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                    <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                    {/* CPO Link --- <DGDGTableColumnComponent headerText="Vin" dataColumnCallback={this.vinColumnCallback} dataColumn="vin" sortColumn="vin" filterColumn="vin" /> */}
                    <DGDGTableColumnComponent headerText="RO Number" dataColumn="recon_ro_number" sortColumn="recon_ro_number" filterColumn="recon_ro_number" dataColumnCallback={this.props.roNumberColumnCallback} />
                    <DGDGTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" />
                    <DGDGTableColumnComponent headerText="Estimated Date of Completion" dataColumnCallback={this.props.onEstCompletionDateColumnCallback} dataColumn="est_completion_date" filterColumn="est_completion_date" sortColumn="est_completion_date" showSpinner />
                    <DGDGTableColumnComponent headerText="Photo Status" dataColumn="photo_status" sortColumn="photo_status" filterColumn="photo_status" cssName="align-top text-center" rowSpan="2" firstSortOrder="Desc" />
                    <DGDGTableColumnComponent dataColumnCallback={this.onActionColumnCallback} cssName="text-center" />
                    {/*<DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_name_web" filterColumn="store_name_web" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Store #" dataColumn="store_number" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Service Out By" dataColumn="service_out_by" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Body Out By" dataColumn="body_out_by" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Detail Out By" dataColumn="detail_out_by" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Recon Out By" dataColumn="recon_out_by" isHidden />*/}
                    {/*<DGDGTableColumnComponent headerText="Photo Out By" dataColumn="photo_out_by" isHidden />*/}
                </DGDGTableRowComponent>
            </DGDGTableV3Component>
        </div>;
    }
}
