import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGRODetailComponent } from "../common/dgdgrodetail/DGDGRODetailComponent";

export class ServiceAdvisorReportCPCommissionableGrossComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ServiceAdvisorReportCPCommissionableGrossComponent - getDerivedStateFromProps");

            if (props.tableData.length === 0) {
                newState.showDialog = false;
            } else {
                newState.showDialog = true;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        };

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ServiceAdvisorReportCPCommissionableGrossComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.state.showDialog} title={this.props.tableTitle}
            cssName="dgdg-advisor-detail-menu-not-counted-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <DGDGRODetailComponent title={this.props.roDetailTitle} data={this.props.roDetailResponse} onCancelClick={this.props.onRODetailCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights} />
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} headerText={this.props.headerText} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" onCellClick={this.props.onRODetailCellClick} filterColumn="ro_number" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_gross" sortColumn="total_gross" dataType="money" footerFunction="sum" footerCssName="text-right" />
                        <DGDGTableColumnComponent headerText="Labor Discount Amount" dataColumn="labor_discount_amount" sortColumn="labor_discount_amount" dataType="money" footerFunction="sum" footerCssName="text-right" />
                        <DGDGTableColumnComponent headerText="CP Commissionable Gross" dataColumn="cp_commissionable_gross" sortColumn="cp_commissionable_gross" dataType="money" footerFunction="sum" footerCssName="text-right" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}
