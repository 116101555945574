import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { SalesStoreReportMonthComponent } from "./SalesStoreReportMonthComponent"
import { SalesStoreReportDayComponent } from "./SalesStoreReportDayComponent"
import { SalesStoreReportWeekendComponent } from "./SalesStoreReportWeekendComponent"
import { SalesStoreReportYTDComponent } from "./SalesStoreReportYTDComponent"
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class SalesStoreReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            years: CommonService.getYears("01/01/2019"),
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesStoreReportRouteComponent - componentDidMount");
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("SalesStoreReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Store Report";

    getControllerPolicy() {
        try {
            fetch("/SalesStoreReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesStoreReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/SalesStoreReport/Month" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Month") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Month</Link>
                    <Link to="/SalesStoreReport/Day" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Day") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Day</Link>
                    {/*<Link to="/SalesStoreReport/Night" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Night") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Night</Link>*/}
                    <Link to="/SalesStoreReport/Weekend" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Weekend") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Weekend</Link>
                    <Link to="/SalesStoreReport/YTD" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/YTD") !== -1 ? " btn btn-dark active" : " btn btn-link")}>YTD</Link>
                    <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}</span>
                    </div>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-sales-store-report-content">
                    <Switch>
                        <Route exact path="/SalesStoreReport/Month" render={(props) => <SalesStoreReportMonthComponent {...this.props} {...props} months={this.state.months} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/SalesStoreReport/Day" render={(props) => <SalesStoreReportDayComponent {...this.props} {...props} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        {/*<Route exact path="/SalesStoreReport/Night" render={(props) => <SalesStoreReportNightComponent {...this.props} {...props} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />*/}
                        <Route exact path="/SalesStoreReport/Weekend" render={(props) => <SalesStoreReportWeekendComponent {...this.props} {...props} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/SalesStoreReport/YTD" render={(props) => <SalesStoreReportYTDComponent {...this.props} {...props} years={this.state.years} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
