import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class DealIssuesQueryComponent extends React.Component {
    static StoreNumberCookie = "DealIssuesStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DealIssuesQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            showHidden: false,
            showSpinner: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onShowHiddenChange = this.onShowHiddenChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(DealIssuesQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                this.setState({
                    selectedStore: selectedStore
                }, () => {
                    this.onStoreClick(null, selectedStore);
                    this.onGetReportClick(selectedStore);
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.props.onStoreClick(event, store);
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowHiddenChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
            this.props.onShowHiddenChange(isChecked);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(DealIssuesQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormCheckboxComponent id="showHidden" label="Show Hidden" value={this.state.showHidden} onChange={this.onShowHiddenChange} labelCssName="dgdg-deal-issues-show-hidden" />
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onAddDealIssuesClick(event)}>
                        Add Deal Issues <span className="fas fa-plus dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
