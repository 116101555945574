import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { SalesEmployeeTimeTrackQueryComponent } from "./SalesEmployeeTimeTrackQueryComponent";

export class SalesEmployeeTimeTrackRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingFirst: true,
            isLoadingData: false,
            isDownLoading: false,
            stores: null,
            dateList: null,
            dataColumnList: null,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            detailTableData: [],
            detailTableTitle: "",
            detailPaintTable: false,
            navigateWeek: null,
            columnsToBeCopied: [],
            lastUpdatedDate: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onGetLastUpdatedDate = this.onGetLastUpdatedDate.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onEmployeeDetailCellClick = this.onEmployeeDetailCellClick.bind(this);
        this.onEmployeeDetailCancelClick = this.onEmployeeDetailCancelClick.bind(this);
        this.onWeekClick = this.onWeekClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesEmployeeTimeTrackRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesEmployeeTimeTrack", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("SalesEmployeeTimeTrackRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Employee Time Track";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesEmployeeTimeTrack/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesEmployeeTimeTrackRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/SalesEmployeeTimeTrack/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getEmployeeTimeTrackDetail(employee, detailDate) {
        this.setState({ error: null });
        let inputData = {
            detailDate: CommonService.formatServerDateTime(detailDate),
            employeeNumber: employee.employee_number
        };
        this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/getEmployeeTimeTrackDetail", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/SalesEmployeeTimeTrack/GetEmployeeTimeTrackDetail", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    detailTableData: CommonService.addOrdinalColumn(parsedResponse),
                    detailTableTitle: "Employee Time Track - " + employee.employee_name,
                    detailPaintTable: true
                }, () => {
                    this.setState({ detailPaintTable: false });
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetEmployeeTimeTrackDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore, startDate) {
        try {
            if (startDate !== null && selectedStore !== null) {
                this.setState({ isLoadingData: true });
                this.onGetLastUpdatedDate(startDate);
                let inputData = {
                    startDate: CommonService.formatServerDateTime(startDate.toString()),
                    storeNumber: selectedStore.store_number
                };

                let dateList = [];
                dateList.push(<td key="Ordinal" colSpan="2" className="dgdg-border-bottom" />);
                let prevWeek = moment(startDate);
                prevWeek.set(prevWeek.subtract(7, "days"));

                let nextWeek = moment(startDate);
                nextWeek.set(nextWeek.add(7, 'days'));

                let columnDate = null;
                for (let index = 0; index <= 6; index++) {
                    let dateIndex = moment(startDate);
                    dateIndex.set(dateIndex.add(index, 'days'));

                    columnDate = CommonService.formatDate(dateIndex);
                    dateList.push(
                        <td colSpan="4" className="text-center dgdg-border-bottom" key={columnDate}>
                            {
                                index === 0
                                    ? <button type="button" className="btn btn-link dgdg-font-blue dgdg-font-size-18" onClick={(event) => this.onWeekClick(event, prevWeek)} style={{ "paddingRight": "15px" }}>
                                        <span className="fas fa-chevron-circle-left" />
                                    </button>
                                    : null
                            }
                            {CommonService.formatDay(moment(columnDate)) + " " + columnDate}
                            {
                                index === 6 && nextWeek.isSameOrBefore(moment())
                                    ? <button type="button" className="btn btn-link dgdg-font-blue dgdg-font-size-18 dgdg-icon" onClick={(event) => this.onWeekClick(event, nextWeek)}>
                                        <span className="fas fa-chevron-circle-right" />
                                    </button>
                                    : null
                            }
                        </td>
                    );
                }
                this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/SalesEmployeeTimeTrack/GetReport", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let employeeTimeTrackHT = {};
                        parsedResponse.forEach((item) => {
                            let employeeTimeTrack = employeeTimeTrackHT[item.store_number + "~" + item.employee_number];
                            if (!employeeTimeTrack) {
                                employeeTimeTrack = {
                                    store_name: item.store_name,
                                    employee_number: item.employee_number,
                                    employee_name: item.employee_name
                                };

                                for (let index = 0; index <= 6; index++) {
                                    let dateIndex = moment(startDate);
                                    dateIndex.set(dateIndex.add(index, 'days'));

                                    let columnDate = CommonService.formatDate(dateIndex);
                                    employeeTimeTrack[columnDate + "~Reg Daily"] = null;
                                    employeeTimeTrack[columnDate + "~OT Daily"] = null;
                                    employeeTimeTrack[columnDate + "~OT WTD"] = null;
                                    employeeTimeTrack[columnDate + "~Break"] = null;
                                    employeeTimeTrack[columnDate + "~break_gradient_pct_color"] = index % 2 ? "#99D9EA" : "#FFFFFF";
                                }

                                employeeTimeTrackHT[item.store_number + "~" + item.employee_number] = employeeTimeTrack;
                            }

                            let itemDate = CommonService.formatDate(moment(item.date));
                            employeeTimeTrack[itemDate + "~" + item.name] = item.value;
                            if (item.name === "Break" && item.break_gradient_pct_color) {
                                employeeTimeTrack[itemDate + "~break_gradient_pct_color"] = item.break_gradient_pct_color;
                            }
                        });

                        let employeeTimeTrackList = Object.keys(employeeTimeTrackHT).map((item) => { return employeeTimeTrackHT[item]; });
                        let dataColumnList = [];
                        let columnDate = null;

                        dataColumnList.push(<DGDGTableColumnComponent key="Ordinal" headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />);
                        dataColumnList.push(<DGDGTableColumnComponent key="employee_name" headerText="Employee Name" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" />);
                        for (let index = 0; index <= 6; index++) {
                            let dateIndex = moment(startDate);
                            dateIndex.set(dateIndex.add(index, 'days'));

                            columnDate = CommonService.formatDate(dateIndex);
                            dataColumnList.push(<DGDGTableColumnComponent key={columnDate + "Reg Daily"} cssName={index % 2 ? "dgdg-column-band-1" : null} headerText="Reg Day (Hrs)" dataColumn={columnDate + "~Reg Daily"} sortColumn={columnDate + "~Reg Daily"} dataType="number_2" onCellClick={this.onEmployeeDetailCellClick} cellData={columnDate} />);
                            dataColumnList.push(<DGDGTableColumnComponent key={columnDate + "OT Daily"} cssName={index % 2 ? "dgdg-column-band-1" : null} headerText="OT Day (Hrs)" dataColumn={columnDate + "~OT Daily"} sortColumn={columnDate + "~OT Daily"} dataType="number_2" onCellClick={this.onEmployeeDetailCellClick} cellData={columnDate} />);
                            dataColumnList.push(<DGDGTableColumnComponent key={columnDate + "OT WTD"} cssName={index % 2 ? "dgdg-column-band-1" : null} headerText="OT WTD (Hrs)" dataColumn={columnDate + "~OT WTD"} sortColumn={columnDate + "~OT WTD"} dataType="number_2" />);
                            dataColumnList.push(<DGDGTableColumnComponent key={columnDate + "Break"} headerText="Meal Break (Mins)" dataColumn={columnDate + "~Break"} sortColumn={columnDate + "~Break"} dataType="number_2" onCellClick={this.onEmployeeDetailCellClick} cellData={columnDate} backgroundColor={columnDate + "~break_gradient_pct_color"} />);
                        }
                        if (!this.state.isLoadingFirst) {
                            this.props.onBreadCrumbStateChanged({
                                pageTitle: this.pageTitle,
                                override: false,
                                link: {
                                    action: "remove"
                                }
                            });
                        }

                        this.props.onBreadCrumbStateChanged({
                            pageTitle: this.pageTitle,
                            override: false,
                            link: {
                                name: selectedStore.display_name
                                    + " - " + CommonService.formatDate(moment(startDate))
                                    + " - " + columnDate,
                                action: "add"
                            }
                        });
                        let columnsToBeCopied = []
                        for (let index = 0; index < dataColumnList.length; index++) {
                            columnsToBeCopied.push(index);
                        }
                        this.setState({
                            isLoadingFirst: false,
                            dateList: dateList,
                            dataColumnList: dataColumnList,
                            columnsToBeCopied: columnsToBeCopied,
                            tableData: CommonService.addOrdinalColumn(employeeTimeTrackList),
                            tableTitle: selectedStore.display_name
                                + " - " + CommonService.formatDate(moment(startDate))
                                + " - " + columnDate,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            dateList: dateList,
                            dataColumnList: null,
                            columnsToBeCopied: [],
                            tableData: [],
                            tableTitle: selectedStore.display_name
                                + " - " + CommonService.formatDate(moment(startDate))
                                + " - " + columnDate,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoadingData: false });
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate(startDate) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString())
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesEmployeeTimeTrack/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse)
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(selectedStore, startDate) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                startDate: CommonService.formatServerDateTime(startDate.toString())
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesEmployeeTimeTrackRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesEmployeeTimeTrack/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesEmployeeTimeTrack/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeDetailCellClick(event, rowData, cellData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.getEmployeeTimeTrackDetail(rowData, cellData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeDetailCancelClick(event) {
        try {
            this.setState({
                detailTableData: [],
                detailTableTitle: "",
                detailPaintTable: true
            }, () => {
                this.setState({ detailPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWeekClick(event, weekDate) {
        try {
            this.setState({ navigateWeek: weekDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("SalesEmployeeTimeTrackRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.detailTableData.length > 0}
                cssName="dgdg-employee-time-track-dialog"
                title={this.state.detailTableTitle}
                onCancelClick={this.onEmployeeDetailCancelClick}
            >
                <div className="dgdg-report-body">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.detailTableData} paintTable={this.state.detailPaintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Date" dataType="date" dataColumn="date" sortColumn="date" filterColumn="date" />
                            <DGDGTableColumnComponent headerText="Pay Type" dataColumn="pay_type" sortColumn="pay_type" filterColumn="pay_type" />
                            <DGDGTableColumnComponent headerText="Pay Description" dataColumn="pay_description" sortColumn="pay_description" filterColumn="pay_description" />
                            <DGDGTableColumnComponent headerText="Rounded Time In" dataColumn="rounded_time_in" sortColumn="rounded_time_in" />
                            <DGDGTableColumnComponent headerText="Rounded Time Out" dataColumn="rounded_time_out" sortColumn="rounded_time_out" />
                            <DGDGTableColumnComponent headerText="Hours" dataType="number_2" dataColumn="hours" sortColumn="hours" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </DGDGModalDialogComponent>
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content dgdg-employee-time-track-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <SalesEmployeeTimeTrackQueryComponent
                        {...this.props}
                        stores={this.state.stores} navigateWeek={this.state.navigateWeek}
                        isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading}
                        onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                        onShowAlert={this.props.onShowAlert}
                    />
                    <div className="dgdg-site-scrollable-content dgdg-employee-time-track-table">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": this.state.columnsToBeCopied, onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableHeaderComponent position="PreHeader">
                                <tr className="dgdg-employee-time-track-bg-head font-weight-bold text-center">
                                    {this.state.dateList}
                                </tr>
                            </DGDGTableHeaderComponent>
                            <DGDGTableRowComponent>
                                {this.state.dataColumnList}
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

