import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";

import { FIPayrollEmployeeQueryComponent } from "./FIPayrollEmployeeQueryComponent";
import { FIPayrollEmployeeAddComponent } from "./FIPayrollEmployeeAddComponent";


export class FIPayrollEmployeeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            employeeList: null,
            paintTable: false,
            tableData: [],
            selectedRow: null,
            deleteRow: null,
            showDialog: false,
            isLoadingData: false
        }

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionDeleteClick = this.onActionDeleteClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDeleteFIPayrollEmployeeOkClick = this.onDeleteFIPayrollEmployeeOkClick.bind(this);
        this.onDeleteFIPayrollEmployeeCancelClick = this.onDeleteFIPayrollEmployeeCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FIPayrollEmployeeComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIPayroll/Employee", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getEmployeeList();
            this.getEmployees();
            CommonService.clientAzureStorageLog("FIPayrollEmployeeComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Payroll";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "FIPayrollEmployeeComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FIPayroll/GetActiveStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getEmployeeList() {
        this.props.applicationInsights.trackTrace({ message: "FIPayrollEmployeeComponent/getEmployeeList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FIPayroll/GetFIPayrollEmployeeList")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse.forEach((responseItem, index) => {
                    responseItem.employee = responseItem.first + ", " + responseItem.last + ", " + responseItem.number + ", " + responseItem.store_web_name + (responseItem.job_title ? ", " + responseItem.job_title : "");
                });
                this.setState({ employeeList: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getEmployees() {
        this.setState({ isLoadingData: true });
        this.props.applicationInsights.trackTrace({ message: "FIPayrollEmployeeComponent/getEmployees", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        try {
            fetch("/FIPayroll/GetFIPayrollEmployees", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.employee = responseItem.first + " " + responseItem.last + " (" + responseItem.number + ")";
                    });
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        managerPlanTableData: [],
                        directorPlanTableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetFIPayrollEmployees", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)}>
                <i className="fas fa-edit" />
            </button>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionDeleteClick(event, selectedRow)}>
                <i className="fas fa-times text-danger dgdg-icon" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({
                selectedRow: selectedRow,
                showDialog: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionDeleteClick(event, selectedRow) {
        try {
            this.setState({
                deleteRow: selectedRow
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, id, employeeNumber, storeNumber, positionCode) {
        try {
            let inputData = {
                id: id,
                employeeNumber: parseInt(employeeNumber),
                storeNumber: parseInt(storeNumber),
                positionCode: positionCode
            };
            this.props.applicationInsights.trackTrace({ message: "FIPayrollEmployeeComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/SaveFIPayrollEmployee", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.getEmployees();
                    this.props.onShowAlert("success", { message: "Employee saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/SaveFIPayrollEmployee", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteFIPayrollEmployeeOkClick(event) {
        try {
            let inputData = {
                id: this.state.deleteRow.id,
                employeeNumber: this.state.deleteRow.number,
                storeNumber: this.state.deleteRow.override_store_number,
                positionCode: this.state.deleteRow.override_position_code
            };

            this.props.applicationInsights.trackTrace({ message: "FIPayrollEmployeeComponent/onDeleteFIPayrollEmployeeOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/DeleteFIPayrollEmployee", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.getEmployees();
                    this.onDeleteFIPayrollEmployeeCancelClick(event);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/DeleteFIPayrollEmployee", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteFIPayrollEmployeeCancelClick(event) {
        try {
            this.setState({
                deleteRow: null,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("FIPayrollEmployeeComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FIPayrollEmployeeAddComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showDialog}
                rowData={this.state.selectedRow}
                title="Custom F&I Payroll Employee"
                stores={this.state.stores}
                employeeList={this.state.employeeList}
                employees={this.state.tableData}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Delete Custom FI Payroll Employee"
                bodyText={"Confirm delete of Employee " + this.state.deleteRow?.first + " " + this.state.deleteRow?.last + "?"}
                showConfirmation={this.state.deleteRow !== null}
                onOkClick={this.onDeleteFIPayrollEmployeeOkClick}
                onCancelClick={this.onDeleteFIPayrollEmployeeCancelClick}
            />
            <FIPayrollEmployeeQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onAddClick={this.onAddClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} headerText="Custom F&I Payroll Employees" defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.state.paintTable}
                    copyTableConfig={{ "columns": [0, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-nowrap dgdg-fi-payroll-employee-action-column" />
                        <DGDGTableColumnComponent headerText="Employee" dataColumn="employee" sortColumn="employee" filterColumn="employee" />
                        <DGDGTableColumnComponent headerText="Override Store" dataColumn="override_store_web_name" sortColumn="override_store_web_name" filterColumn="override_store_web_name" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Override Position Code" dataColumn="override_position_code" sortColumn="override_position_code" filterColumn="override_position_code" />
                        <DGDGTableColumnComponent headerText="Job Title" dataColumn="job_title" sortColumn="job_title" filterColumn="job_title" cssName="text-nowrap" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
