import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { OnboardingQueryComponent } from "./OnboardingQueryComponent";
import { OnboardingAddComponent } from "./OnboardingAddComponent";

export class OnboardingRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      paintTable: false,
      isLoadingData: false,
      showDialog: false,
      lastUpdatedDate: null
    }

    this.onLookupClick = this.onLookupClick.bind(this);
    this.onAddUserClick = this.onAddUserClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
    this.onPasswordColumnCallback = this.onPasswordColumnCallback.bind(this);
    this.onCopyColumnClick = this.onCopyColumnClick.bind(this);

    props.onBreadCrumbStateChanged({
      pageTitle: this.pageTitle,
      override: true,
      link: {
        name: this.pageTitle,
        action: "add"
      }
    });

    console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
    console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
  }

  componentDidMount() {
    try {
      console.devLog("OnboardingRouteComponent - componentDidMount");
      this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Onboarding", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      this.getControllerPolicy();
      this.onLookupClick();
      this.getLastUpdatedDate();
      CommonService.clientAzureStorageLog("OnboardingRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  pageTitle = "IT Onboarding";

  getControllerPolicy() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/OnboardingRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/Onboarding/GetControllerPolicy")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          console.devLog("ACL: " + parsedResponse.acl.join(), "OnboardingRouteComponent", "getControllerPolicy");
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Onboarding/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  getLastUpdatedDate() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/OnboardingRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/Onboarding/GetLastUpdated")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          let lastUpdatedDate = "";
          if (CommonService.isDateValid(parsedResponse))
            lastUpdatedDate = moment.utc(parsedResponse);
          this.setState({ lastUpdatedDate: lastUpdatedDate });
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Onboarding/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onLookupClick(id, value) {
    try {
      let inputData = {
        upn: value,
      };
      this.setState({ isLoadingData: true });
      this.props.applicationInsights.trackTrace({ message: "/OnboardingRouteComponent/onLookupClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/Onboarding/GetUsers", {
        method: "POST",
        headers: {
          'Content-Type': "application/json; charset=utf-8;"
        },
        body: JSON.stringify(inputData)
      })
        .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
        .then(parsedResponse => {
          parsedResponse.forEach((responseItem, index) => {
            let mask = "*";
            responseItem.maskedPassword = mask.repeat(responseItem.password?.length);
          })
          this.setState({
            tableData: CommonService.addOrdinalColumn(parsedResponse),
            paintTable: true,
            isLoadingData: false
          }, () => {
            this.setState({ paintTable: false });
          });
        })
        .catch(notOKResponse => {
          this.setState({
            isLoadingData: false,
            tableData: [],
            paintTable: true
          }, () => {
            this.setState({ paintTable: false });
          });
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Onboarding/GetUsers", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onAddUserClick(event) {
    try {
      this.setState({ showDialog: true });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onCancelClick(event) {
    try {
      this.setState({
        showDialog: false
      });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onSaveClick(event, user) {
    try {
      let inputData = user;
      this.props.applicationInsights.trackTrace({ message: "/OnboardingRouteComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/Onboarding/SaveUser", {
        method: "POST",
        headers: {
          'Content-Type': "application/json; charset=utf-8;"
        },
        body: JSON.stringify(inputData)
      })
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          this.onLookupClick();
          this.props.onShowAlert("success", { message: "User saved successfully." }, 4000);
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OnboardingRouteComponent/onSaveClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }
  onPasswordColumnCallback(rowData) {
    return <div className="form-inline" >
      <span title={rowData.maskedPassword}>{rowData.maskedPassword}</span>
      {
        rowData.password
          ? <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.password)}>
            <span className="far fa-copy" />
          </button>
          : null
      }
    </div>;
  }

  onCopyColumnClick(event, columnValue) {
    try {
      navigator.clipboard.writeText(columnValue);
      this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
      CommonService.clientAzureStorageLog("OnboardingRouteComponent", "onCopyColumnClick", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onCopyTableCallBack(event) {
    try {
      this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
      CommonService.clientAzureStorageLog("OnboardingRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  render() {
    return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
      <div>
        <span className="float-right badge badge-primary dgdg-badge">
          Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
        </span>
      </div>
      <div className="dgdg-flex-item dgdg-site-content">
        <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
          <OnboardingAddComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.state.showDialog}
            title={this.state.tableTitle}
            onSaveClick={this.onSaveClick}
            onCancelClick={this.onCancelClick}
            onShowAlert={this.props.onShowAlert}
          />

          <OnboardingQueryComponent
            applicationInsights={this.props.applicationInsights}
            isLoadingData={this.state.isLoadingData}
            onLookupClick={this.onLookupClick}
            onAddUserClick={this.onAddUserClick}
          />
          <div className="dgdg-site-scrollable-content">
            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Users" tableData={this.state.tableData} paintTable={this.state.paintTable}
              tablePageSize={CommonService.tablePageSize} stickyHeader
              copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
            >
              <DGDGTableRowComponent>
                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-directory-bg-white" />
                <DGDGTableColumnComponent headerText="User Principal Name" dataColumn="upn" sortColumn="upn" filterColumn="upn" />
                <DGDGTableColumnComponent headerText="Display Name" dataColumn="displayName" sortColumn="displayName" filterColumn="displayName" />
                <DGDGTableColumnComponent headerText="Password" dataColumnCallback={this.onPasswordColumnCallback} dataColumn="maskedPassword" sortColumn="maskedPassword" filterColumn="maskedPassword" />
                <DGDGTableColumnComponent headerText="Proxy Addresses" dataColumn="proxyAddresses" sortColumn="proxyAddresses" filterColumn="proxyAddresses" />
              </DGDGTableRowComponent>
            </DGDGTableV3Component>
          </div>
        </div>
      </div>
    </DGDGPageComponent >;
  }
}
