import React, { Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { ROQueryComponent } from "./ROQueryComponent";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class ROComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            paintTable: false,
            tableData: [],
            selectedStore: null,
            roNumber: null,
            roDate: null,
            componentState: null,
            legendText: null,
            vin: null
        }

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.roNumberColumnCallback = this.roNumberColumnCallback.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "RO Report";

    componentDidMount() {
        try {
            console.devLog("ROComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/RO", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLegendText();
            CommonService.clientAzureStorageLog("ROComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        try {
            console.devLog("ROComponent - componentWillUnmount");
            this.props.onROComponentUnmount({ selectedStore: this.state.selectedStore, roNumber: this.state.roNumber, roDate: this.state.roDate });
            CommonService.clientAzureStorageLog("ROComponent", "componentWillUnmount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-ro-report-bg-orange" />&nbsp;&nbsp;&nbsp; Open RO</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-ro-report-bg-white" />&nbsp;&nbsp;&nbsp;Closed RO</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetReportClick(selectedStore, roNumber, roDate, vin) {
        try {
            if (roNumber || roDate || vin) {
                this.setState({
                    isLoadingData: true,
                    selectedStore: selectedStore,
                    roNumber: roNumber,
                    roDate: roDate,
                    vin: vin
                });

                let inputData = {
                    storeNumber: vin === "" && selectedStore ? selectedStore.store_number : null,
                    roNumber: vin === "" && roNumber !== "" ? roNumber : null,
                    roDate: CommonService.isDateValid(roDate) ? CommonService.formatServerDateTime(roDate) : "",
                    vin: vin !== "" ? vin : null
                };

                this.props.applicationInsights.trackTrace({ message: "/ROComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/RO/GetReport", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = parsedResponse.table;
                        tableData.forEach((rowData) => {
                            if (rowData.status === "Open")
                                rowData.rowCssName = "dgdg-ro-report-bg-orange";
                        });

                        let tableTitle = "";
                        if (vin) {
                            tableTitle = vin + " - " + CommonService.formatDate(moment(roDate));
                        }
                        else {
                            tableTitle = selectedStore.display_name + " - "
                                + (roNumber ? roNumber : roDate ? CommonService.formatDate(moment(roDate)) : "");
                        }

                        this.setState({
                            isLoadingData: false,
                            tableTitle: tableTitle,
                            tableData: tableData,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "",
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/RO/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    roNumberColumnCallback(rowData) {
        if (rowData.status === "Open") {
            return <Link to={"/RO/OpenRO/Detail/" + rowData.store_number + "/" + rowData.ro_number + "/" + rowData.vehicle_id}> {rowData.ro_number}</Link>;
        }
        else {
            return <Link to={"/RO/ClosedRO/Detail/" + rowData.store_number + "/" + rowData.ro_number + "/" + rowData.vehicle_id}> {rowData.ro_number}</Link>;
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 5000);
            CommonService.clientAzureStorageLog("ROComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-responsive-page dgdg-report-body dgdg-flex dgdg-flex-column">
                <ROQueryComponent
                    {...this.props} isLoadingData={this.state.isLoadingData} componentState={this.props.closedROcomponentState}
                    onGetReportClick={this.onGetReportClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText} tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_web_name" filterColumn="store_web_name" />
                            <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" filterColumn="ro_number" dataColumnCallback={this.roNumberColumnCallback} />
                            <DGDGTableColumnComponent headerText="Open Date" dataColumn="open_date" sortColumn="open_date" filterColumn="open_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Close Date" dataColumn="close_date" sortColumn="close_date" filterColumn="close_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Open Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                            <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" sortColumn="vehicle_id" filterColumn="vehicle_id" />
                            <DGDGTableColumnComponent headerText="Service Advisor Name" dataColumn="service_advisor_name" sortColumn="service_advisor_name" filterColumn="service_advisor_name" />
                            <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                            <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Days In Shop" dataColumn="days_in_shop" sortColumn="days_in_shop" filterColumn="days_in_shop" dataType="number" />
                            <DGDGTableColumnComponent headerText="RO Status Code" dataColumn="ro_status_code" sortColumn="ro_status_code" filterColumn="ro_status_code" />
                            <DGDGTableColumnComponent headerText="RO Status Description" dataColumn="ro_status_desc" sortColumn="ro_status_desc" filterColumn="ro_status_desc" />
                            <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_gross" sortColumn="total_gross" filterColumn="total_gross" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Total Sale" dataColumn="total_sale" sortColumn="total_sale" filterColumn="total_sale" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Total Cost" dataColumn="total_cost" sortColumn="total_cost" filterColumn="total_cost" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Labor Gross" dataColumn="labor_gross" sortColumn="labor_gross" filterColumn="labor_gross" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" sortColumn="labor_sale" filterColumn="labor_sale" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" sortColumn="labor_cost" filterColumn="labor_cost" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Parts Gross" dataColumn="parts_gross" sortColumn="parts_gross" filterColumn="parts_gross" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Misc Gross" dataColumn="misc_gross" sortColumn="misc_gross" filterColumn="misc_gross" dataType="money_2" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
