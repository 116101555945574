import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableV2Component } from "../common/dgdgtable/DGDGTableV2Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomComponent } from "../common/dgdgtable/DGDGTableCustomComponent";

import { SalesEmployeePayEditComponent } from "./SalesEmployeePayEditComponent";
import { SalesPayrollDetailEditComponent } from "./SalesPayrollDetailEditComponent";
import { SalesPayrollDetailComponent } from "./SalesPayrollDetailComponent";
import { SalesPayrollQueryComponent } from "./SalesPayrollQueryComponent";

export class SalesPayrollRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            lastUpdatedDate: null,
            tableData: null,
            months: CommonService.getMonths(),
            stores: null,
            selectedSetupMonth: null,
            selectedSetupStore: null,
            setupAdditionalEmployeeNumber: null,
            selectedEmployee: null,
            payrollDetail: null,
            isPayrollEditEnabled: true,
            paintTable: false,
            onLoadMessage: "Select Store, Month and click Get Payroll"
        };

        this.onPayPlanEditCellClick = this.onPayPlanEditCellClick.bind(this);
        this.onPayPlanSaveClick = this.onPayPlanSaveClick.bind(this);
        this.onPayPlanCancelClick = this.onPayPlanCancelClick.bind(this);
        this.onPayrollDetailEditCellClick = this.onPayrollDetailEditCellClick.bind(this);
        this.onPayrollDetailSaveClick = this.onPayrollDetailSaveClick.bind(this);
        this.onPayrollDetailCancelClick = this.onPayrollDetailCancelClick.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesPayrollRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesPayroll", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("SalesPayrollRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Payroll";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesPayrollRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesPayroll/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesPayrollRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/SalesPayrollRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/SalesPayroll/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getLastUpdatedDate(inputData) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesPayrollRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesPayroll/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                selectedEmployee: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanSaveClick(event) {
        try {
            this.onPayPlanCancelClick(event);
            this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore, this.state.setupAdditionalEmployeeNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanCancelClick(event) {
        try {
            this.setState({ selectedEmployee: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({ payrollDetail: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailSaveClick(event) {
        try {
            this.onPayrollDetailCancelClick(event);
            this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore, this.state.setupAdditionalEmployeeNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailCancelClick(event) {
        try {
            this.setState({ payrollDetail: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth, selectedStore, additionalEmployeeNumber) {
        try {
            if (selectedMonth !== null && selectedStore !== null) {
                this.setState({
                    onLoadMessage: null,
                    isLoadingData: true,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
                let inputData = {
                    month: CommonService.formatServerDateTime(selectedMonth),
                    storeNumber: selectedStore.store_number,
                    additionalEmployeeNumber: additionalEmployeeNumber
                };

                this.getLastUpdatedDate(inputData)

                this.props.applicationInsights.trackTrace({ message: "/SalesPayrollRouteComponent/onGetPayrollClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/SalesPayroll/GetSalesPayroll", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = CommonService.addOrdinalColumn(parsedResponse);
                        tableData.forEach((rowData) => {
                            rowData.disable_edit = !rowData.can_edit;
                        });
                        this.setState({
                            isLoadingData: false,
                            selectedSetupMonth: selectedMonth,
                            selectedSetupStore: selectedStore,
                            setupAdditionalEmployeeNumber: additionalEmployeeNumber,
                            isPayrollEditEnabled: moment(selectedMonth) > moment().startOf("month").subtract(2, "month"),
                            tableData: tableData,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/GetSalesPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(selectedMonth, selectedStore, additionalEmployeeNumber) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                month: CommonService.formatServerDateTime(selectedMonth),
                additionalEmployeeNumber: additionalEmployeeNumber
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesPayrollRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesPayroll/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    CommonService.downloadBlob(response);
                    this.setState({ isDownLoading: false });
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <SalesEmployeePayEditComponent
                applicationInsights={this.props.applicationInsights}
                employee={this.state.selectedEmployee}
                onSaveClick={this.onPayPlanSaveClick}
                onCancelClick={this.onPayPlanCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <SalesPayrollDetailEditComponent
                applicationInsights={this.props.applicationInsights}
                payrollDetail={this.state.payrollDetail}
                payrollMonth={this.state.selectedSetupMonth}
                onSaveClick={this.onPayrollDetailSaveClick}
                onCancelClick={this.onPayrollDetailCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <SalesPayrollQueryComponent
                        applicationInsights={this.props.applicationInsights}
                        isLoadingData={this.state.isLoadingData}
                        stores={this.state.stores}
                        months={this.state.months}
                        onGetPayrollClick={this.onGetPayrollClick}
                        isDownLoading={this.state.isDownLoading}
                        onDownloadClick={this.onDownloadClick}
                    />
                    <div className="dgdg-site-scrollable-content">
                        <div className="card">
                            <div className="card-header">Sales Payroll Setup</div>
                            {
                                (this.state.onLoadMessage)
                                    ? <div className="card card-header dgdg-payroll-bg-no-load text-center font-weight-bold">
                                        {this.state.onLoadMessage}
                                    </div>
                                    : <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable}>
                                        <DGDGTableRowComponent>
                                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_name_web" filterColumn="store_name_web" cssName="text-nowrap" />
                                            <DGDGTableColumnComponent headerText="Employee Number" dataColumn="employee_number" sortColumn="employee_number" filterColumn="employee_number" />
                                            <DGDGTableColumnComponent headerText="Employee Name" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" />
                                            <DGDGTableColumnComponent headerText="Job Title" dataColumn="job_title" sortColumn="job_title" filterColumn="job_title" cssName="text-nowrap" />
                                            <DGDGTableColumnComponent headerText="Plan Date" dataColumn="plan_date" sortColumn="plan_date" filterColumn="plan_date" dataType="date" />
                                            <DGDGTableColumnComponent headerText="Plan Name" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" cssName="text-nowrap" />
                                            <DGDGTableColumnComponent headerText="Wage" dataColumn="wage" sortColumn="wage" filterColumn="wage" dataType="rate" />
                                            <DGDGTableColumnComponent headerText="Mid Month Guarantee" dataColumn="mid_month_guarantee" sortColumn="mid_month_guarantee" filterColumn="mid_month_guarantee" dataType="money" />
                                            <DGDGTableColumnComponent headerText="Monthly Guarantee" dataColumn="monthly_guarantee" sortColumn="monthly_guarantee" filterColumn="monthly_guarantee" dataType="money" />
                                            <DGDGTableColumnComponent headerText="ASP" dataColumn="asp" sortColumn="asp" filterColumn="asp" dataType="bool" />
                                            <DGDGTableColumnComponent headerText="Edit" cssName="text-right" dataText="<span class='fas fa-edit' />" disabledColumn="disable_edit" onCellClick={this.onPayPlanEditCellClick} />
                                        </DGDGTableRowComponent>
                                        <DGDGTableCustomComponent>
                                            <SalesPayrollDetailComponent
                                                payrollMonth={this.state.selectedSetupMonth}
                                                isEditEnabled={this.state.isPayrollEditEnabled}
                                                onEditClick={this.onPayrollDetailEditCellClick}
                                                onShowAlert={this.props.onShowAlert} />
                                        </DGDGTableCustomComponent>
                                    </DGDGTableV2Component>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
