import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { DealIssuesComponent } from "./DealIssuesComponent";
import { DealIssuesSummaryComponent } from "./DealIssuesSummaryComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class DealIssuesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DealIssuesRouteComponent - componentDidMount");
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("DealIssuesRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Deal Issues";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DealIssuesRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "DealIssuesRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/DealIssues/GetControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/DealIssuesRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/DealIssues/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackTrace({ message: "/DealIssues/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/DealIssues/DealIssues" className={"nav-item nav-link" + (window.location.pathname.indexOf("/DealIssues/DealIssues") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Deal Issues</Link>
                    <Link to="/DealIssues/Summary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Summary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Summary</Link>
                </nav>
                <br />
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/DealIssues/DealIssues" render={(routeProps) => <DealIssuesComponent {...this.props} {...routeProps} stores={this.state.stores} onShowAlert={this.props.onShowAlert} />} />
                        <Route exact path="/DealIssues/Summary" render={(routeProps) => <DealIssuesSummaryComponent {...this.props} {...routeProps} stores={this.state.stores} onShowAlert={this.props.onShowAlert} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
