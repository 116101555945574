import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { EVOXImagesEVOXVehiclesQueryComponent } from "./EVOXImagesEVOXVehiclesQueryComponent";
import { EVOXImagesComponent } from "./EVOXImagesComponent";

export class EVOXImagesEVOXVehiclesComponent extends React.Component {
    statusList = ["Active", "Hidden"];

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            tableData: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onYMMTColumnCallback = this.onYMMTColumnCallback.bind(this);
        this.onPhotoColumnCallback = this.onPhotoColumnCallback.bind(this);
        this.onShowEVOXPhotosClick = this.onShowEVOXPhotosClick.bind(this);
        this.onCancelEVOXPhotosClick = this.onCancelEVOXPhotosClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onVehicleIdColumnCallback = this.onVehicleIdColumnCallback.bind(this);
        this.onIsActiveChange = this.onIsActiveChange.bind(this);
        this.onStatusColumnCallback = this.onStatusColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "EVOX Vehicles";
    evoxImageBaseUrl = "/EVOXImages/EVOXImage/";

    componentDidMount() {
        try {
            console.devLog("EVOXImagesEVOXVehiclesComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/EVOXImages/KBBEVOXMapping", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }


    onGetReportClick(vifNumber, selectedView) {
        try {
            this.setState({ isLoadingData: true });

            let inputData = {
                vifNumber: vifNumber,
                view: selectedView ? selectedView.key : null
            };

            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEvoxVehiclesData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.evox_display_name = responseItem.ymmt + " "
                            + (responseItem.body ? responseItem.body : "") + " ("
                            + responseItem.vif_number + ", "
                            + responseItem.image_product_downloaded + ", "
                            + CommonService.formatDate(responseItem.date_delivered) + ")";

                        if (responseItem.status === "Inactive") {
                            responseItem.rowCssName = "evox-images-inactive-mapping";
                        }
                        else if (responseItem.status === "Hidden") {
                            responseItem.rowCssName = "evox-images-hidden";
                        }

                        responseItem.is_active = responseItem.status === "Active";
                        responseItem.is_active_label = "Hidden";
                        responseItem.is_active_label2 = "Active";
                    });
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEvoxVehiclesData", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onYMMTColumnCallback(rowData) {
        return <Link to={"/EVOXImages/KBBEVOXMapping/" + rowData.year + "/" + rowData.make}>{rowData.ymmt}</Link>;
    }

    onPhotoColumnCallback(rowData) {
        if (rowData.last_image_download_date) {
            return <button type="button" className="btn btn-link" onClick={(event) => this.onShowEVOXPhotosClick(event, rowData)} tabIndex="-1">
                EVOX
            </button>;
        }

        return <button type="button" className="btn btn-link" disabled>
            EVOX
        </button>;
    }

    onShowEVOXPhotosClick(event, rowData) {
        try {
            this.setState({
                evoxPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onShowEVOXPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEVOXVehicleOEMColors/" + rowData.vif_number)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            evoxVehicleOEMColors: parsedResponse
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            evoxVehicleOEMColors: sortedOEMColors
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEVOXVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onIsActiveChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.is_active = isChecked;
            rowData.is_active_showSpinner = true;
            rowData.status = isChecked ? "Active" : "Hidden";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                vifNumber: rowData.vif_number,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onIsActiveChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/SaveEVOXVehicleStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.rowCssName = "";
                    if (rowData.status === "Inactive") {
                        rowData.rowCssName = "evox-images-inactive-mapping";
                    }
                    else if (rowData.status === "Hidden") {
                        rowData.rowCssName = "evox-images-hidden";
                    }
                    rowData.is_active_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_active_showSpinner = false;
                    this.setState({
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/SaveEVOXVehicleStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelEVOXPhotosClick(event) {
        this.setState({
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null
        });
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("EVOXImagesEVOXVehiclesComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVehicleIdColumnCallback(rowData) {
        if (rowData.vehicle_id)
            return <Link to={"/EVOXImages/KBBEVOXMapping/" + rowData.vehicle_id}>{rowData.vehicle_id}</Link>;
    }

    onStatusColumnCallback(rowData) {
        try {

            if (rowData.status !== "Inactive") {
                return <Fragment>
                    <DGDGFormCheckboxV2Component id={"is_active-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData} value={rowData.is_active} label={rowData.is_active_label} label2={rowData.is_active_label2} onChange={this.onIsActiveChange} cssName="dgdg-sell-trade-kbb-evox-image-status" />
                    {rowData.is_active_showSpinner ? <DGDGSpinnerComponent cssName="float-right" showSpinner /> : null}
                </Fragment>
            }
            else {
                return rowData.status;
            }

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let evoxOEMColors = [];
        if (this.state.evoxVehicleOEMColors && !this.state.evoxVehicleOEMColors.status) {
            this.state.evoxVehicleOEMColors.forEach((oemColor) => {
                let rgbColor = "#" + oemColor.rgb1;
                evoxOEMColors.push(<div className="d-inline-block dgdg-sell-trade-kbb-evox-oem-color" style={{ "backgroundColor": rgbColor }} role="button" onClick={(event) => this.onEVOXVehicleOEMColorsClick(event, oemColor)} />);
            });
        }

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <EVOXImagesComponent
                vehicleOEMColors={this.state.evoxVehicleOEMColors}
                photoRowData={this.state.evoxPhotoRowData}
                imageBaseUrl={this.evoxImageBaseUrl}
                vehicleIdPropertyName="vif_number"
                imgUrlSeparator="/"
                imageExtension=""
                imageAlt="EVOX"
                imageContainer="download"
                imageBlobPrefix="EVOX/Image"
                titlePrefix="EVOX Photos"
                onCancelClick={this.onCancelEVOXPhotosClick}
                logPart2="EVOX image not found"
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
            />
            <EVOXImagesEVOXVehiclesQueryComponent
                isLoadingData={this.state.isLoadingData}
                {...this.props}
                onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="EVOX Vehicles" tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="VIF Number" dataColumn="vif_number" filterColumn="vif_number" sortColumn="vif_number" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Mapped KBB Vehicle Id" dataColumnCallback={this.onVehicleIdColumnCallback} dataColumn="vehicle_id" filterColumn="vehicle_id" sortColumn="vehicle_id" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Status" dataColumn="status" dataColumnCallback={this.onStatusColumnCallback} />
                        <DGDGTableColumnComponent headerText="YMMT" dataColumn="ymmt" filterColumn="ymmt" sortColumn="ymmt" dataColumnCallback={this.onYMMTColumnCallback} />
                        <DGDGTableColumnComponent headerText="Date Delivered" dataColumn="date_delivered" sortColumn="date_delivered" filterColumn="date_delivered" headerCssName="text-nowrap" dataType="date" />
                        <DGDGTableColumnComponent headerText="Image Download Date" dataColumn="last_image_download_date" sortColumn="last_image_download_date" filterColumn="last_image_download_date" headerCssName="text-nowrap" dataType="date" />
                        <DGDGTableColumnComponent headerText="Image Product" dataColumn="image_product_downloaded" sortColumn="image_product_downloaded" filterColumn="image_product_downloaded" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Images" cssName="text-nowrap" dataColumnCallback={this.onPhotoColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
