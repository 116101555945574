import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent"

import { ServiceStoreReportDayQueryComponent } from "./ServiceStoreReportDayQueryComponent";

export class ServiceStoreReportDayComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableTitle: "",
            footerRow: null,
            paintTable: false,
            isLoadingData: false,
            showSpinnerChart: false,
            chartData: null,
            chartOption: {
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1 },
                    2: { targetAxisIndex: 1 }
                },
                hAxis: {
                    title: "Date",
                    format: "M/d",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "RO Count",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    },
                    1: {
                        title: "Fixed Gross",
                        format: "$#,###",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ServiceStoreReportDayComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceStoreReport/Day", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getChartData();
            CommonService.clientAzureStorageLog("ServiceStoreReportDayComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service Store Report";

    formatFooterNumber(key) {
        return CommonService.formatNumber(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    formatFooterCurrency(key) {
        return CommonService.formatCurrency(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    getLastUpdatedDate(day) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(day)
            };
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetLastUpdatedDayReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReport/GetLastUpdatedDayReport", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(day) {
        try {
            this.setState({ isLoadingData: true });
            this.getLastUpdatedDate(day);
            let inputData = {
                month: CommonService.formatServerDateTime(day)
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDayReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.filter(tableRow => tableRow.store_number !== 99);
                    tableData.forEach((responseItem, index) => {
                        responseItem.store_web_name = responseItem.store_number + " | " + responseItem.store_name;
                    });
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableTitle: CommonService.formatDate(day),
                        tableData: CommonService.addOrdinalColumn(tableData),
                        footerRow: parsedResponse.filter(tableRow => tableRow.store_number === 99)[0]
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableTitle: "",
                        tableData: [],
                        footerRow: null
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReport/GetDayReport", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ServiceStoreReportDayComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getChartData() {
        this.setState({
            showSpinnerChart: true
        });
        this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/getChartData", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/ServiceStoreReport/GetServiceStoreChartData")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    chartData: parsedResponse,
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetServiceStoreChartData", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            })
            .finally(() => {
                this.setState({
                    showSpinnerChart: false
                });
            });
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceStoreReportDayQueryComponent
                applicationInsights={this.props.applicationInsights}
                onGetReportClick={this.onGetReportClick}
                isLoadingData={this.state.isLoadingData}
                isActive={this.props.isActive}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Service Store - " + this.state.tableTitle} tableData={this.state.tableData} paintTable={this.props.paintTable} footerRow={this.state.footerRow} showFooter
                        copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="4" className="text-center font-weight-bold dgdg-service-store-report-bg-head">ROs</td>
                                <td colSpan="3" className="text-center font-weight-bold dgdg-service-store-report-bg-head">Gross</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-service-store-report-header-green">Customer Pay</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-service-store-report-header-yellow">Warranty Pay</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-service-store-report-header-white">Internal Pay</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Actual Hours Worked" dataColumn="actual_hours" sortColumn="actual_hours" filterColumn="actual_hours" dataType="formatted_number" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" filterColumn="sold_hours" dataType="formatted_number" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Open ROs" dataColumn="open_ro_count" sortColumn="open_ro_count" filterColumn="open_ro_count" dataType="formatted_number" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Closed ROs" dataColumn="ro_count" sortColumn="ro_count" filterColumn="ro_count" dataType="formatted_number" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Service Gross" dataColumn="service_gross" filterColumn="service_gross" sortColumn="service_gross" dataType="money" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Parts Gross" dataColumn="parts_gross" filterColumn="parts_gross" sortColumn="parts_gross" dataType="money" footerCssName="text-right" columnHelp="Parts Gross (including Gas, Oil & Grease)" />
                            <DGDGTableColumnComponent headerText="Fixed Gross" dataColumn="fixed_gross" filterColumn="fixed_gross" sortColumn="fixed_gross" dataType="money" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="CP ROs" dataColumn="cp_ro_count" sortColumn="cp_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("cp_ro_count")} />
                            <DGDGTableColumnComponent headerText="CP Labor & Parts Gross" dataColumn="cp_labor_parts_gross" sortColumn="cp_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("cp_labor_parts_gross")} />
                            <DGDGTableColumnComponent headerText="WP ROs" dataColumn="wp_ro_count" sortColumn="wp_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("wp_ro_count")} />
                            <DGDGTableColumnComponent headerText="WP Labor & Parts Gross" dataColumn="wp_labor_parts_gross" sortColumn="wp_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("wp_labor_parts_gross")} />
                            <DGDGTableColumnComponent headerText="IP ROs" dataColumn="ip_ro_count" sortColumn="ip_ro_count" dataType="formatted_number" footerCssName="text-right " footerText={this.formatFooterNumber("ip_ro_count")} />
                            <DGDGTableColumnComponent headerText="IP Labor & Parts Gross" dataColumn="ip_labor_parts_gross" sortColumn="ip_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("ip_labor_parts_gross")} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div className="card">
                    <DGDGWidgetComponent title="RO Count and Gross for last 30 days" restoredCss="dgdg-widget-maximized" isVisible>
                        <DGDGSpinnerComponent showSpinner={this.state.showSpinnerChart} />
                        {
                            this.state.chartData
                                ? <DGDGChartLineComponent chartData={this.state.chartData} chartOption={this.state.chartOption} />
                                : null
                        }
                    </DGDGWidgetComponent>
                </div>
            </div>
        </div>;
    }
}
