import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { TechnicianScoreCardPayPeriodComponent } from "./TechnicianScoreCardPayPeriodComponent";
import { TechnicianScoreCardMonthComponent } from "./TechnicianScoreCardMonthComponent";
import { TechnicianScoreCardDayComponent } from "./TechnicianScoreCardDayComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class TechnicianScoreCardRouteComponent extends React.Component {
    static StoreNumberCookie = "TechnicianScoreCardStoreNumberCookie";
    static MonthCookie = "TechnicianScoreCardMonthCookie";

    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            stores: null,
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("TechnicianScoreCardRouteComponent - componentDidMount");
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("TechnicianScoreCardRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Technician Scorecard";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianScoreCard/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "ServiceStoreReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TechnicianScoreCard/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/TechnicianScoreCard/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TechnicianScoreCard/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            })
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/TechnicianScoreCard/PayPeriod" className={"nav-item nav-link" + (window.location.pathname.indexOf("/PayPeriod") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Pay Period</Link>
                    <Link to="/TechnicianScoreCard/Month" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Month") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Month</Link>
                    <Link to="/TechnicianScoreCard/Day" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Day") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Day</Link>
                    <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}</span>
                    </div>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/TechnicianScoreCard/PayPeriod" render={(routeProps) => <TechnicianScoreCardPayPeriodComponent {...this.props} {...routeProps} stores={this.state.stores} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/TechnicianScoreCard/Month" render={(routeProps) => <TechnicianScoreCardMonthComponent {...this.props} {...routeProps} months={this.state.months} stores={this.state.stores} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/TechnicianScoreCard/Day" render={(routeProps) => <TechnicianScoreCardDayComponent {...this.props} {...routeProps} stores={this.state.stores} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
