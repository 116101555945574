import React, { Fragment } from "react";

import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";

import { DealIssuesQueryComponent } from "./DealIssuesQueryComponent";
import { DealIssuesAddComponent } from "./DealIssuesAddComponent";

export class DealIssuesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            showDialog: false,
            paintTable: false,
            selectedStore: null,
            showHidden: false,
            tableTitle: "",
            tableData: []
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onAddDealIssuesClick = this.onAddDealIssuesClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onShowHiddenChange = this.onShowHiddenChange.bind(this);
        this.onIsHiddenChange = this.onIsHiddenChange.bind(this);
        this.onCopyRowColumnCallback = this.onCopyRowColumnCallback.bind(this);
        this.onCopyRowClick = this.onCopyRowClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Deal Issues"

    componentDidMount() {
        try {
            console.devLog("DealIssues - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DealIssues/DealIssues", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("DealIssues", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore.store_number
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;

                    if (!this.state.showHidden) {
                        tableData = tableData.filter(rowData => !rowData.is_hidden);
                    }

                    tableData.forEach((responseItem, index) => {
                        responseItem.is_hidden_label = "Hide";
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: selectedStore.display_name,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: "",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comment);

        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.deal_number} value={rowData["comments_" + rowData.deal_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({
                paintTable: false
            }, () => {
                if (rowData.showAddComment) {
                    let id = "#comments_" + rowData.deal_number;
                    $(id).focus();
                }
            });
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.deal_number === id.split('_')[1])[0];
            rowData["comments_" + rowData.deal_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddDealIssuesClick(event) {
        try {
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deal_number,
                comments: rowData["comments_" + rowData.deal_number].replace(/\n|\r/g, " ")
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comment = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.deal_number] + (rowData.comment ? '\r' + rowData.comment : "");
                    rowData["comments_" + rowData.deal_number] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onSaveCommentClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, dealIssues) {
        try {
            let inputData = dealIssues;
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore);
                    this.props.onShowAlert("success", { message: "Deal Issues saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onSaveClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DealIssues", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowHiddenChange(isChecked) {
        this.setState({ showHidden: isChecked });
    }

    onIsHiddenChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.is_hidden = isChecked;
            rowData.is_hidden_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deal_number,
                isHidden: rowData.is_hidden
            };

            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onIsHiddenChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveIsHidden", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (!this.state.showHidden && isChecked) {
                        tableData = tableData.filter(row => row.deal_number !== rowData.deal_number);
                    }
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "Deal Issue # " + rowData.deal_number + " hide saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/DealIssues/onIsHiddenChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyRowColumnCallback(rowData) {
        return <Fragment>
            <div className="form-inline">
                <button type="button" className="btn btn-link" onClick={(event) => this.onCopyRowClick(event, rowData)}>
                    <span className="far fa-copy" />
                </button>
            </div>
        </Fragment>;
    }

    onCopyRowClick(event, rowData) {
        try {
            let rowText = "Store Name\tDeal Number\tContract Date\tNew Used Wholesale\tMake\tYear\tModel\tStock Number\tVIN\tCustomer Name\tFinance Source\tSales Manager Name\tFinance Manager Name\tStatus\r\n";

            if (rowData) {
                rowText += (rowData.store_web_name ? rowData.store_web_name : "")
                    + "\t" + (rowData.deal_number ? rowData.deal_number : "")
                    + "\t" + (rowData.contract_date ? CommonService.formatDate(rowData.contract_date) : "")
                    + "\t" + (rowData.new_used_wholesale ? rowData.new_used_wholesale : "")
                    + "\t" + (rowData.make ? rowData.make : "")
                    + "\t" + (rowData.year ? (rowData.year) : "")
                    + "\t" + (rowData.model ? rowData.model : "")
                    + "\t" + (rowData.stock_number ? rowData.stock_number : "")
                    + "\t" + (rowData.vin ? rowData.vin : "")
                    + "\t" + (rowData.customer_name ? rowData.customer_name : "")
                    + "\t" + (rowData.finance_source ? rowData.finance_source : "")
                    + "\t" + (rowData.sales_mgr_name ? rowData.sales_mgr_name : "")
                    + "\t" + (rowData.finance_mgr_name ? rowData.finance_mgr_name : "")
                    + "\t" + (rowData.status ? rowData.status : "")
                    + "\r\n";
            }
            navigator.clipboard.writeText(rowText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DealIssues", "onCopyRowClick");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DealIssuesAddComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showDialog}
                selectedStore={this.state.selectedStore}
                title={this.state.tableTitle}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DealIssuesQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                onStoreClick={this.onStoreClick}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onAddDealIssuesClick={this.onAddDealIssuesClick}
                onShowHiddenChange={this.onShowHiddenChange}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" filterColumn="deal_number" sortColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="New Used Wholesale" dataColumn="new_used_wholesale" filterColumn="new_used_wholesale" sortColumn="new_used_wholesale" />
                        <DGDGTableColumnComponent headerText="Make" dataColumn="make" filterColumn="make" sortColumn="make" />
                        <DGDGTableColumnComponent headerText="Year" dataColumn="year" filterColumn="year" sortColumn="year" />
                        <DGDGTableColumnComponent headerText="Model" dataColumn="model" filterColumn="model" sortColumn="model" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" filterColumn="stock_number" sortColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" filterColumn="vin" sortColumn="vin" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Finance Source" dataColumn="finance_source" filterColumn="finance_source" sortColumn="finance_source" />
                        <DGDGTableColumnComponent headerText="Sales Manager Name" dataColumn="sales_mgr_name" filterColumn="sales_mgr_name" sortColumn="sales_mgr_name" />
                        <DGDGTableColumnComponent headerText="Finance Manager Name" dataColumn="finance_mgr_name" filterColumn="finance_mgr_name" sortColumn="finance_mgr_name" />
                        <DGDGTableColumnComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent cssName="dgdg-no-border-top">
                        <DGDGTableColumnComponent dataColumnCallback={this.onCopyRowColumnCallback} />
                        <DGDGTableColumnCheckboxV2Component colSpan="2" headerText="Hide" dataColumn="is_hidden" labelColumn="is_hidden_label" dataType="bool" onChange={this.onIsHiddenChange} showSpinner />
                        <DGDGTableColumnComponent colSpan="3" />
                        <DGDGTableColumnComponent colSpan="4" headerText="Last Updated By" dataColumn="last_updated_by" showHeader />
                        <DGDGTableColumnComponent colSpan="5" dataColumnCallback={this.onAddCommentColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
