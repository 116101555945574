import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

export class FIStoreReportFIManagerTopTenComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maximizedWidget: null
        };
        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onMaximizeClick(event, widgetName) {
        try {
            this.setState({ maximizedWidget: widgetName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedWidget: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("FIStoreReportFIManagerTopTenComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="row" style={{ "margin": "0px" }}>
            <div className="col-3 dgdg-fi-store-report-top-10-manager">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.pvrData} headerText="Top 10 Back PVR" paintTable={this.props.paintTable} cardCssName="d-block dgdg-box-shadow"
                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_number" sortColumn="store_number" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="PVR" dataColumn="back_pvr" sortColumn="back_pvr" dataType="money" cssName="text-center" footerCssName="text-center" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
            <div className="col-3 dgdg-fi-store-report-top-10-manager">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.pprData} headerText="Top 10 PPR" paintTable={this.props.paintTable} cardCssName="d-block dgdg-box-shadow"
                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_number" sortColumn="store_number" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="PPR" dataColumn="ppr" sortColumn="ppr" dataType="number_2" cssName="text-center" footerCssName="text-center" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
            <div className="col-3 dgdg-fi-store-report-top-10-manager">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.warrantyData} headerText="Top 10 Warranty (VSC) Penetration" paintTable={this.props.paintTable} cardCssName="d-block dgdg-box-shadow"
                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_number" sortColumn="store_number" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Warranty" dataColumn="vsc_penetration" sortColumn="vsc_penetration" dataType="number_2" cssName="text-center" footerCssName="text-center" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
            <div className="col-3 dgdg-fi-store-report-top-10-manager">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.maintenanceData} headerText="Top 10 Maintenance Penetration" paintTable={this.props.paintTable} cardCssName="d-block dgdg-box-shadow"
                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_number" sortColumn="store_number" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Maintenance" dataColumn="maint_penetration" sortColumn="maint_penetration" dataType="number_2" cssName="text-center" footerCssName="text-center" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}