import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGEventSourceComponent } from "../js_modules/dgdgjs/DGDGEventSourceComponent";

import { BalanceSheetComponent } from "./BalanceSheetComponent";
import { TotalExpensesComponent } from "./TotalExpensesComponent";
import { ExpenseBreakdownComponent } from "./ExpenseBreakdownComponent";
import { SalesGrossBreakdownComponent } from "./SalesGrossBreakdownComponent";
import { ServicePartsGrossBreakdownComponent } from "./ServicePartsGrossBreakdownComponent";
import { FinancialStatementLineDetailComponent } from "./FinancialStatementLineDetailComponent";
import { FinancialStatementQueryComponent } from "./FinancialStatementQueryComponent";

export class FinancialStatementRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        let reCastLevels = [];
        reCastLevels.push({ "level": 2, "name": "Recast Level 2" });
        reCastLevels.push({ "level": 1, "name": "Recast Level 1" });
        reCastLevels.push({ "level": 0, "name": "Recast Level 0" });
        let months = CommonService.getMonths();
        months.shift();

        this.state = {
            isLoadingFirst: true,
            showSpinner: false,
            lastUpdatedDate: null,
            isFinancialStatementConfirmBlobFound: true,
            months: months,
            selectedMonth: moment().startOf('month'),
            failOverMonth: null,
            stores: null,
            selectedStore: null,
            activeNavItem: "#balanceSheetSection",
            fsDict: null,
            //mode: "",
            //reCastLevels: reCastLevels,
            selectedReCastLevel: reCastLevels[0],
            tableTitle: "",
            tableData: [],
            tableShowSpinner: false,
            paintTable: false,
            childModelClosedTime: null,
            detailTableTitle: "",
            detailTableData: [],
            detailShowSpinner: false,
            detailPaintTable: false,
            currentStore: "",
            currentMonth: null,
            lineItem: null,
            enableDetailAmountClick: false,
            isConsolidated: false,
            blobName: "",
            accountDetailBlobName: "",
            downloadedBlobCount: "",
            detailBlobCount: "",
            isDeveloper: false,
            isInSURole: false
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onGetStatementClick = this.onGetStatementClick.bind(this);
        this.onFinancialStatementDetailClick = this.onFinancialStatementDetailClick.bind(this);
        this.getFinancialStatementDetailMsgCallback = this.getFinancialStatementDetailMsgCallback.bind(this);
        this.onLineDetailAmountClick = this.onLineDetailAmountClick.bind(this);
        this.onLineDetailCancelClick = this.onLineDetailCancelClick.bind(this);
        this.onLineDetailTotalAmountClick = this.onLineDetailTotalAmountClick.bind(this);
        this.onLineDetailTotalAmountCancelClick = this.onLineDetailTotalAmountCancelClick.bind(this);
        this.lineDetailTotalAmountMsgCallback = this.lineDetailTotalAmountMsgCallback.bind(this);
        //this.onReCastLevelClick = this.onReCastLevelClick.bind(this);
        this.onScrollClick = this.onScrollClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FinancialStatementRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FinancialStatement", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("FinancialStatementRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        try {
            console.devLog("FinancialStatementRouteComponent - componentWillUnmount");
            CommonService.clientAzureStorageLog("FinancialStatementRouteComponent", "componentWillUnmount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Financial Statement";
    lastUpdatedEventSource;
    accountDetailEventSource

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "FinancialStatementRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FinancialStatement/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
            };

            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse.lastUpdatedDate))
                        lastUpdatedDate = moment.utc(parsedResponse.lastUpdatedDate);
                    this.setState({
                        lastUpdatedDate: lastUpdatedDate,
                        isFinancialStatementConfirmBlobFound: parsedResponse.financialStmtConfirmedBlobFound
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(selectedMonth, selectedStore) {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
                storeNumber: selectedStore.store_number
            };

            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ showSpinner: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download." });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    //onReCastLevelClick(event, reCastLevel) {
    //    this.setState({ selectedReCastLevel: reCastLevel });
    //}

    onGetStatementClick(selectedMonth, selectedStore, failOver) {
        try {
            this.getLastUpdatedDate(selectedMonth);
            this.setState({
                showSpinner: true,
                selectedMonth: selectedMonth,
                selectedStore: selectedStore,
                isConsolidated: selectedStore.store_number === 0 ? true : false,
                fsDict: null
            });

            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
                storeNumber: selectedStore.store_number,
                recastlevel: this.state.selectedReCastLevel.level,
                failOver: failOver
            };

            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/onGetStatementClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/GetStatement", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status === "BlobNotFound" && failOver) {
                        this.setState({ failOverMonth: this.state.months[1] });
                    }
                    else {
                        this.setState({
                            showSpinner: false,
                            isLoadingFirst: false,
                            fsDict: parsedResponse,
                            currentStore: selectedStore ? selectedStore.display_name : "",
                            currentMonth: selectedMonth
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/GetStatement", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("warning", parsedError, 4000);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFinancialStatementDetailClick(event, lineItem, enableDetailAmountClick) {
        try {
            this.setState({
                tableShowSpinner: true,
                lineItem: lineItem,
                enableDetailAmountClick: enableDetailAmountClick,
                downloadedBlobCount: "",
                isDeveloper: false,
                isInSURole: false
            });
            this.lastUpdatedEventSource = new DGDGEventSourceComponent();
            this.lastUpdatedEventSource.addEventSource(
                "/FinancialStatement/GetFinancialStatementLineDetail?Month=" + CommonService.formatServerDateTime(this.state.selectedMonth) + "&StoreNumber=" + this.state.selectedStore.store_number + "&OEM=" + lineItem[3] + "&YM=" + lineItem[1] + "&Category=" + lineItem[4].replace("&", "%26") + "&SubCategory=" + lineItem[5].replace("&", "%26") + "&Description=" + lineItem[6].replace("&", "%26"),
                "data", this.getFinancialStatementDetailMsgCallback);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getFinancialStatementDetailMsgCallback(eventData) {
        let parsedResponse = eventData.Value;
        if (parsedResponse.Status && parsedResponse.Status === "DetailNotFound") {
            let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                + ", Store Number: " + this.state.selectedStore.store_number
                + ", OEM: " + this.state.lineItem[3]
                + ", YM: " + this.state.lineItem[1]
                + ", Category: " + this.state.lineItem[4]
                + ", Sub-Category: " + this.state.lineItem[5]
                + ", Description: " + this.state.lineItem[6] + ".";
            this.props.onShowAlert("danger", { message: message });
            this.setState({
                tableShowSpinner: false
            });
        }
        else if (parsedResponse.Detail) {
            let amount = this.state.lineItem[8] < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(this.state.lineItem[8]) + "</span>" : CommonService.formatCurrency_2(this.state.lineItem[8]);
            this.setState({
                blobName: parsedResponse.BlobName + " (Blob Count: " + parsedResponse.DownloadedBlobCount + ")",
                downloadedBlobCount: "Blob: " + parsedResponse.DownloadedBlobCount,
                isDeveloper: parsedResponse.IsDeveloper,
                isInSURole: parsedResponse.IsInSURole,
                tableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + this.state.selectedStore.store_name + " - " + this.state.lineItem[6] + " - " + amount,
                tableData: CommonService.addOrdinalColumn(parsedResponse.Detail),
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        if (parsedResponse.Count === 0) {
            this.setState({
                downloadedBlobCount: "",
                tableShowSpinner: false,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.lastUpdatedEventSource.closeEventSource();
        }
    }

    onLineDetailCancelClick(event) {
        try {
            this.setState({
                tableTitle: "",
                tableData: [],
                tableShowSpinner: false,
                paintTable: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLineDetailAmountClick(event, lineItemDetail) {
        try {
            this.setState({ detailShowSpinner: true });
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: lineItemDetail.sn,
                ym: this.state.lineItem[1],
                accountNumber: lineItemDetail.an,
                category: lineItemDetail.lc,
                subCategory: lineItemDetail.lsc,
                description: lineItemDetail.ld
            };

            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementRouteComponent/onLineDetailAmountClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/GetFinancialStatementAccountDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + this.state.selectedStore.store_number
                            + ", YM: " + this.state.lineItem[1]
                            + ", Account: " + lineItemDetail.an + ".";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        let amount = this.state.lineItem[8] < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(lineItemDetail.amt) + " of " + CommonService.formatCurrency_2(this.state.lineItem[8]) + "</span>" : CommonService.formatCurrency_2(lineItemDetail.amt) + " of " + CommonService.formatCurrency_2(this.state.lineItem[8]);
                        this.setState({
                            lineItemAmount: lineItemDetail.amt,
                            accountDetailBlobName: parsedResponse.blobName,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + this.state.selectedStore.store_name + " - " + this.state.lineItem[6]
                                + " - " + amount,
                            detailShowSpinner: false,
                            detailPaintTable: true
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableTitle: "",
                        detailTableData: [],
                        detailShowSpinner: false,
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FinancialStatement/GetFinancialStatementAccountDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLineDetailTotalAmountClick(event) {
        try {
            this.setState({
                detailShowSpinner: true,
                detailBlobCount: ""
            });
            let details = "";
            this.state.tableData.forEach((rowData, index) => { details += (rowData.sn + "," + rowData.an + "|"); });

            this.accountDetailEventSource = new DGDGEventSourceComponent();
            this.accountDetailEventSource.addEventSource(
                "/FinancialStatement/GetTotalFinancialStatementAccountDetail?Month=" + CommonService.formatServerDateTime(this.state.selectedMonth) + "&StoreNumber=" + this.state.selectedStore.store_number + "&YM=" + this.state.lineItem[1] + "&Details=" + details,
                "data", this.lineDetailTotalAmountMsgCallback);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    lineDetailTotalAmountMsgCallback(eventData) {
        let parsedResponse = eventData.Value;
        if (parsedResponse.Detail) {
            let amount = this.state.lineItem[8] < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(this.state.lineItem[8]) + " of " + CommonService.formatCurrency_2(this.state.lineItem[8]) + "</span>" : CommonService.formatCurrency_2(this.state.lineItem[8]) + " of " + CommonService.formatCurrency_2(this.state.lineItem[8]);
            this.setState({
                accountDetailBlobName: parsedResponse.BlobName + " (Blob Count: " + parsedResponse.DownloadedBlobCount + ")",
                detailBlobCount: "Blob: " + parsedResponse.DownloadedBlobCount,
                detailTableData: CommonService.addOrdinalColumn(parsedResponse.Detail),
                detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + this.state.selectedStore.store_name + " - " + this.state.lineItem[6]
                    + " - " + amount,
                lineItemAmount: this.state.lineItem[8],
                detailPaintTable: true
            }, () => {
                this.setState({ detailPaintTable: false });
            });
        }
        else if (parsedResponse.Status && parsedResponse.Status === "DetailNotFound" && parsedResponse.Count === 0) {
            this.setState({ detailShowSpinner: false });
        }
        if (parsedResponse.Count === 0) {
            this.setState({
                detailShowSpinner: false,
                detailBlobCount: ""
            });
            this.accountDetailEventSource.closeEventSource();
        }
    }

    onLineDetailTotalAmountCancelClick(event) {
        try {
            this.setState({
                detailTableTitle: "",
                detailTableData: [],
                detailShowSpinner: false,
                detailPaintTable: true,
                childModelClosedTime: moment()
            }, () => {
                this.setState({ detailPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    //onDevModeToggleClick(event) {
    //    if (this.state.mode === "Dev") {
    //        this.setState({ mode: "" });
    //    }
    //    else {
    //        this.setState({ mode: "Dev" });
    //    }
    //}

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-site-scrollable-content", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <FinancialStatementLineDetailComponent
                applicationInsights={this.props.applicationInsights}
                blobName={this.state.blobName}
                accountDetailBlobName={this.state.accountDetailBlobName}
                tableTitle={this.state.tableTitle}
                tableData={this.state.tableData}
                showSpinner={this.state.tableShowSpinner}
                downloadedBlobCount={this.state.downloadedBlobCount}
                isDeveloper={this.state.isDeveloper}
                paintTable={this.state.paintTable}
                childModelClosedTime={this.state.childModelClosedTime}
                enableDetailAmountClick={this.state.enableDetailAmountClick}
                onLineDetailAmountClick={this.onLineDetailAmountClick}
                onCancelClick={this.onLineDetailCancelClick}
                onLineDetailTotalAmountClick={this.onLineDetailTotalAmountClick}
                detailTableData={this.state.detailTableData}
                detailPaintTable={this.state.detailPaintTable}
                detailTableTitle={this.state.detailTableTitle}
                detailShowSpinner={this.state.detailShowSpinner}
                detailBlobCount={this.state.detailBlobCount}
                onLineDetailTotalAmountCancelClick={this.onLineDetailTotalAmountCancelClick}
                onShowAlert={this.props.onShowAlert}
                lineItemAmount={this.state.lineItemAmount}
            />
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge dgdg-icon">Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}</span>
                {
                    !this.state.isFinancialStatementConfirmBlobFound
                        ? <span className="float-right badge badge-light dgdg-badge dgdg-fin-stmt-badge">Limited Visibility</span>
                        : null
                }
            </div>
            <div className="dgdg-flex-item dgdg-site-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <FinancialStatementQueryComponent
                        stores={this.state.stores}
                        failOverMonth={this.state.failOverMonth}
                        showSpinner={this.state.showSpinner}
                        isLoadingData={this.state.isLoadingData}
                        onGetStatementClick={this.onGetStatementClick}
                        onDownloadClick={this.onDownloadClick}
                        applicationInsights={this.props.applicationInsights}
                    />
                    <div className="card dgdg-flex-item">
                        <div className="card-block" style={{ "height": "100%" }}>
                            <div className="dgdg-flex dgdg-flex-column dgdg-site-content">
                                <nav className="nav nav-pills nav-justified dgdg-nav">
                                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link active" onClick={(event) => this.onScrollClick(event, "#balanceSheetSection")}>Balance Sheet</button>
                                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link active" onClick={(event) => this.onScrollClick(event, "#totalExpensesSection")}>Income & Expense</button>
                                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link active" onClick={(event) => this.onScrollClick(event, "#expenseBreakdownSection")}>Department Breakdown</button>
                                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link active" onClick={(event) => this.onScrollClick(event, "#salesGrossBreakdownSection")}>Sales Gross Breakdown</button>
                                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link active" onClick={(event) => this.onScrollClick(event, "#servicePartsGrossBreakdownSection")}>Service & Parts Breakdown</button>
                                </nav>
                                <div className="dgdg-flex-item dgdg-site-scrollable-content">
                                    <div id="balanceSheetSection">
                                        <h4 className="text-center font-weight-bold">Balance Sheet</h4>
                                        <BalanceSheetComponent applicationInsights={this.props.applicationInsights} fsDict={this.state.fsDict} mode={this.state.mode} onFinancialStatementDetailClick={!this.state.isConsolidated ? this.onFinancialStatementDetailClick : null} storeName={this.state.currentStore} selectedMonth={this.state.currentMonth} />
                                    </div>
                                    <br /> <br />
                                    <div id="totalExpensesSection">
                                        <h4 className="text-center font-weight-bold">Income & Expense</h4>
                                        <TotalExpensesComponent {...this.props} fsDict={this.state.fsDict} mode={this.state.mode} onFinancialStatementDetailClick={this.onFinancialStatementDetailClick} isInSURole={this.state.isInSURole} selectedStore={this.state.selectedStore} />
                                    </div>
                                    <br /> <br />
                                    <div id="expenseBreakdownSection">
                                        <h4 className="text-center font-weight-bold">Department Breakdown</h4>
                                        <ExpenseBreakdownComponent applicationInsights={this.props.applicationInsights} fsDict={this.state.fsDict} mode={this.state.mode} onFinancialStatementDetailClick={!this.state.isConsolidated ? this.onFinancialStatementDetailClick : null} isInSURole={this.state.isInSURole} />
                                    </div>
                                    <br /> <br />
                                    <div id="salesGrossBreakdownSection">
                                        <h4 className="text-center font-weight-bold">Sales Gross Breakdown</h4>
                                        <SalesGrossBreakdownComponent applicationInsights={this.props.applicationInsights} fsDict={this.state.fsDict} mode={this.state.mode} onFinancialStatementDetailClick={this.onFinancialStatementDetailClick} isConsolidated={this.state.isConsolidated} selectedStore={this.state.selectedStore} />
                                    </div>
                                    <br /> <br />
                                    <div id="servicePartsGrossBreakdownSection">
                                        <h4 className="text-center font-weight-bold">Service & Parts Breakdown</h4>
                                        <ServicePartsGrossBreakdownComponent applicationInsights={this.props.applicationInsights} fsDict={this.state.fsDict} mode={this.state.mode} onFinancialStatementDetailClick={this.onFinancialStatementDetailClick} isConsolidated={this.state.isConsolidated} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
