import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class NewPDIInventoryQueryComponent extends React.Component {
    static StoreNumberCookie = "NewPDIInventoryStoreNumberCookie";
    static ShowPDIDoneCookie = "NewPDIInventoryShowPDIDoneCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("NewPDIInventoryQueryComponent - getDerivedStateFromProps");
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            selectedStore: null,
            showPDIDone: true,
            setCookies: true
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onShowPDIDoneChange = this.onShowPDIDoneChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(NewPDIInventoryQueryComponent.StoreNumberCookie);
                let cookieShowPDIDone = CommonService.getCookie(NewPDIInventoryQueryComponent.ShowPDIDoneCookie);

                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                if (this.state.setCookies === true) {
                    this.setState({
                        selectedStore: selectedStore,
                        showPDIDone: !(cookieShowPDIDone === "false")
                    }, this.onGetReportClick);
                }

                else {
                    this.setState({
                        selectedStore: selectedStore
                    }, this.onGetReportClick);
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(NewPDIInventoryQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            CommonService.setCookie(NewPDIInventoryQueryComponent.ShowPDIDoneCookie, this.state.showPDIDone, 30);
            this.props.onGetReportClick(this.state.selectedStore, this.state.showPDIDone);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowPDIDoneChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} inputCssName="dgdg-new-pdi-store-width" />
            <DGDGFormCheckboxComponent id="showPDIDone" label="Show PDI Done" value={this.state.showPDIDone} onChange={this.onShowPDIDoneChange} labelCssName="dgdg-new-pdi-show-done-pdi" />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
