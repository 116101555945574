import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { DirectoryComponent } from "./DirectoryComponent";
import { DirectoryPhoneNumbersComponent } from "./DirectoryPhoneNumbersComponent";
import { DirectoryEmployeeComponent } from "./DirectoryEmployeeComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class DirectoryRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canContribute: false,
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DirectoryRouteComponent - componentDidMount");
            this.getControllerPolicy();
            this.canContribute();
            CommonService.clientAzureStorageLog("DirectoryRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Directory";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "DirectoryRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "DirectoryRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/getControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    canContribute() {
        this.props.applicationInsights.trackTrace({ message: "DirectoryRouteComponent/canContribute", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Directory/CanContribute")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ canContribute: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/CanContribute", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            {
                this.state.canContribute
                    ? <BrowserRouter>
                        <nav className="nav nav-pills dgdg-tab">
                            <Link to="/Directory/Directory" className={"nav-item nav-link" + (window.location.pathname.indexOf("Directory/Directory") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Directory</Link>
                            <Link to="/Directory/Employee" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Employee") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Employee</Link>
                            <Link to="/Directory/PhoneNumbers" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/PhoneNumbers") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Phone Numbers</Link>
                            {
                                this.state.lastUpdatedDate
                                    ? <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {CommonService.formatDateTimeZone(this.state.lastUpdatedDate)}</span>
                                    </div>
                                    : null
                            }
                        </nav>
                        <div className="dgdg-flex-item dgdg-site-content">
                            <Switch>
                                <Route exact path="/Directory/Directory" render={(routeProps) => <DirectoryComponent {...this.props} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                                <Route exact path="/Directory/Employee" render={(routeProps) => <DirectoryEmployeeComponent {...this.props} />} />
                                <Route exact path="/Directory/PhoneNumbers" render={(routeProps) => <DirectoryPhoneNumbersComponent {...this.props} />} />
                                <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                            </Switch>
                        </div>
                    </BrowserRouter>
                    : <div className="dgdg-flex-item dgdg-site-content">
                        <DirectoryComponent {...this.props} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />
                    </div>
            }
        </DGDGPageComponent>;
    }
}
