import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { BuyCenterManheimQueryComponent } from "./BuyCenterManheimQueryComponent";

export class BuyCenterManheimComponent extends React.Component {
    pageTitle = "Buy Center";

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableTitle: "",
            paintTable: false,
            isLoadingData: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onGetReportClick(event, input) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = "";
            this.props.applicationInsights.trackTrace({ message: "/BuyCenterManheimComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BuyCenter/GetManheimListingsReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        tableData: CommonService.addOrdinalColumn(JSON.parse(parsedResponse).listings),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BuyCenter/GetManheimListingsReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("BuyCenterManheimComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <BuyCenterManheimQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Manheim Listings" tablePageSize={CommonService.tablePageSize} tableData={this.state.tableData} paintTable={this.state.paintTable} stickyHeader
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 34, 3, 6, 37, 38, 39, 40, 41, 42, 43], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Channel" dataColumn="saleInformation.channel" sortColumn="saleInformation.channel" filterColumn="saleInformation.channel" />
                        <DGDGTableColumnComponent headerText="Sale Date" dataColumn="saleInformation.saleDate" sortColumn="saleInformation.saleDate" filterColumn="saleInformation.saleDate" dataType="date" />
                        <DGDGTableColumnComponent headerText="Auction Id" dataColumn="saleInformation.auctionId" sortColumn="saleInformation.auctionId" filterColumn="saleInformation.auctionId" />
                        <DGDGTableColumnComponent headerText="Sale Year" dataColumn="saleInformation.saleYear" sortColumn="saleInformation.saleYear" filterColumn="saleInformation.saleYear" dataType="date" />
                        <DGDGTableColumnComponent headerText="Auction Start Date" dataColumn="saleInformation.auctionStartDate" sortColumn="saleInformation.auctionStartDate" filterColumn="saleInformation.auctionStartDate" dataType="date" />
                        <DGDGTableColumnComponent headerText="Auction End Date" dataColumn="saleInformation.auctionEndDate" sortColumn="saleInformation.auctionEndDate" filterColumn="saleInformation.auctionEndDate" dataType="date" />
                        <DGDGTableColumnComponent headerText="Auction Location" dataColumn="saleInformation.auctionLocation" sortColumn="saleInformation.auctionLocation" filterColumn="saleInformation.auctionLocation" />
                        <DGDGTableColumnComponent headerText="Vehicle Sale URL" dataColumn="saleInformation.vehicleSaleURL" sortColumn="saleInformation.vehicleSaleURL" filterColumn="saleInformation.vehicleSaleURL" />
                        <DGDGTableColumnComponent headerText="Type Code" dataColumn="vehicleInformation.typeCode" sortColumn="vehicleInformation.typeCode" filterColumn="vehicleInformation.typeCode" />
                        <DGDGTableColumnComponent headerText="Year" dataColumn="vehicleInformation.year" sortColumn="vehicleInformation.year" filterColumn="vehicleInformation.year" />
                        <DGDGTableColumnComponent headerText="Model" dataColumn="vehicleInformation.model" sortColumn="vehicleInformation.model" filterColumn="vehicleInformation.model" />
                        <DGDGTableColumnComponent headerText="Seller Types" dataColumn="vehicleInformation.sellerTypes" sortColumn="vehicleInformation.sellerTypes" filterColumn="vehicleInformation.sellerTypes" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vehicleInformation.vin" sortColumn="vehicleInformation.vin" filterColumn="vehicleInformation.vin" />
                        <DGDGTableColumnComponent headerText="Adj Mmr" dataColumn="vehicleInformation.adjMmr" sortColumn="vehicleInformation.adjMmr" filterColumn="vehicleInformation.adjMmr" />
                        <DGDGTableColumnComponent headerText="Pickup Location" dataColumn="vehicleInformation.pickupLocation" sortColumn="vehicleInformation.pickupLocation" filterColumn="vehicleInformation.pickupLocation" />
                        <DGDGTableColumnComponent headerText="Pickup Location State" dataColumn="vehicleInformation.pickupLocationState" sortColumn="vehicleInformation.pickupLocationState" filterColumn="vehicleInformation.pickupLocationState" />
                        <DGDGTableColumnComponent headerText="Pickup Location Zip" dataColumn="vehicleInformation.pickupLocationZip" sortColumn="vehicleInformation.pickupLocationZip" filterColumn="vehicleInformation.pickupLocationZip" />
                        <DGDGTableColumnComponent headerText="Make" dataColumn="vehicleInformation.make" sortColumn="vehicleInformation.make" filterColumn="vehicleInformation.make" />
                        <DGDGTableColumnComponent headerText="Mileage" dataColumn="vehicleInformation.mileage" sortColumn="vehicleInformation.mileage" filterColumn="vehicleInformation.mileage" />
                        <DGDGTableColumnComponent headerText="Certified" dataColumn="vehicleInformation.certified" sortColumn="vehicleInformation.certified" filterColumn="vehicleInformation.certified" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Update Timestamp" dataColumn="vehicleInformation.updateTimestamp" sortColumn="vehicleInformation.updateTimestamp" filterColumn="vehicleInformation.updateTimestamp" />
                        <DGDGTableColumnComponent headerText="As Is" dataColumn="vehicleInformation.asIs" sortColumn="vehicleInformation.asIs" filterColumn="vehicleInformation.asIs" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Salvage" dataColumn="vehicleInformation.salvage" sortColumn="vehicleInformation.salvage" filterColumn="vehicleInformation.salvage" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Has Air Conditioning" dataColumn="vehicleInformation.hasAirConditioning" sortColumn="vehicleInformation.hasAirConditioning" filterColumn="vehicleInformation.hasAirConditioning" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Exterior Color" dataColumn="vehicleInformation.exteriorColor" sortColumn="vehicleInformation.exteriorColor" filterColumn="vehicleInformation.exteriorColor" />
                        <DGDGTableColumnComponent headerText="Title Status" dataColumn="vehicleInformation.titleStatus" sortColumn="vehicleInformation.titleStatus" filterColumn="vehicleInformation.titleStatus" />
                        <DGDGTableColumnComponent headerText="Title State" dataColumn="vehicleInformation.titleState" sortColumn="vehicleInformation.titleState" filterColumn="vehicleInformation.titleState" />
                        <DGDGTableColumnComponent headerText="Extended Model" dataColumn="vehicleInformation.extendedModel" sortColumn="vehicleInformation.extendedModel" filterColumn="vehicleInformation.extendedModel" />
                        <DGDGTableColumnComponent headerText="Location Zip" dataColumn="vehicleInformation.locationZip" sortColumn="vehicleInformation.locationZip" filterColumn="vehicleInformation.locationZip" />
                        <DGDGTableColumnComponent headerText="Comments" dataColumn="vehicleInformation.comments" sortColumn="vehicleInformation.comments" filterColumn="vehicleInformation.comments" maxLength="100" />
                        <DGDGTableColumnComponent headerText="Fuel Type" dataColumn="vehicleInformation.fuelType" sortColumn="vehicleInformation.fuelType" filterColumn="vehicleInformation.fuelType" />
                        <DGDGTableColumnComponent headerText="Frame Damage" dataColumn="vehicleInformation.frameDamage" sortColumn="vehicleInformation.frameDamage" filterColumn="vehicleInformation.frameDamage" />
                        <DGDGTableColumnComponent headerText="Prior Paint" dataColumn="vehicleInformation.priorPaint" sortColumn="vehicleInformation.priorPaint" filterColumn="vehicleInformation.priorPaint" />
                        <DGDGTableColumnComponent headerText="Buyer Group Id" dataColumn="vehicleInformation.buyerGroupId" sortColumn="vehicleInformation.buyerGroupId" filterColumn="vehicleInformation.buyerGroupId" />
                        <DGDGTableColumnComponent headerText="Pickup Region" dataColumn="vehicleInformation.pickupRegion" sortColumn="vehicleInformation.pickupRegion" filterColumn="vehicleInformation.pickupRegion" />
                        <DGDGTableColumnComponent headerText="Has Ecr" dataColumn="vehicleInformation.hasEcr" sortColumn="vehicleInformation.hasEcr" filterColumn="vehicleInformation.hasEcr" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Odometer Units" dataColumn="vehicleInformation.odometerUnits" sortColumn="vehicleInformation.odometerUnits" filterColumn="vehicleInformation.odometerUnits" />
                        <DGDGTableColumnComponent headerText="VDP URL" dataColumn="vehicleInformation.vdpURL" sortColumn="vehicleInformation.vdpURL" filterColumn="vehicleInformation.vdpURL" />
                        <DGDGTableColumnComponent headerText="Mobile VDP URL" dataColumn="vehicleInformation.mobileVdpURL" sortColumn="vehicleInformation.mobileVdpURL" filterColumn="vehicleInformation.mobileVdpURL" />
                        <DGDGTableColumnComponent headerText="Offsite Flag" dataColumn="vehicleInformation.offsiteFlag" sortColumn="vehicleInformation.offsiteFlag" filterColumn="vehicleInformation.offsiteFlag" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Buy Now Price" dataColumn="vehicleInformation.buyNowPrice" sortColumn="vehicleInformation.buyNowPrice" filterColumn="vehicleInformation.buyNowPrice" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Current Bid Price" dataColumn="vehicleInformation.currentBidPrice" sortColumn="vehicleInformation.currentBidPrice" filterColumn="vehicleInformation.currentBidPrice" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Seller Name" dataColumn="sellerInformation.sellerName" sortColumn="sellerInformation.sellerName" filterColumn="sellerInformation.sellerName" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
