import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class USWarrantyReconciliationJournalEntryDetailsComponent extends React.Component {
    constructor(props) {
        super(props);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog} title={this.props.tableTitle}
            cssName="dgdg-us-warranty-reconciliation-journal-entry-details-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} tableTitl={this.props.tableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="CDK Account Description" dataColumn="cdk_account_description" sortColumn="cdk_account_description" filterColumn="cdk_account_description" headerCssName="dgdg-us-warranty-reconciliation-acc-desc-col" footerText="Balance" />
                        <DGDGTableColumnComponent headerText="User Name" dataColumn="user_name" sortColumn="user_name" filterColumn="user_name" />
                        <DGDGTableColumnComponent headerText="Posting Date" dataColumn="posting_date_time" sortColumn="posting_date_time" filterColumn="posting_date_time" type="Date" headerCssName="dgdg-us-warranty-reconciliation-posting-date-col" />
                        <DGDGTableColumnComponent headerText="Txn Type" dataColumn="txn_type" sortColumn="txn_type" filterColumn="txn_type" />
                        <DGDGTableColumnComponent headerText="Posting Amount" dataColumn="posting_amount" sortColumn="posting_amount" filterColumn="posting_amount" dataType="accounting_amount" footerText={CommonService.formatCurrency_2(this.props.totalPostingAmount)} footerCssName={"text-right" + (this.props.totalPostingAmount < 0 ? " dgdg-font-red" : "")} />
                        <DGDGTableColumnComponent headerText="Control 1" dataColumn="control_1" sortColumn="control_1" filterColumn="control_1" />
                        <DGDGTableColumnComponent headerText="Control 2" dataColumn="control_2" sortColumn="control_2" filterColumn="control_2" />
                        <DGDGTableColumnComponent headerText="Refer" dataColumn="refer" sortColumn="refer" filterColumn="refer" />
                        <DGDGTableColumnComponent headerText="Detail Description" dataColumn="detail_description" sortColumn="detail_description" filterColumn="detail_description" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}
