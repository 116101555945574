import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { SalesManagerPayrollQueryComponent } from "./SalesManagerPayrollQueryComponent";
import { SalesManagerPayrollFinancialDetailComponent } from "./SalesManagerPayrollFinancialDetailComponent";

export class SalesManagerPayrollRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            isLoadingData: false,
            lastUpdatedDate: null,
            tableData: [],
            footerRow: null,
            months: CommonService.getMonths("12/01/2023"),
            paintTable: false,
            tableTitle: "",
            showFinancialDetail: false,
            financialDetailTitle: "",
            financialDetailTableData: [],
            financialDetailPaintTable: false,
            blobName: "",
            isDeveloper: false
        };

        this.onGetLastUpdatedDate = this.onGetLastUpdatedDate.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onNewCommGrossCellClick = this.onNewCommGrossCellClick.bind(this);
        this.onUsedCommGrossCellClick = this.onUsedCommGrossCellClick.bind(this);
        this.onFinancialDetailCancelClick = this.onFinancialDetailCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesManagerPayrollRouteComponent - componentDidMount");
            //this.getControllerPolicy();
            CommonService.clientAzureStorageLog("SalesManagerPayrollRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Manager Payroll";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesManagerPayrollRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesManagerPayroll/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesManagerPayrollRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };
            this.props.applicationInsights.trackTrace({ message: "/SalesManagerPayrollRouteComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesManagerPayroll/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth) {
        try {
            if (selectedMonth !== null) {
                this.setState({
                    isLoadingData: true,
                    paintTable: true,
                    selectedMonth: selectedMonth
                }, () => {
                    this.setState({ paintTable: false });
                });
                this.onGetLastUpdatedDate(selectedMonth);
                let inputData = {
                    month: CommonService.formatServerDateTime(selectedMonth)
                };
                this.props.applicationInsights.trackTrace({ message: "/SalesManagerPayrollRouteComponent/onGetPayrollClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/SalesManagerPayroll/GetSalesManagerPayroll", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = parsedResponse.filter((row) => { return row.store_number !== 99 && row.sub_store == null; });
                        let footerRow = parsedResponse.filter((row) => { return row.store_number === 99; })[0];
                        tableData.forEach((rowData) => {
                            this.updateRow(rowData);
                        });
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "Sales Stores - " + CommonService.formatMonthYear(moment(selectedMonth)),
                            tableData: CommonService.addOrdinalColumn(tableData),
                            footerRow: footerRow,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableData: [],
                            footerRow: null,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("SalesManagerPayrollRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    updateRow(rowData) {
        rowData.store_name_web = rowData.store_number + " | " + rowData.store_name;
    }

    onNewCommGrossCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                category: "SalesNewCommGross"
            };
            fetch("/SalesManagerPayroll/GetSalesManagerFinancialDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let amount = rowData.new_commissionable_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.new_commissionable_gross) + "</span>" : CommonService.formatCurrency_2(rowData.new_commissionable_gross);
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_name + " - New Comm Gross - " + amount,
                        financialDetailTableData: CommonService.addOrdinalColumn(parsedResponse.details),
                        showFinancialDetail: true,
                        financialDetailPaintTable: true,
                        blobName: parsedResponse.blobName + " (Blob Count: 1)",
                        isDeveloper: parsedResponse.isDeveloper
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: "",
                        financialDetailTableData: [],
                        showFinancialDetail: true,
                        financialDetailPaintTable: true
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUsedCommGrossCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                category: "SalesUsedCommGross"
            };
            fetch("/SalesManagerPayroll/GetSalesManagerFinancialDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let amount = rowData.used_commissionable_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.used_commissionable_gross) + "</span>" : CommonService.formatCurrency_2(rowData.used_commissionable_gross);
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_name + " - Used Comm Gross - " + amount,
                        financialDetailTableData: CommonService.addOrdinalColumn(parsedResponse.details),
                        showFinancialDetail: true,
                        financialDetailPaintTable: true,
                        blobName: parsedResponse.blobName + " (Blob Count: 1)",
                        isDeveloper: parsedResponse.isDeveloper
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: "",
                        financialDetailTableData: [],
                        showFinancialDetail: true,
                        financialDetailPaintTable: true
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFinancialDetailCancelClick(event) {
        this.setState({
            financialDetailTableData: [],
            showFinancialDetail: false,
        });
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <SalesManagerPayrollFinancialDetailComponent
                showDialog={this.state.showFinancialDetail}
                tableData={this.state.financialDetailTableData}
                tableTitle={this.state.financialDetailTitle}
                paintTable={this.state.financialDetailPaintTable}
                blobName={this.state.blobName}
                isDeveloper={this.state.isDeveloper}
                applicationInsights={this.props.applicationInsights}
                onCancelClick={this.onFinancialDetailCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
                onShowAlert={this.props.onShowAlert}
            />
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <SalesManagerPayrollQueryComponent
                        isLoadingData={this.state.isLoadingData}
                        months={this.state.months}
                        applicationInsights={this.props.applicationInsights}
                        onGetPayrollClick={this.onGetPayrollClick}
                    />
                    <div className="dgdg-site-scrollable-content">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-card-gutter" tableData={this.state.tableData} footerRow={this.state.footerRow} headerText={this.state.tableTitle} paintTable={this.state.paintTable} stickyHeader showFooter
                            tablePageSize={CommonService.tablePageSize} copyTableConfig={{
                                "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
                                onCopyTableCallBack: this.onCopyTableCallBack
                            }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_name_web" filterColumn="store_name_web" footerText="DGDG" cssName="text-nowrap" />
                                <DGDGTableColumnComponent headerText="New Car Count" dataColumn="new_car_count" sortColumn="new_car_count" filterColumn="new_car_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                                <DGDGTableColumnComponent headerText="Used Car Count" dataColumn="used_car_count" sortColumn="used_car_count" filterColumn="used_car_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                                <DGDGTableColumnComponent headerText="New Comm Gross" dataColumn="new_commissionable_gross" sortColumn="new_commissionable_gross" dataType="money" footerCssName="text-right" columnHelp="New Commissionable Gross: OEM Stmt New Car Gross including OEM incentives" footerFunction="sum" onCellClick={this.onNewCommGrossCellClick} />
                                <DGDGTableColumnComponent headerText="Used Comm Gross" dataColumn="used_commissionable_gross" sortColumn="used_commissionable_gross" dataType="money" footerCssName="text-right" columnHelp="Used Commissionable Gross: OEM Stmt Used Car Gross" footerFunction="sum" onCellClick={this.onUsedCommGrossCellClick} />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
