import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { AutoCheckVHRQueryComponent } from "./AutoCheckVHRQueryComponent";

export class AutoCheckVHRRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            tableTitle: "AutoCheck Vehicle",
            historyTableTitle: "AutoCheck Vehicle History",
            tableData: [],
            historyTableData: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("AutoCheckVHRRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/AutoCheckVHR", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("AutoCheckVHRRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "AutoCheck Vehicle History Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/AutoCheckVHRRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/AutoCheckVHR/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "AutoCheckVHRRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/AutoCheckVHR/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(vin) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                vin: vin
            };
            this.props.applicationInsights.trackTrace({ message: "/AutoCheckVHRRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/AutoCheckVHR/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.vindecode),
                        historyTableData: CommonService.addOrdinalColumn(parsedResponse.hdata),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        historyTableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/AutoCheckVHR/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("AutoCheckVHRRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <AutoCheckVHRQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    onGetReportClick={this.onGetReportClick}
                    isLoadingData={this.state.isLoadingData}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-card-gutter" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Owner Count" dataColumn="owneR_COUNT" sortColumn="owneR_COUNT" filterColumn="owneR_COUNT" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Accident Count" dataColumn="accidenT_COUNT" sortColumn="accidenT_COUNT" filterColumn="accidenT_COUNT" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="MFG" dataColumn="mfg" sortColumn="mfg" filterColumn="mfg" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Body" dataColumn="body" sortColumn="body" filterColumn="body" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Series Code" dataColumn="seriescode" sortColumn="seriescode" filterColumn="seriescode" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Class" dataColumn="class" sortColumn="class" filterColumn="class" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Engine" dataColumn="engine" sortColumn="engine" filterColumn="engine" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Result Message" dataColumn="resultmessage" sortColumn="resultmessage" filterColumn="resultmessage" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Country" dataColumn="country" sortColumn="country" filterColumn="country" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Result Code" dataColumn="resultcode" sortColumn="resultcode" filterColumn="resultcode" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="MFG Code" dataColumn="mfgcode" sortColumn="mfgcode" filterColumn="mfgcode" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="NEG Score Factor1" dataColumn="neG_SCORE_FACTOR1" sortColumn="neG_SCORE_FACTOR1" filterColumn="neG_SCORE_FACTOR1" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Score" dataColumn="score" sortColumn="score" filterColumn="score" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Age" dataColumn="age" sortColumn="age" filterColumn="age" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Compare Score Range High" dataColumn="comparE_SCORE_RANGE_HIGH" sortColumn="comparE_SCORE_RANGE_HIGH" filterColumn="comparE_SCORE_RANGE_HIGH" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Compare Score Range Low" dataColumn="comparE_SCORE_RANGE_LOW" sortColumn="comparE_SCORE_RANGE_LOW" filterColumn="comparE_SCORE_RANGE_LOW" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="COUNT" dataColumn="count" sortColumn="count" filterColumn="count" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Estimated_Average_Miles" dataColumn="estimateD_AVERAGE_MILES" sortColumn="estimateD_AVERAGE_MILES" filterColumn="estimateD_AVERAGE_MILES" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="recall Data Available" dataColumn="recalL_DATA_AVAILABLE" sortColumn="recalL_DATA_AVAILABLE" filterColumn="recalL_DATA_AVAILABLE" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Rollback" dataColumn="rollback" sortColumn="rollback" filterColumn="rollback" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Odometer" dataColumn="odometer" sortColumn="odometer" filterColumn="odometer" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Last Odometer" dataColumn="lasT_ODOMETER" sortColumn="lasT_ODOMETER" filterColumn="lasT_ODOMETER" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Mixed Odometer" dataColumn="mixeD_ODOMETER" sortColumn="mixeD_ODOMETER" filterColumn="mixeD_ODOMETER" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Recall COunt" dataColumn="recalL_COUNT" sortColumn="recalL_COUNT" filterColumn="recalL_COUNT" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="BBP" dataColumn="bbp" sortColumn="bbp" filterColumn="bbp" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Assured" dataColumn="assured" sortColumn="assured" filterColumn="assured" cssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.historyTableTitle} tableData={this.state.historyTableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="TxnNumber" dataColumn="actcode" sortColumn="actcode" filterColumn="actcode" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Date" dataColumn="date" sortColumn="date" filterColumn="date" dataType="date" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Category" dataColumn="category" sortColumn="category" filterColumn="category" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Odometer" dataColumn="odometer" sortColumn="odometer" filterColumn="odometer" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="State" dataColumn="state" sortColumn="state" filterColumn="state" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="UOM" dataColumn="uom" sortColumn="uom" filterColumn="uom" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" filterColumn="type" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="File Type" dataColumn="filetype" sortColumn="filetype" filterColumn="filetype" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="File Source" dataColumn="filesource" sortColumn="filesource" filterColumn="filesource" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="File Id" dataColumn="filesource" sortColumn="filesource" filterColumn="filesource" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="CheckList GRP" dataColumn="checklistgrp" sortColumn="checklistgrp" filterColumn="checklistgrp" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Lien" dataColumn="lien" sortColumn="lien" filterColumn="lien" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Lease" dataColumn="lease" sortColumn="lease" filterColumn="lease" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="City" dataColumn="city" sortColumn="city" filterColumn="city" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Case" dataColumn="case" sortColumn="case" filterColumn="case" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Accident Sequence Number" dataColumn="accidentseqnum" sortColumn="accidentseqnum" filterColumn="accidentseqnum" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Color" dataColumn="color" sortColumn="color" filterColumn="color" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Phone" dataColumn="phone" sortColumn="phone" filterColumn="phone" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="ORG Name" dataColumn="orG_NAME" sortColumn="orG_NAME" filterColumn="orG_NAME" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Recall URL" dataColumn="recalL_URL" sortColumn="recalL_URL" filterColumn="recalL_URL" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Recall Link Text" dataColumn="recalllinktxt" sortColumn="recalllinktxt" filterColumn="recalllinktxt" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Recall Code" dataColumn="recallcode" sortColumn="recallcode" filterColumn="recallcode" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Recall Text" dataColumn="recalltxt" sortColumn="recalltxt" filterColumn="recalltxt" cssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
