import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { PartsDetailReportComponent } from "./PartsDetailReportComponent";

export class PartsCustomerStoreSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableTitle: "",
            paintTable: false,
            isLoadingData: false,
            selectedMonth: null,
            rowData: null
        };

        this.onCustomerCellClick = this.onCustomerCellClick.bind(this);
        this.onCustomerCancelClick = this.onCustomerCancelClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.selectedMonth && this.props.rowData
                && (prevProps.selectedMonth !== this.props.selectedMonth
                    || prevProps.rowData !== this.props.rowData)) {
                this.getCustomerStoreSummary(this.props.selectedMonth, this.props.rowData.customer_number, this.props.rowData.customer_name);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getCustomerStoreSummary(selectedMonth, customerNumber, customerName) {
        this.setState({
            showSpinner: true,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
        let inputData = {
            startDate: selectedMonth,
            customerNumber: customerNumber
        };

        this.props.applicationInsights.trackTrace({ message: "/PartsCustomerStoreSummaryComponent/getCustomerStoreSummary", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/PartsReport/GetCustomerStoreSummary", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let tableTile = "Customer Summary by Store - " + customerName + " (" + customerNumber + ") - " + CommonService.formatMonthYear(moment(selectedMonth));
                this.setState({
                    showSpinner: false,
                    tableTitle: tableTile,
                    tableData: CommonService.addOrdinalColumn(parsedResponse),
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            })
            .catch(notOKResponse => {
                this.setState({
                    showSpinner: false,
                    tableTile: "",
                    tableData: [],
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/GetCustomerStoreSummary", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onCustomerCellClick(event, rowData) {
        try {
            this.setState({
                rowData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerCancelClick(event) {
        try {
            this.setState({ rowData: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                paintTable: true,
                tableData: [],
                tableTile: ""
            }, () => {
                this.setState({ paintTable: false });
                this.props.onCancelClick(event);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("PartsCustomerStoreSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.rowData && this.state.tableData.length > 0} title={this.state.tableTitle} cssName="dgdg-parts-customer-store-dialog" onCancelClick={this.onCancelClick}>
            <div className="dgdg-report-body">
                <PartsDetailReportComponent type="Customer"
                    applicationInsights={this.props.applicationInsights}
                    rowData={this.state.rowData} selectedMonth={this.props.selectedMonth}
                    onCancelClick={this.onCustomerCancelClick} onShowAlert={this.props.onShowAlert}
                />
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.tableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.state.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" onCellClick={this.onCustomerCellClick} sortColumn="store_name_web" filterColumn="store_name_web" cssName="text-nowrap" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Closed Date" dataType="date" dataColumn="closed_date" sortColumn="closed_date" filterColumn="closed_date" />
                        <DGDGTableColumnComponent headerText="Sale" dataColumn="sale" dataType="money" sortColumn="sale" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Cost" dataColumn="cost" dataType="money" sortColumn="cost" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Gross" dataColumn="gross" dataType="money" sortColumn="gross" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Gross Margin %" dataColumn="gross_margin_pct" dataType="percent" sortColumn="gross_margin_pct" />
                        <DGDGTableColumnComponent headerText="Credit" dataColumn="credit" dataType="money" sortColumn="credit" />
                        <DGDGTableColumnComponent headerText="Credit %" dataColumn="credit_pct" dataType="percent" sortColumn="credit_pct" />
                        <DGDGTableColumnComponent headerText="Sale Ytd" dataColumn="sale_ytd" dataType="money" sortColumn="sale_ytd" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Cost Ytd" dataColumn="cost_ytd" dataType="money" sortColumn="cost_ytd" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Gross Ytd" dataColumn="gross_ytd" dataType="money" sortColumn="gross_ytd" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Gross Margin %" dataColumn="gross_margin_pct_ytd" dataType="percent" sortColumn="gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="Credit Ytd" dataColumn="credit_ytd" dataType="money" sortColumn="credit_ytd" />
                        <DGDGTableColumnComponent headerText="Credit Ytd %" dataColumn="credit_pct_ytd" dataType="percent" sortColumn="credit_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Sale" dataColumn="py_sale" dataType="money" sortColumn="py_sale" />
                        <DGDGTableColumnComponent headerText="PY Cost" dataColumn="py_cost" dataType="money" sortColumn="py_cost" />
                        <DGDGTableColumnComponent headerText="PY Gross" dataColumn="py_gross" dataType="money" sortColumn="py_gross" />
                        <DGDGTableColumnComponent headerText="PY Gross Margin %" dataColumn="py_gross_margin_pct_ytd" dataType="percent" sortColumn="py_gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit" dataColumn="py_credit" dataType="money" sortColumn="py_credit" />
                        <DGDGTableColumnComponent headerText="PY Credit %" dataColumn="py_credit_pct" dataType="percent" sortColumn="py_credit_pct" />
                        <DGDGTableColumnComponent headerText="PY Sale Ytd" dataColumn="py_sale_ytd" dataType="money" sortColumn="py_sale_ytd" />
                        <DGDGTableColumnComponent headerText="PY Cost Ytd" dataColumn="py_cost_ytd" dataType="money" sortColumn="py_cost_ytd" />
                        <DGDGTableColumnComponent headerText="PY Gross Ytd" dataColumn="py_gross_ytd" dataType="money" sortColumn="py_gross_ytd" />
                        <DGDGTableColumnComponent headerText="PY Gross Margin %" dataColumn="py_gross_margin_pct_ytd" dataType="percent" sortColumn="py_gross_margin_pct_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit Ytd" dataColumn="py_credit_ytd" dataType="money" sortColumn="py_credit_ytd" />
                        <DGDGTableColumnComponent headerText="PY Credit Ytd %" dataColumn="py_credit_pct_ytd" dataType="percent" sortColumn="py_credit_pct_ytd" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}
