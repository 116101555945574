import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class ProductAdjustmentAddComponent extends React.Component {
    static cancelAdjusttTypes = [
        { key: "Cancel", value: "Cancel" },
        { key: "Adjust", value: "Adjust" }
    ];

    static bulkTypes = [
        { key: "Bulk", value: "Bulk" }
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ProductAdjustmentAddComponent - getDerivedStateFromProps");
            if (props.employees && state.employeeList.length === 0) {
                newState.employeeList = props.employees;
            }

            if (props.accountNumbers && state.selectedNewUsed !== "New/Used" && state.accountNumbers === null) {
                newState.accountNumbers = props.accountNumbers;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            isSaveEnabled: false,
            id: null,
            productAdjustmentDate: null,
            minAdjustmentDate: null,
            maxAdjustmentDate: null,
            selectedStore: null,
            bulk: false,
            dealNumber: "",
            isDealNumberDirty: false,
            salesDetails: null,
            productDealDate: null,
            employeeList: [],
            selectedEmployee: null,
            employeeNumber: "",
            employeeName: "",
            customerNumber: "",
            customerName: "",
            stockNumber: "",
            selectedNewUsed: "New/Used",
            accountNumbers: null,
            selectedAccountNumber: null,
            adjustTypes: ProductAdjustmentAddComponent.cancelAdjusttTypes,
            selectedAdjustType: null,
            amount: "",
            selectedComment: null,
            comments2: "",
            lastUpdatedBy: ""
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDealNumberChange = this.onDealNumberChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onNewUsedClick = this.onNewUsedClick.bind(this);
        this.onAccountNumberClick = this.onAccountNumberClick.bind(this);
        this.onAdjustTypeClick = this.onAdjustTypeClick.bind(this);
        this.onCommentsClick = this.onCommentsClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onSaveNewClick = this.onSaveNewClick.bind(this);
        this.onSaveCloseClick = this.onSaveCloseClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.rowData === null) {
                let productAdjustmentDate = this.state.productAdjustmentDate;
                let minAdjustmentDate = this.state.minAdjustmentDate;
                let maxAdjustmentDate = this.state.maxAdjustmentDate;

                if (prevProps.adjustmentDate !== this.props.adjustmentDate) {
                    productAdjustmentDate = moment().startOf("month");
                    minAdjustmentDate = moment().startOf("month");
                    maxAdjustmentDate = moment().endOf("month");

                    let previousMonthStart = moment().subtract(1, "month").startOf("month");
                    let currentMonthStart = moment().startOf("month");
                    let selectedMonth = moment(this.props.adjustmentDate).startOf("month");

                    if (previousMonthStart <= selectedMonth && selectedMonth < currentMonthStart) {
                        productAdjustmentDate = moment(this.props.adjustmentDate).endOf("month");
                        minAdjustmentDate = moment(this.props.adjustmentDate).startOf("month");
                        maxAdjustmentDate = moment(this.props.adjustmentDate).endOf("month");
                    }
                }

                if (this.state.id
                    || (!this.state.lastUpdatedBy && this.props.lastUpdatedBy)
                    || prevProps.adjustmentDate !== this.props.adjustmentDate) {
                    this.setState({
                        id: null,
                        lastUpdatedBy: this.props.lastUpdatedBy,
                        productAdjustmentDate: productAdjustmentDate,
                        minAdjustmentDate: minAdjustmentDate,
                        maxAdjustmentDate: maxAdjustmentDate
                    });
                }
            } else if (this.props.rowData !== prevProps.rowData) {
                let selectedStore = this.props.stores.filter(storeRow => {
                    return storeRow.store_number === this.props.rowData.store_number;
                });

                let selectedEmployee = null;
                if (this.props.rowData.employee_number) {
                    selectedEmployee = this.props.employees.filter(empRow => {
                        return empRow.employee_number === this.props.rowData.employee_number.replace("FI", "").replace("F", "");
                    });
                }

                let selectedAccountNumber = null;
                if (this.props.rowData.account_number) {
                    selectedAccountNumber = this.props.accountNumbers.filter(accountRow => {
                        return accountRow.display_name === this.props.rowData.account_number;
                    });
                }

                let selectedComment = null;
                if (this.props.rowData.comments) {
                    selectedComment = this.props.comments.filter(commentRow => {
                        return commentRow.Description === this.props.rowData.comments;
                    });
                }

                let adjustTypes = this.props.rowData.type === "Bulk" ? ProductAdjustmentAddComponent.bulkTypes : ProductAdjustmentAddComponent.cancelAdjusttTypes;
                let selectedAdjustType = adjustTypes.filter(adjustTypeRow => {
                    return adjustTypeRow.key === this.props.rowData.type;
                });

                this.filterAccountNumbers(this.props.rowData.new_used);

                this.setState({
                    id: this.props.rowData.id,
                    productAdjustmentDate: moment(this.props.rowData.adjustment_date),
                    selectedStore: selectedStore[0],
                    bulk: this.props.rowData.type === "Bulk",
                    dealNumber: this.props.rowData.deal_number ? this.props.rowData.deal_number : "",
                    productDealDate: this.props.rowData.deal_date ? moment(this.props.rowData.deal_date) : null,
                    selectedEmployee: selectedEmployee ? selectedEmployee[0] : null,
                    employeeNumber: selectedEmployee && selectedEmployee.length > 0 ? selectedEmployee[0].employee_number : this.props.rowData.employee_number,
                    employeeName: selectedEmployee && selectedEmployee.length > 0 ? selectedEmployee[0].employee_name : this.props.rowData.employee_name,
                    customerNumber: this.props.rowData.customer_number,
                    customerName: this.props.rowData.customer_name,
                    stockNumber: this.props.rowData.stock_number,
                    selectedNewUsed: this.props.rowData.new_used === null ? "New/Used" : this.props.rowData.new_used,
                    accountNumbers: this.props.accountNumbers,
                    selectedAccountNumber: selectedAccountNumber ? selectedAccountNumber[0] : null,
                    adjustTypes: adjustTypes,
                    selectedAdjustType: selectedAdjustType[0],
                    amount: this.props.rowData.amount,
                    selectedComment: selectedComment ? selectedComment[0] : null,
                    lastUpdatedBy: this.props.rowData.last_updated_by,
                    comments2: this.props.rowData.comments_2 ? this.props.rowData.comments_2 : ""
                }, this.onEmployeeChange);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Add Product Adjustment";

    validateForm() {
        let isSaveEnabled = false;
        if (this.state.bulk) {
            isSaveEnabled = this.state.productAdjustmentDate !== null && this.state.productAdjustmentDate !== ""
                && this.state.selectedStore !== null
                && this.state.selectedAdjustType !== null
                && this.state.selectedAccountNumber !== null
                && this.state.amount !== ""
                && this.state.lastUpdatedBy !== "";
        }
        else {
            isSaveEnabled = this.state.productAdjustmentDate !== null && this.state.productAdjustmentDate !== ""
                && this.state.selectedStore !== null
                && this.state.employeeNumber !== ""
                && this.state.productDealDate !== null && this.state.productDealDate !== ""
                && this.state.customerNumber !== "" && this.state.customerName !== ""
                && this.state.stockNumber !== ""
                && this.state.selectedAccountNumber !== null
                && this.state.selectedNewUsed !== "New/Used"
                && this.state.selectedAdjustType !== null
                && this.state.amount !== ""
                && this.state.lastUpdatedBy !== "";
        }

        this.setState({
            isSaveEnabled: isSaveEnabled
        });
    }

    setData() {
        if (!isNaN(parseInt(this.state.dealNumber)) && this.state.salesDetails) {
            let selectedEmployee = [];
            if (this.state.salesDetails.finance_mgr_number) {
                selectedEmployee = this.props.employees.filter(empRow => {
                    return empRow.employee_number === this.state.salesDetails.finance_mgr_number.replace("FI", "").replace("F", "");
                });
            }

            this.setState({
                productDealDate: this.state.salesDetails.contract_date ? moment(this.state.salesDetails.contract_date) : null,
                selectedEmployee: selectedEmployee[0],
                employeeNumber: selectedEmployee.length > 0 ? selectedEmployee[0].employee_number : this.state.salesDetails.finance_mgr_number,
                employeeName: selectedEmployee.length > 0 ? selectedEmployee[0].employee_name : this.state.salesDetails.finance_mgr_name,
                customerName: this.state.salesDetails.customer_name,
                customerNumber: this.state.salesDetails.customer_number,
                stockNumber: this.state.salesDetails.stock_number,
                selectedNewUsed: this.state.salesDetails.new_used_wholesale === null ? "New/Used" : this.state.salesDetails.new_used_wholesale
            }, this.filterAccountNumbers);
        } else {
            this.setState({
                productDealDate: null,
                employeeNumber: "",
                employeeName: "",
                customerName: "",
                selectedEmployee: null,
                customerNumber: "",
                stockNumber: "",
                selectedNewUsed: "New/Used",
                accountNumbers: null,
                selectedAccountNumber: null
            }, this.filterAccountNumbers);
        }
    }

    filterAccountNumbers(selectedNewUsed) {
        selectedNewUsed = selectedNewUsed === undefined ? this.state.selectedNewUsed : selectedNewUsed;
        let filteredAccountNumbers = null;
        if (selectedNewUsed && selectedNewUsed !== "New/Used") {
            filteredAccountNumbers = this.props.accountNumbers.filter(row => {
                return row.Type === selectedNewUsed;
            });
        } else {
            filteredAccountNumbers = this.props.accountNumbers;
        }

        this.setState({
            accountNumbers: filteredAccountNumbers
        }, this.validateForm);
    }

    getSalesDetails() {
        if (isNaN(parseInt(this.state.dealNumber)) || this.state.selectedStore === null) {
            this.setState({
                salesDetails: null
            }, this.setData);
        }
        else {
            let inputData = {
                storeNumber: parseInt(this.state.selectedStore.store_number),
                dealNumber: parseInt(this.state.dealNumber)
            };
            this.props.applicationInsights.trackTrace({ message: "/ProductAdjustmentAddComponent/getSalesDetails", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ProductReserveAdjustments/GetSalesDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        salesDetails: parsedResponse[0],
                        isDealNumberDirty: false
                    }, this.setData);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ProductReserveAdjustments/GetSalesDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({
                selectedStore: store,
                selectedEmployee: null,
                employeeNumber: "",
                employeeName: ""
            }, this.onEmployeeChange);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAccountNumberClick(event, accountNumber) {
        try {
            this.setState({
                selectedAccountNumber: accountNumber
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCommentsClick(event, comment) {
        try {
            this.setState({
                selectedComment: comment
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeChange(event, filterValue) {
        try {
            let filteredData = [];
            if (this.props.employees) {
                if (this.state.selectedStore !== null && filterValue === undefined) {
                    filteredData = this.props.employees.filter(empProdRow => {
                        return empProdRow.store_number === this.state.selectedStore.store_number;
                    });

                    this.setState({
                        employeeList: filteredData
                    }, this.validateForm);
                }

                else {
                    if (this.state.selectedStore !== null) {
                        filteredData = this.props.employees.filter(empProdRow => {
                            return empProdRow.store_number === this.state.selectedStore.store_number
                                && (empProdRow.employee_last.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                                    || empProdRow.employee_first.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                                    || empProdRow.employee_number.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
                        });
                    }

                    else {
                        filteredData = this.props.employees.filter(empProdRow => {
                            return empProdRow.employee_last.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                                || empProdRow.employee_first.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                                || empProdRow.employee_number.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
                        });
                    }

                    this.setState({
                        employeeName: filterValue,
                        employeeList: filteredData
                    }, this.validateForm);
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeClick(event, employee) {
        try {
            if (employee.employee_name) {
                this.setState({
                    selectedEmployee: employee,
                    employeeNumber: employee.employee_number,
                    employeeName: employee.employee_name
                }, this.validateForm);
            } else {
                this.onEmployeeChange(event, employee);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.getSalesDetails);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value)
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberChange(id, value) {
        try {
            let employeeNumber = this.state.employeeNumber;
            if (value === "" && !this.state.employeeName) {
                employeeNumber = "";
            }

            this.setState({
                [id]: value,
                employeeNumber: employeeNumber,
                isDealNumberDirty: value !== ""
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChanged(id, value) {
        try {
            let adjustTypes = this.state.adjustTypes;
            let selectedAdjustType = this.state.selectedAdjustType;

            if (id === "bulk") {
                if (value) {
                    adjustTypes = ProductAdjustmentAddComponent.bulkTypes;
                    selectedAdjustType = ProductAdjustmentAddComponent.bulkTypes[0];
                } else {
                    adjustTypes = ProductAdjustmentAddComponent.cancelAdjusttTypes;
                    selectedAdjustType = null;
                }
            }

            this.setState({
                [id]: value,
                adjustTypes: adjustTypes,
                selectedAdjustType: selectedAdjustType
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onNewUsedClick(id, selectedNewUsed) {
        try {
            this.setState({
                selectedNewUsed: selectedNewUsed,
                selectedAccountNumber: null
            }, this.filterAccountNumbers);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustTypeClick(id, selectedAdjustType) {
        try {
            this.setState({
                selectedAdjustType: selectedAdjustType
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveNewClick(event) {
        this.onSaveClick(event, ".SaveNew");
        this.setState({
            selectedAccountNumber: null,
            amount: ""
        }, this.validateForm)
    }

    onSaveCloseClick(event) {
        this.onSaveClick(event, ".SaveClose");
        this.onCancelClick(event);
    }

    onSaveClick(event, buttonName) {
        try {
            if (this.state.bulk) {
                this.props.onSaveClick(event, {
                    id: this.state.id,
                    adjustmentDate: CommonService.formatServerDateTime(this.state.productAdjustmentDate),
                    storeNumber: this.state.selectedStore.store_number,
                    accountNumber: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.AccountNumber : null,
                    accountDescription: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.Description : null,
                    adjustType: this.state.selectedAdjustType.key,
                    amount: this.state.amount,
                    lastUpdatedBy: this.state.lastUpdatedBy,
                    comments: this.state.selectedComment ? this.state.selectedComment.Description : "",
                    comments2: this.state.comments2,
                    buttonName: buttonName
                });
            }
            else {
                this.props.onSaveClick(event, {
                    id: this.state.id,
                    adjustmentDate: CommonService.formatServerDateTime(this.state.productAdjustmentDate),
                    storeNumber: this.state.selectedStore.store_number,
                    employeeNumber: this.state.selectedEmployee ? this.state.selectedEmployee.employee_number : this.state.salesDetails.finance_mgr_number ? this.state.salesDetails.finance_mgr_number : null,
                    employeeName: this.state.selectedEmployee ? this.state.selectedEmployee.employee_first + " " + this.state.selectedEmployee.employee_last : this.state.salesDetails.finance_mgr_name ? this.state.salesDetails.finance_mgr_name.replace(",", " ") : null,
                    dealDate: this.state.productDealDate ? CommonService.formatServerDateTime(this.state.productDealDate) : null,
                    dealNumber: this.state.dealNumber,
                    customerNumber: this.state.customerNumber,
                    customerName: this.state.customerName,
                    stockNumber: this.state.stockNumber,
                    newUsed: this.state.selectedNewUsed === "New/Used" ? null : this.state.selectedNewUsed,
                    accountNumber: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.AccountNumber : null,
                    accountDescription: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.Description : null,
                    adjustType: this.state.selectedAdjustType.key,
                    amount: this.state.amount,
                    lastUpdatedBy: this.state.lastUpdatedBy,
                    comments: this.state.selectedComment ? this.state.selectedComment.Description : "",
                    comments2: this.state.comments2,
                    buttonName: buttonName
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                bulk: false,
                dealNumber: "",
                productDealDate: null,
                employeeNumber: "",
                employeeName: "",
                selectedEmployee: null,
                customerNumber: "",
                customerName: "",
                stockNumber: "",
                selectedNewUsed: "New/Used",
                accountNumbers: null,
                selectedAccountNumber: null,
                selectedAdjustType: null,
                lastUpdatedBy: "",
                amount: "",
                selectedComment: null,
                comments2: "",
                isSaveEnabled: false
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-product-reserve-add-dialog"
            title={this.props.title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveNewClick} disabled={!this.state.isSaveEnabled || this.state.isDealNumberDirty}>
                    Save & New  <i className="fas fa-save dgdg-icon" />
                </button>
                <button className="btn btn-primary" onClick={this.onSaveCloseClick} disabled={!this.state.isSaveEnabled || this.state.isDealNumberDirty} style={{ "marginLeft": "15px" }}>
                    Save & Close  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-product-reserve-frontend-adjustment-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Adjustment Date:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="productAdjustmentDate" onDateChange={this.onDateChange}
                                            value={this.state.productAdjustmentDate}
                                            minDate={this.state.minAdjustmentDate ? this.state.minAdjustmentDate.format("MM/DD/YYYY") : this.state.minAdjustmentDate}
                                            maxDate={this.state.maxAdjustmentDate ? this.state.maxAdjustmentDate.format("MM/DD/YYYY") : this.state.maxAdjustmentDate}
                                        />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Store Name:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormDropdownComponent data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Bulk:
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormCheckboxComponent disabled={this.state.selectedStore === null} id="bulk" value={this.state.bulk} onChange={this.onCheckChanged} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Deal Number:
                                    </td>
                                    <td />
                                    <td className="dgdg-product-reserve-column-width">
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null || this.state.bulk} id="dealNumber" value={this.state.dealNumber} onChange={this.onDealNumberChange} inputType="number" onLookupClick={this.onLookupClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        Deal Date:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} disabled={this.state.dealNumber !== "" || this.state.selectedStore === null || this.state.bulk} id="productDealDate"
                                            value={this.state.productDealDate}
                                            maxDate={moment().format("MM/DD/YYYY")}
                                            onDateChange={this.onDateChange}
                                        />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        F&I Manager:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td className="dgdg-product-reserve-column-width">
                                        <DGDGFormComboBoxComponent disabled={this.state.dealNumber !== "" || this.state.selectedStore === null || this.state.bulk} id="employee" data={this.state.employeeList} itemKey="employee_name" value={this.state.employeeName} onItemClick={this.onEmployeeClick} autoDrop />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue dgdg-product-reserve-label-width">
                                        Customer Number:<span className="text-danger dgdg-product-reserve-label-width">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.dealNumber !== "" || this.state.selectedStore === null || this.state.bulk} id="customerNumber" value={this.state.customerNumber} onChange={this.onTextChange} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        Customer Name:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.dealNumber !== "" || this.state.selectedStore === null || this.state.bulk} id="customerName" value={this.state.customerName} onChange={this.onTextChange} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        Stock #:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.dealNumber !== "" || this.state.selectedStore === null || this.state.bulk} id="stockNumber" value={this.state.stockNumber} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        New/Used:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.dealNumber !== "" || this.state.selectedStore === null} data={["New", "Used"]} value={this.state.selectedNewUsed} onItemClick={this.onNewUsedClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-product-reserve-label-width">
                                        Account Number:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.selectedStore === null} data={this.state.accountNumbers} itemKey="display_name" value={this.state.selectedAccountNumber ? this.state.selectedAccountNumber.display_name : "Account Number"} onItemClick={this.onAccountNumberClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        Adjust Type:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.selectedStore === null || this.state.bulk} data={this.state.adjustTypes} itemKey="value" value={this.state.selectedAdjustType ? this.state.selectedAdjustType.value : "Adjust Type"} onItemClick={this.onAdjustTypeClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Amount:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null} id="amount" value={this.state.amount} onChange={this.onTextChange} inputType="money" />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue">
                                        Comments:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.selectedStore === null} data={this.props.comments} itemKey="Description" value={this.state.selectedComment ? this.state.selectedComment.Description : "Select Comment"} onItemClick={this.onCommentsClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-product-reserve-label-width">
                                        Last Updated By:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="lastUpdatedBy" value={this.state.lastUpdatedBy} disabled readOnly />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Comments #2:
                                    </td>
                                    <td />
                                    <td colSpan="9">
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null} id="comments2" value={this.state.comments2} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
