import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

export class GLReconciliationAdjustmentDetailComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Product & Reserve Adjustments";

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("GLReconciliationAdjustmentDetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.tableData.length > 0}
            title={this.props.tableTitle}
            cssName="dgdg-product-reserve-account-detail-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showFooter={true}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Adjustment Date" dataColumn="adjustment_date" sortColumn="adjustment_date" filterColumn="adjustment_date" dataType="date" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Dealer Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="adjustment_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerFunction="sum" footerCssName="text-right" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}
