import React from "react";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableFooterComponent } from "../common/dgdgtable/DGDGTableFooterComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ReserveAdjustmentReportQueryComponent } from "./ReserveAdjustmentReportQueryComponent";

export class ReserveAdjustmentReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            showDialog: false,
            paintTable: false,
            tableTitle: "",
            newAccountSummaryTableTitle: "",
            usedAccountSummaryTableTitle: "",
            allAccountSummaryTableTitle: "",
            totalAdjustmentAmount: null,
            tableData: [],
            newAccountSummaryTableData: [],
            usedAccountSummaryTableData: [],
            allAccountSummaryTableData: []
        };

        this.onGetDetailsClick = this.onGetDetailsClick.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Product & Reserve Adjustments";

    componentDidMount() {
        try {
            console.devLog("ReserveAdjustmentReportComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ProductReserveAdjustments/ReserveAdjustmentReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetDetailsClick(selectedMonth, selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                startDate: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/ReserveAdjustmentReportComponent/onGetDetailsClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ProductReserveAdjustments/GetReserveAdjustmentReportDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalNewAccountSummary = 0, totalUsedAccountSummary = 0, totalOtherAccountSummary = 0, totalAdjustmentAmount = 0;
                    let newAccountSummaryTableData = parsedResponse.table1.filter(item => item.new_used === "New");
                    newAccountSummaryTableData.forEach((newAccountSummaryItem, index) => {
                        totalNewAccountSummary += newAccountSummaryItem.amount;
                    });

                    let usedAccountSummaryTableData = parsedResponse.table1.filter(item => item.new_used === "Used");
                    usedAccountSummaryTableData.forEach((usedAccountSummaryItem, index) => {
                        totalUsedAccountSummary += usedAccountSummaryItem.amount;
                    });

                    parsedResponse.table1.filter(item => item.new_used !== "New" && item.new_used !== "Used").forEach((otherAccountSummaryItem, index) => {
                        totalOtherAccountSummary += otherAccountSummaryItem.amount;
                    });

                    parsedResponse.table.forEach((responseItem, index) => {
                        totalAdjustmentAmount += responseItem.new_cb + responseItem.new_adj + responseItem.used_cb + responseItem.used_adj + responseItem.cpo_cb
                            + responseItem.cpo_adj + responseItem.new_acq + responseItem.other;
                    });

                    let allAccountSummaryTableData = [];
                    allAccountSummaryTableData.push({ display_name: "New Account Summary", amount: totalNewAccountSummary });
                    allAccountSummaryTableData.push({ display_name: "Used Account Summary", amount: totalUsedAccountSummary });
                    allAccountSummaryTableData.push({ display_name: "Other", amount: totalOtherAccountSummary });

                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        totalAdjustmentAmount: totalAdjustmentAmount,
                        newAccountSummaryTableData: CommonService.addOrdinalColumn(newAccountSummaryTableData),
                        usedAccountSummaryTableData: CommonService.addOrdinalColumn(usedAccountSummaryTableData),
                        allAccountSummaryTableData: CommonService.addOrdinalColumn(allAccountSummaryTableData),
                        tableTitle: "Reserve Adjustment Detail - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        newAccountSummaryTableTitle: "New Account Summary - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        usedAccountSummaryTableTitle: "Used Account Summary - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        allAccountSummaryTableTitle: "Account Summary - " + CommonService.formatMonthYear(new Date(selectedMonth))
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableData: [],
                        newAccountSummaryTableData: [],
                        usedAccountSummaryTableData: [],
                        allAccountSummaryTableData: [],
                        tableTitle: "",
                        newAccountSummaryTableTitle: "",
                        usedAccountSummaryTableTitle: "",
                        allAccountSummaryTableTitle: ""
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ProductReserveAdjustments/GetReserveAdjustmentReportDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {
        try {
            let inputData = {
                tabName: ".ReserveAdjustments"
            };
            this.props.applicationInsights.trackTrace({ message: "/ReserveAdjustmentReportComponent/onPrintClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ProductReserveAdjustments/PrintClick", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<div class="table-title">' + this.state.tableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-product-reserve-print-content  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.newAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-product-reserve-print-content-new-summary  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.usedAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-product-reserve-print-content-used-summary  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.allAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-product-reserve-print-content-all-summary  .dgdg-main-table").html() + '</table>';
                    CommonService.showPrint(this.state.tableTitle, printContent);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ProductReserveAdjustments/onPrintClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ReserveAdjustmentReportComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ReserveAdjustmentReportQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                stores={this.props.stores} months={this.props.months}
                onGetDetailsClick={this.onGetDetailsClick} onPrintClick={this.onPrintClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-product-reserve-print-content dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" filterColumn="type" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Employee" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="New/Used" dataColumn="new_used" sortColumn="new_used" filterColumn="new_used" />
                            <DGDGTableColumnComponent headerText="New CB" dataColumn="new_cb" sortColumn="new_cb" dataType="money_2" cssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="New ADJ" dataColumn="new_adj" sortColumn="new_adj" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="Used CB" dataColumn="used_cb" sortColumn="used_cb" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="Used ADJ" dataColumn="used_adj" sortColumn="used_adj" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="CPO CB" dataColumn="cpo_cb" sortColumn="cpo_cb" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="CPO ADJ" dataColumn="cpo_adj" sortColumn="cpo_adj" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="New ACQ" dataColumn="new_acq" sortColumn="new_acq" dataType="money_2" headerCssName="dgdg-width-50" />
                            <DGDGTableColumnComponent headerText="Write-Off" dataColumn="other" sortColumn="other" dataType="money_2" />
                        </DGDGTableRowComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Done By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" colSpan="2" showHeader />
                            <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" colSpan="4" showHeader />
                            <DGDGTableColumnComponent headerText="Comments #" dataColumn="comments_2" sortColumn="comments_2" filterColumn="comments_2" colSpan="4" showHeader />
                        </DGDGTableRowComponent>
                        <DGDGTableFooterComponent position="PostFooter">
                            <tr className="font-weight-bold dgdg-product-reserve-bg-total-row">
                                <td colSpan="16" className="text-right ui-widget">Grand Total :&nbsp;&nbsp;&nbsp;&nbsp;{CommonService.formatCurrency_2(this.state.totalAdjustmentAmount)}</td>
                            </tr>
                        </DGDGTableFooterComponent>
                    </DGDGTableV3Component>
                </div>
                <div className="dgdg-flex">
                    <div className="dgdg-product-reserve-print-content-new-summary">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.newAccountSummaryTableTitle} tableData={this.state.newAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Account Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-product-reserve-print-content-used-summary dgdg-icon">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.usedAccountSummaryTableTitle} tableData={this.state.usedAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Account Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-product-reserve-print-content-all-summary dgdg-icon">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.allAccountSummaryTableTitle} tableData={this.state.allAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Grand Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </div>;
    }
}
