import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGInputFileComponent } from "../common/dgdginputfile/DGDGInputFileComponent";

export class ExcelReportsTemplateComponent extends React.Component {
    constructor(props) {
        super(props);

        let startDate = moment().startOf('month');
        let endDate = moment();
        this.state = {
            selectedReport: null,
            startDate: startDate,
            endDate: endDate,
            isUploading: false
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        this.onReportClick = this.onReportClick.bind(this);
        this.onUploadTemplate = this.onUploadTemplate.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.paintTable) {
                this.setState({ paintTable: false });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Excel Reports";

    componentDidMount() {
        try {
            console.devLog("ExcelReportsRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ExcelReports/Template", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("ExcelReportsRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    reportNames = [
        { key: "Lead View", value: "Lead View" }
    ]

    onReportClick(id, report) {
        try {
            this.setState({ selectedReport: report });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUploadTemplate(event, selectedReport) {
        try {
            this.setState({ isUploading: true });
            let fileUpload = $('#browseFile')[0];
            var formData = new FormData();
            formData.append("FileUpload", fileUpload.files[0]);
            formData.append("ReportName", selectedReport.value.replace(" ", ""),);

            this.props.applicationInsights.trackTrace({ message: "/ExcelReportsRouteComponent/onUploadTemplate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ExcelReports/UploadTemplate", {
                method: "POST",
                headers: {
                    'enctype': "multipart/form-data"
                },
                body: formData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isUploading: false });
                    this.props.onShowAlert("success", { message: selectedReport.value + " Template has been uploaded." }, 4000);
                })
                .catch(notOKResponse => {
                    this.setState({ isUploading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ExcelReports/onUploadTemplate", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                this.setState({ isUploading: false });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-block">
                        <div className="form-inline">
                            <DGDGFormDropdownComponent label="Report" data={this.reportNames} itemKey="key" value={this.state.selectedReport ? this.state.selectedReport.value : "Select Report"} onItemClick={this.onReportClick} onKeyDown={this.onKeyDown} />
                            <DGDGInputFileComponent id="browseFile" accept=".xlsx" onFileChange={(event) => this.onUploadTemplate(event, this.state.selectedReport)} disabled={this.state.selectedReport == null} />
                            <DGDGSpinnerComponent showSpinner={this.state.isUploading} />
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
