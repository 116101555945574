import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DirectoryKeypadComponent } from "./DirectoryKeypadComponent";
import { DirectoryQueryComponent } from "./DirectoryQueryComponent";

export class DirectoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            calleeData: null,
            callerData: null,
            showCallDialog: false,
            users: []
        };

        this.onLookupClick = this.onLookupClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionCallClick = this.onActionCallClick.bind(this);
        this.onConfirmCallClick = this.onConfirmCallClick.bind(this);
        this.onCancelCallClick = this.onCancelCallClick.bind(this);
        this.onDNDSelectChange = this.onDNDSelectChange.bind(this);
        this.onCallClick = this.onCallClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DirectoryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Directory/Directory", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.onLookupClick();
            CommonService.clientAzureStorageLog("DirectoryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Directory";
    legendText = <Fragment>
        <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-blue" />&nbsp;&nbsp;&nbsp;Office</div>
        <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-yellow" />&nbsp;&nbsp;&nbsp;Department</div>
        <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-green" />&nbsp;&nbsp;&nbsp;Room</div>
        <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-white" />&nbsp;&nbsp;&nbsp;User</div>
    </Fragment>;

    setRowColor(rowData) {
        switch (rowData.Type) {
            case "Office":
                rowData.rowCssName = "dgdg-directory-bg-blue";
                rowData.Name_colSpan = 3;
                break;
            case "Department":
                rowData.rowCssName = "dgdg-directory-bg-yellow";
                rowData.Name_colSpan = 3;
                break;
            case "Room":
                rowData.rowCssName = "dgdg-directory-bg-green";
                rowData.Name_colSpan = 3;
                break;
            default:
                rowData.rowCssName = null;
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DirectoryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        let callerData = "";
        try {
            let inputData = {
                filterValue: value,
            };
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "DirectoryComponent/onLookupClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/GetUsers", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.directory;
                    callerData = parsedResponse.currentUser;
                    tableData.forEach((responseItem, index) => {
                        responseItem.disable_action = false;
                        responseItem.dnd_label = "Off";
                        responseItem.dnd_label2 = "On";
                        responseItem.disable_dnd = true;
                        responseItem.Extension = responseItem.Extension ? "Ext. " + responseItem.Extension : "";
                        responseItem.display_name = responseItem.Name + (responseItem.PhoneNumber ? " " + responseItem.PhoneNumber[0] : "");
                        if (responseItem.Email?.indexOf(parsedResponse.remoteUpn) > -1) {
                            callerData = responseItem;
                            responseItem.disable_action = true;
                        }

                        if (responseItem.PhoneNumber?.length > 0) {
                            let formatedPhoneNumber = responseItem.PhoneNumber[0].replaceAll("-", "");
                            formatedPhoneNumber = "(" + formatedPhoneNumber.substring(0, 3) + ") " + formatedPhoneNumber.substring(3, 6) + "-" + formatedPhoneNumber.substring(6, 10)
                            responseItem.formatted_phone_number = formatedPhoneNumber;
                        }
                        this.setRowColor(responseItem);
                    });
                    if (CommonService.isDateValid(parsedResponse.lastUpdatedDate)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse.lastUpdatedDate));
                    }
                    this.setState({
                        tableData: CommonService.addOrdinalColumn(CommonService.sortJsonData(tableData, "Name", "Asc")),
                        users: tableData,
                        callerData: callerData,
                        paintTable: true,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        users: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/GetUsers", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionCallClick(event, rowData)} disabled={!this.state.callerData || rowData.disable_action}>
                <span className="fas fa-phone" />
            </button>
        </Fragment>;
    }

    onActionCallClick(event, calleeData) {
        try {
            this.setState({
                calleeData: calleeData,
                showCallDialog: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmCallClick(dialNumber) {
        try {
            dialNumber = dialNumber.split(" ");
            if (dialNumber.length > 0) {
                let inputData = {
                    custom_data: "Test Call",
                    user_id: this.state.props.Id,
                    phone_number: "+1" + dialNumber[dialNumber.length - 1].replaceAll("-", ""),
                    outbound_caller_id: "+1" + this.state.props.PhoneNumber[0].replaceAll("-", ""),
                };
                this.setState({ isLoadingData: true });
                this.props.applicationInsights.trackTrace({ message: "DirectoryComponent/onConfirmCallClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/Directory/InitiateCall", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({
                            isLoadingData: false
                        });
                        this.onCancelCallClick();
                    })
                    .catch(notOKResponse => {
                        this.setState({ isLoadingData: false });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/onConfirmCallClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelCallClick() {
        this.setState({
            calleeData: null,
            showCallDialog: false
        });
    }

    onDNDSelectChange(id, isChecked, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.DND_showSpinner = true;
            rowData.DND = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                user_id: rowData.Id,
                do_not_disturb: rowData.DND,
                group_id: rowData.Id,/*TODO: DepartmentId */
                group_type: "department",
            };
            this.props.applicationInsights.trackTrace({ message: "DirectoryComponent/onDNDSelectChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/UpdateUserToggleDeptDND", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.DND_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.DND_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/UpdateUserToggleDeptDND", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCallClick(event) {
        this.setState({ showCallDialog: true });
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DirectoryKeypadComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showCallDialog}
                users={this.state.users}
                callerData={this.state.callerData}
                calleeData={this.state.calleeData}
                onConfirmCallClick={this.onConfirmCallClick}
                onCancelCallClick={this.onCancelCallClick}
            />
            <DirectoryQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                isCallDisabled={this.state.callerData ? false : true}
                onLookupClick={this.onLookupClick}
                onCallClick={this.onCallClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-flex dgdg-flex-row" style={{ "minWidth": "1250px" }}>
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Users" tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader legendText={this.legendText}
                        copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 10, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-directory-bg-white" />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="dgdg-directory-action-column dgdg-directory-bg-white" dataColumn="showSpinner" showSpinner />
                            <DGDGTableColumnComponent headerText="Store" dataColumn="StoreName" sortColumn="StoreName" filterColumn="StoreName" />
                            <DGDGTableColumnComponent headerText="Name" dataColumn="Name" sortColumn="Name" filterColumn="Name" />
                            <DGDGTableColumnComponent headerText="Title" dataColumn="Title" sortColumn="Title" filterColumn="Title" />
                            <DGDGTableColumnComponent headerText="Email" dataColumn="Email" sortColumn="Email" filterColumn="Email" />
                            <DGDGTableColumnComponent headerText="Phone Numbers" dataColumn="PhoneNumber" sortColumn="PhoneNumber" filterColumn="PhoneNumber" ssName="dgdg-directory-phone" />
                            <DGDGTableColumnComponent headerText="Extension" dataColumn="Extension" sortColumn="Extension" filterColumn="Extension" />
                            <DGDGTableColumnCheckboxV2Component headerText="DND" dataColumn="DND" sortColumn="DND" dataType="bool" labelColumn="dnd_label" label2Column="dnd_label2" cssName="dgdg-directory-dnd" disabledColumn="disable_dnd" onChange={this.onDNDSelectChange} showSpinner />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="StatusMessage" sortColumn="StatusMessage" filterColumn="StatusMessage" />
                            <DGDGTableColumnComponent headerText="Phone Numbers Formatted" dataColumn="formatted_phone_number" isHidden />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <div className="card dgdg-box-shadow dgdg-directory-help-card">
                        <div className="card-header">
                            Dialpad Help
                        </div>
                        <div className="card-block dgdg-directory-help-card-block">
                            <a className="btn btn-info" href="https://www.dialpad.com/download/" target="_blank" rel="noreferrer" style={{ marginTop: "5px" }}>
                                Download Dialpad App, Dialpad Meetings <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="/Directory/PolycomPhoneManual" target="_blank" rel="noreferrer">
                                Polycom Phone Manual <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://www.youtube.com/watch?v=cGg3LNPRwTw" target="_blank" rel="noreferrer">
                                Dialpad 101 | Get Started with Your Business Phone - YouTube <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/115003414126-Tour-the-Dialpad-App" target="_blank" rel="noreferrer">
                                Tour the Dialpad App <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/210482063-Your-Dialpad-Profile" target="_blank" rel="noreferrer">
                                Your Dialpad Profile <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/218351163-Manage-Your-Voicemail" target="_blank" rel="noreferrer">
                                Manage Your Voicemail <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/115001002063-Transfer-a-Call" target="_blank" rel="noreferrer">
                                Transfer a Call <i className="fas fa-link dgdg-icon" />
                            </a>
                            <a className="btn btn-info dgdg-icon" href="https://help.dialpad.com/hc/en-us/articles/208803206-Add-a-Caller" target="_blank" rel="noreferrer">
                                Add a Caller <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/210394843-Place-a-Call-on-Hold" target="_blank" rel="noreferrer">
                                Place Call on Hold <i className="fas fa-link dgdg-icon" />
                            </a>
                            <a className="btn btn-info dgdg-icon" href="https://help.dialpad.com/hc/en-us/articles/115005156483-Park-a-Call-on-Your-Main-Line" target="_blank" rel="noreferrer">
                                Park a Call <i className="fas fa-link dgdg-icon" />
                            </a>
                            <br />
                            <a className="btn btn-info" href="https://help.dialpad.com/hc/en-us/articles/115004819906-Use-your-Polycom-Phone-#guest-login-(hotdesking)" target="_blank" rel="noreferrer" style={{ marginBottom: "5px" }}>
                                Guest Login (Hotdesking) <i className="fas fa-link dgdg-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
