import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export class DemoPageView4Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            embedParams: null
        }

        this.embedPowerBIReport = this.embedPowerBIReport.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DemoPageView4Component - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DemoPage/View4", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.embedPowerBIReport();
            CommonService.clientAzureStorageLog("DemoPageRouteComponent", "DemoPageView4Component", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Demo Page";

    embedPowerBIReport(event) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView2Component/embedPowerBIReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/EmbedPowerBIReport")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        embedParams: JSON.parse(parsedResponse)
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPage/EmbedPowerBIReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-block">
                        <PowerBIEmbed
                            embedConfig={
                                {
                                    type: 'report',
                                    id: this.state.embedParams?.EmbedReport[0].ReportId,
                                    embedUrl: this.state.embedParams?.EmbedReport[0].EmbedUrl,
                                    accessToken: this.state.embedParams?.EmbedToken.Token,
                                    tokenType: models.TokenType.Embed,
                                    settings: {
                                        panes: {
                                            bookmarks: {
                                                visible: true
                                            },
                                            fields: {
                                                expanded: true
                                            },
                                            filters: {
                                                expanded: true,
                                                visible: true
                                            },
                                            pageNavigation: {
                                                visible: true
                                            },
                                            selection: {
                                                visible: true
                                            },
                                            syncSlicers: {
                                                visible: true
                                            },
                                            visualizations: {
                                                expanded: true
                                            }
                                        },
                                        bars: {
                                            statusBar: {
                                                visible: true
                                            }
                                        }
                                    }
                                }
                            }
                            cssClassName={"dgdg-demo-page-powerbi-report-window"}
                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>;
    }
}
