import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { DGDGEventSourceComponent } from "../js_modules/dgdgjs/DGDGEventSourceComponent";

import { DGDGCarouselComponent } from "../common/dgdgcarousel/DGDGCarouselComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnCheckboxComponent } from "../common/dgdgtable/DGDGTableColumnCheckboxComponent";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableColumnInputComponent } from "../common/dgdgtable/DGDGTableColumnInputComponent";
import { DGDGTableColumnSparklineComponent } from "../common/dgdgtable/DGDGTableColumnSparklineComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DemoPageDetailPopupComponent } from "./DemoPageDetailPopupComponent";
import { DemoPageEditComponent } from "./DemoPageEditComponent";
import { DemoPageView1GlyphsComponent } from "./DemoPageView1GlyphsComponent";
import { DemoPageView1QueryComponent } from "./DemoPageView1QueryComponent";

export class DemoPageView1Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelectedAll: false,
            isV2SelectedAll: false,
            selectedRow: null,
            cancelRowData: null,
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            isSettingCache: false,
            isGettingCache: false,
            showDetailPopup: false,
            isDetailPopupLoadingData: false,
            detailPopupTableData: [],
            detailPopupPaintTable: false,
            detailPopupChartData: [["x Axis", "y Axis"], [0, 0]],
            detailPopupPaintChart: false,
            showMoneyColFooterClick: false,
            ddpData: [
                { key: "key1", value: "Value 1" },
                { key: "key2", value: "Value 2" },
                { key: "key3", value: "Value 3" },
                { key: "key4", value: "Value 4" },
                { key: "key5", value: "Value 5" }
            ]
        };

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.onV2SelectChange = this.onV2SelectChange.bind(this);
        this.onV2SelectAllChange = this.onV2SelectAllChange.bind(this);
        this.onTableDropdownChange = this.onTableDropdownChange.bind(this);
        this.onTableTextboxChange = this.onTableTextboxChange.bind(this);
        this.onTableCommentChange = this.onTableCommentChange.bind(this);
        this.onTableMoneyChange = this.onTableMoneyChange.bind(this);
        this.onTablePercentChange = this.onTablePercentChange.bind(this);
        this.onSetCacheClick = this.onSetCacheClick.bind(this);
        this.onGetCacheClick = this.onGetCacheClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelEditClick = this.onCancelEditClick.bind(this);
        this.onAlertClick = this.onAlertClick.bind(this);
        this.onWarningClick = this.onWarningClick.bind(this);
        this.onErrorClick = this.onErrorClick.bind(this);
        this.onRouteComponentExClick = this.onRouteComponentExClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onCarousel2ColumnCallback = this.onCarousel2ColumnCallback.bind(this);
        this.onCarousel3ColumnCallback = this.onCarousel3ColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionCancelClick = this.onActionCancelClick.bind(this);
        this.onShowDetailPopupClick = this.onShowDetailPopupClick.bind(this);
        this.onCancelDetailPopupClick = this.onCancelDetailPopupClick.bind(this);
        this.onFetchDownloadClick = this.onFetchDownloadClick.bind(this);
        this.onGlyphsClick = this.onGlyphsClick.bind(this);
        this.onCancelGlyphsClick = this.onCancelGlyphsClick.bind(this);
        this.onMoneyColFooterClick = this.onMoneyColFooterClick.bind(this);
        this.onMoneyColFooterDialogOkClick = this.onMoneyColFooterDialogOkClick.bind(this);
        this.onMoneyColFooterDialogCancelClick = this.onMoneyColFooterDialogCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.data1MethodMsgCallback = this.data1MethodMsgCallback.bind(this);
        this.onCancelData1MethodClick = this.onCancelData1MethodClick.bind(this);
        this.data2MethodMsgCallback = this.data2MethodMsgCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DemoPageView1Component - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DemoPage/View1", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getData();
            this.data1Method();
            this.data2Method();
            CommonService.clientAzureStorageLog("DemoPageRouteComponent", "DemoPageView1Component", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.detailPopupPaintChart) {
                this.setState({ detailPopupPaintChart: false });
            }

            if (this.data1MethodEventSource && this.props.cancelEvent1) {
                this.data1MethodEventSource.closeEventSource();
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Demo Page";
    data1MethodEventSource;
    data2MethodEventSource;

    getData() {
        this.setState({ isLoadingData: true });

        // Get data asynchronously
        let tableData = [];
        for (let index = 0; index < 30; index++) {
            let rowData = {
                sort_column_1: index,
                sort_column_2: 30 - index,
                bar_graph_1: index * Math.random() * 10,
                bar_graph_2: index * Math.random() * 10,
                bar_graph_3: index * Math.random() * 10,
                string_col: "string " + index.toString(),
                string_col_edit_description: "Edit Title - " + index.toString(),
                money_col: index,
                money_2_col: index,
                percent_col: index / 100,
                percent_2_col: index / 100,
                percent_col_background_color: "#00ff00",
                percent_col_background_color_pct: index,
                percent_2_col_color_strips: ["#FF0000", "#0000FF", "#FFFF00"],
                percent_2_background_color: "#FFFFFF",
                parent_row: {
                    child_value: "Child Row Data",
                    array_value: ["String 1", "String 2", "String 3"]
                },
            };

            if (index % 2 === 0) {
                rowData.money_2_col_cellCssName = "dgdg-font-red";
                rowData.percent_col_colSpan = 2;
            }

            tableData.push(rowData);
        }

        // Set the state in done
        tableData = CommonService.addOrdinalColumn(tableData);

        this.setState({
            isLoadingData: false,
            paintTable: true,
            tableData: tableData
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-tbl-container", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event, queryObject) {
        try {
            this.getData();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSelectAllChange(id, isChecked) {
        try {
            let tableData = this.state.tableData;
            tableData.forEach((rowData) => {
                rowData.isSelected = isChecked;
            });

            this.setState({
                isSelectedAll: isChecked,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSelectChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.isSelected = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onV2SelectAllChange(id, isChecked) {
        try {
            let tableData = this.state.tableData;
            tableData.forEach((rowData) => {
                rowData.isV2Selected = isChecked;
            });

            this.setState({
                isV2SelectedAll: isChecked,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onV2SelectChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.isV2Selected = isChecked;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTableDropdownChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.ddp_column_name = selectedItem.value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTableTextboxChange(id, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.string_col = text;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTableCommentChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.string_col = text;
            rowData.rowCssName = "dgdg-demo-bg-modified";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTableMoneyChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.money_col = text;
            rowData.rowCssName = "dgdg-demo-bg-modified";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTablePercentChange(event, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.percent_col = text;
            rowData.rowCssName = "dgdg-demo-bg-modified";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSetCacheClick(event) {
        this.setState({ isSettingCache: true });
        try {
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView1Component/onSetCacheClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/SetCache")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isSettingCache: false });
                    this.props.onShowAlert("success", { message: "Cache is set. Value: " + parsedResponse.value });
                })
                .catch(notOKResponse => {
                    this.setState({ isSettingCache: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPageView1Component/onSetCacheClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetCacheClick(event) {
        try {
            this.setState({ isGettingCache: true });
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView1Component/onGetCacheClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/GetCache")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onShowAlert("success", { message: "Current value of Cache: " + parsedResponse.value });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPageView1Component/onGetCacheClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isGettingCache: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationOkClick(event) {
        try {
            this.props.onShowAlert("success", { message: "Action Completed. Row: " + this.state.cancelRowData.string_col }, 4000);
            this.onConfirmationCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationCancelClick(event) {
        try {
            this.setState({ cancelRowData: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onShowAlert("success", { message: "Acion completed." }, 4000);
            this.onCancelEditClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelEditClick(event) {
        try {
            this.setState({ selectedRow: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAlertClick(event) {
        try {
            this.props.onShowAlert("success", { message: "Success message here..." }, 4000);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWarningClick(event) {
        try {
            this.props.onShowAlert("warning", { message: "Warning message here..." });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onErrorClick(event) {
        try {
            let object = null
            let createError = object.IdontExist;
            console.log(createError);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
            this.props.onShowAlert("danger", { message: "Error message here..." });
        }
    }

    onRouteComponentExClick(event) {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView1Component/onRouteComponentExClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/ThrowException", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPageView1Component/onRouteComponentExClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                <i className="fas fa-edit" />
            </button>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionCancelClick(event, rowData)}>
                <i className="fas fa-times text-danger dgdg-icon" />
            </button>
        </Fragment>;
    }

    onCarousel1ColumnCallback(rowData) {
        return <DGDGCarouselComponent text="Completed" status="completed" statusOut="completed" />;
    }

    onCarousel2ColumnCallback(rowData) {
        return <Fragment>
            <DGDGCarouselComponent text="Completed" status="completed" statusIn="completed" statusOut="pending" />
        </Fragment>;
    }

    onCarousel3ColumnCallback(rowData) {
        return <Fragment>
            <DGDGCarouselComponent text="Pending" status="pending" statusIn="pending" />
        </Fragment>;
    }

    onActionEditClick(event, rowData) {
        try {
            this.setState({ selectedRow: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionCancelClick(event, rowData) {
        try {
            this.setState({ cancelRowData: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowDetailPopupClick(event, rowData) {
        try {
            this.setState({
                showDetailPopup: true,
                isDetailPopupLoadingData: true
            });

            // Get data asynchronously
            let tableData = [];
            for (let index = 0; index < 15; index++) {
                let dataRow = {
                    string_col: "string " + index.toString(),
                    money_col: index,
                    money_2_col: index,
                    percent_col: index / 100,
                    percent_2_col: index / 100
                };

                tableData.push(dataRow);
            }

            // Set the state in done
            tableData = CommonService.addOrdinalColumn(tableData);

            this.setState({
                isDetailPopupLoadingData: false,
                detailPopupTableData: tableData,
                detailPopupPaintTable: true
            }, () => {
                this.getChartData();
                this.setState({ detailPopupPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getChartData() {
        setTimeout(() => { // Important setTimeout is not required, this is only a workaround to paint chart with correct width and height
            // Get data asynchronously
            let chartData = this.state.detailPopupChartData.slice(0);
            for (let index = 1; index <= 15; index++) {
                chartData.push([index, index]);
            }

            // Set the state in done
            this.setState({
                detailPopupChartData: chartData,
                detailPopupPaintChart: true
            });
        }, 1000);
    }

    onCancelDetailPopupClick(event) {
        try {
            this.setState({
                showDetailPopup: false,
                detailPopupTableData: [],
                detailPopupPaintTable: true,
                detailPopupChartData: [["x Axis", "y Axis"], [0, 0]],
                detailPopupPaintChart: true
            }, () => {
                this.setState({ detailPopupPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFetchDownloadClick(event) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                month: "03-01-2019",
                storeNumber: 1
            };

            this.props.applicationInsights.trackTrace({ message: "/DemoPageView1Component/onFetchDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isLoadingData: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPageView1Component/onFetchDownloadClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGlyphsClick(event) {
        try {
            this.setState({ showGlyphs: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelGlyphsClick(event) {
        try {
            this.setState({ showGlyphs: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMoneyColFooterClick(event) {
        this.setState({ showMoneyColFooterClick: true });
    }

    onMoneyColFooterDialogOkClick(event) {
        this.setState({ showMoneyColFooterClick: false });
    }

    onMoneyColFooterDialogCancelClick(event) {
        this.setState({ showMoneyColFooterClick: false });
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DemopageView1Component", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFileChange(event, fileUpload) {
        var formData = new FormData();
        formData.append("FileUpload", fileUpload.files[0]);
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView1Component/onFileChange", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/FileUpload", {
                method: "POST",
                headers: {
                    'enctype': "multipart/form-data"
                },
                body: formData
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPageView1Component/onFileChange", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            if (this.props.onShowAlert) {
                this.props.onShowAlert("danger", error);
            }
        }
    }

    data1Method(event) {
        this.data1MethodEventSource = new DGDGEventSourceComponent();
        this.data1MethodEventSource.addEventSource(
            "/DemoPage/Data1Method",
            "data1Event", this.data1MethodMsgCallback);
    }

    data1MethodMsgCallback(eventData) {
        this.props.onEvent1Changed(eventData);
    }

    onCancelData1MethodClick(event) {
        this.data1MethodEventSource.closeEventSource();
    }

    data2Method(event) {
        this.data2MethodEventSource = new DGDGEventSourceComponent();
        this.data2MethodEventSource.addEventSource(
            "/DemoPage/Data2Method",
            "data2Event", this.data2MethodMsgCallback);
    }

    data2MethodMsgCallback(eventData) {
        this.props.onEvent2Changed(eventData);
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Cancel Click. Row: " + (this.state.cancelRowData ? this.state.cancelRowData.string_col : "")}
                bodyText="Are you sure?"
                showConfirmation={this.state.cancelRowData !== null}
                onOkClick={this.onConfirmationOkClick}
                onCancelClick={this.onConfirmationCancelClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Money Col footer clicked!"
                bodyText="Click event"
                showConfirmation={this.state.showMoneyColFooterClick}
                onOkClick={this.onMoneyColFooterDialogOkClick}
                onCancelClick={this.onMoneyColFooterDialogCancelClick}
            />
            <DemoPageEditComponent
                applicationInsights={this.props.applicationInsights}
                rowData={this.state.selectedRow}
                onSaveClick={this.onSaveClick} onCancelClick={this.onCancelEditClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DemoPageDetailPopupComponent
                applicationInsights={this.props.applicationInsights}
                showDetailPopup={this.state.showDetailPopup}
                isLoadingData={this.state.isDetailPopupLoadingData}
                tableData={this.state.detailPopupTableData} paintTable={this.state.detailPopupPaintTable}
                chartData={this.state.detailPopupChartData} paintChart={this.state.detailPopupPaintChart}
                onCancelClick={this.onCancelDetailPopupClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DemoPageView1GlyphsComponent
                applicationInsights={this.props.applicationInsights}
                showGlyphs={this.state.showGlyphs}
                onCancelGlyphsClick={this.onCancelGlyphsClick}
            />
            <DemoPageView1QueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick}
                isSettingCache={this.state.isSettingCache} onSetCacheClick={this.onSetCacheClick} isGettingCache={this.state.isGettingCache} onGetCacheClick={this.onGetCacheClick}
                onAlertClick={this.onAlertClick} onWarningClick={this.onWarningClick} onErrorClick={this.onErrorClick} onFileChange={this.onFileChange}
                onRouteComponentExClick={this.onRouteComponentExClick} onFetchDownloadClick={this.onFetchDownloadClick}
                onGlyphsClick={this.onGlyphsClick}
                onShowAlert={this.props.onShowAlert}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table1")}>Table 1</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table2")}>Table 2</button>
            </nav>
            <div className="dgdg-tbl-container dgdg-site-scrollable-content">
                <div id="table1" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Table 1 Title - Filter - Totals" defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" tablePageSize={CommonService.tablePageSize} freezeColumn="5" showFooter stickyHeader
                        tableData={this.state.tableData} cssName="dgdg-table-v3-demo" paintTable={this.state.paintTable} copyTableConfig={{ "columns": [4, 6, 7, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td colSpan="2" className="text-center font-weight-bold"></td>
                                <td colSpan="9" className="dgdg-demo-bg-head text-center font-weight-bold dgdg-border-right">Table Header 1</td>
                                <td colSpan="8" className="dgdg-demo-bg-head text-center font-weight-bold">Table Header 2</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent colSpan="2" cssName="dgdg-border-right" />
                            <DGDGTableColumnComponent dataColumnCallback={this.onCarousel1ColumnCallback} />
                            <DGDGTableColumnComponent dataColumnCallback={this.onCarousel2ColumnCallback} />
                            <DGDGTableColumnComponent dataColumnCallback={this.onCarousel3ColumnCallback} />
                            <DGDGTableColumnComponent colSpan="6" cssName="dgdg-border-right" />
                            <DGDGTableColumnComponent colSpan="7" />
                        </DGDGTableRowComponent>
                        <DGDGTableRowComponent hasHeader>
                            <DGDGTableColumnCheckboxComponent dataColumn="isSelected" selectAll={this.state.isSelectedAll} sortColumn="isSelected" dataType="bool" onChange={this.onSelectChange} onChangeAll={this.onSelectAllChange} />
                            <DGDGTableColumnCheckboxV2Component headerText="Select" dataColumn="isV2Selected" selectAll={this.state.isV2SelectedAll} sortColumn="isV2Selected" dataType="bool" cssName="dgdg-border-right" footerCssName="dgdg-border-right" onChange={this.onV2SelectChange} onChangeAll={this.onV2SelectAllChange} />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" headerCssName="dgdg-demo-action-column" headerColSpan="3" />
                            <DGDGTableColumnComponent />
                            <DGDGTableColumnComponent />
                            <DGDGTableColumnDropdownComponent headerText="Dropdown" dataColumn="ddp_column_name" data={this.state.ddpData} itemKey="value" onItemClick={this.onTableDropdownChange} columnHelp="Help for dropdown column" />
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" filterColumn={CommonService.ordinalColumnName} columnHelp="Help for ordinal column"
                                sortColumn={[
                                    { displayName: "Sort Column 1", column: "sort_column_1" },
                                    { displayName: "Sort Column 2", column: "sort_column_2" }
                                ]}
                            />
                            <DGDGTableColumnSparklineComponent headerText="Graph" valueFields={["bar_graph_1", "bar_graph_2", "bar_graph_3"]} options={{ type: "bar" }} columnHelp="Help for sparkline column" />
                            <DGDGTableColumnInputComponent headerText="Input" dataColumn="string_col" onChange={this.onTableTextboxChange} />
                            <DGDGTableColumnComponent headerText="String" dataColumn="string_col" sortColumn="string_col" filterColumn="string_col" cssName="text-nowrap" editHeaderColumn="string_col_edit_description" dataType="comment" maxLength={7} isEditable onChange={this.onTableCommentChange} />
                            <DGDGTableColumnComponent headerText="Array" dataColumn="parent_row.array_value" headerCssName="dgdg-border-right" cssName="dgdg-border-right" footerCssName="dgdg-border-right" />
                            <DGDGTableColumnComponent headerText="Sub Page" dataText="<b>Show</b> popup" onCellClick={this.onShowDetailPopupClick} />
                            <DGDGTableColumnDrCrComponent headerText="Dr/Cr" dataColumn="money_col" sortColumn="money_col" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Money" dataColumn="money_col" sortColumn="money_col" filterColumn="money_col" dataType="money" cssName="text-nowrap dgdg-font-underline" footerFunction="sum" onFooterCellClick={this.onMoneyColFooterClick} isEditable isDrCr onChange={this.onTableMoneyChange} />
                            <DGDGTableColumnComponent headerText="Money 2" dataColumn="money_2_col" sortColumn="money_2_col" filterColumn="money_2_col" dataType="money_2" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Percent" dataColumn="percent_col" sortColumn="percent_col" filterColumn="percent_col" dataType="percent" footerFunction="average" cssName="text-nowrap" ofDataColumn="percent_col" backgroundColor="percent_col_background_color" backgroundColorPct="percent_col_background_color_pct" isEditable onChange={this.onTablePercentChange} />
                            <DGDGTableColumnComponent headerText="Percent 2" dataColumn="percent_2_col" sortColumn="percent_2_col" filterColumn="percent_2_col" dataType="percent_2" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Color Strip" dataColumn="percent_2_col" sortColumn="percent_2_col" filterColumn="percent_2_col" dataType="percent_2" footerFunction="average" backgroundColor="percent_2_background_color" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div id="table2">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Table 2 Title - Filter - Totals" defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" tablePageSize={CommonService.tablePageSize} freezeColumn="3" showFooter
                        tableData={this.state.tableData} paintTable={this.state.paintTable}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="String" dataColumn="string_col" sortColumn="string_col" filterColumn="string_col" cssName="text-nowrap" editHeaderColumn="string_col_edit_description" dataType="comment" maxLength={7} isEditable onChange={this.onTableCommentChange} />
                            <DGDGTableColumnComponent headerText="Complex Json" dataColumn="parent_row.child_value" />
                            <DGDGTableColumnComponent headerText="Array" dataColumn="parent_row.array_value" />
                            <DGDGTableColumnDrCrComponent headerText="Dr/Cr" dataColumn="money_col" sortColumn="money_col" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Money" dataColumn="money_col" sortColumn="money_col" filterColumn="money_col" dataType="money" footerFunction="sum" cssName="text-nowrap" isEditable isDrCr onChange={this.onTableMoneyChange} />
                            <DGDGTableColumnComponent headerText="Money 2" dataColumn="money_2_col" sortColumn="money_2_col" filterColumn="money_2_col" dataType="money_2" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Percent" dataColumn="percent_col" sortColumn="percent_col" filterColumn="percent_col" dataType="percent" footerFunction="average" cssName="text-nowrap" ofDataColumn="percent_col" backgroundColor="percent_col_background_color" backgroundColorPct="percent_col_background_color_pct" isEditable onChange={this.onTablePercentChange} />
                            <DGDGTableColumnComponent headerText="Percent 2" dataColumn="percent_2_col" sortColumn="percent_2_col" filterColumn="percent_2_col" dataType="percent_2" footerFunction="average" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}
/*
 // Browser History code snippet
 //Adding to browser history
 if (this.props.history &&
    (this.props.location.state?.selectedStore !== JSON.stringify(selectedStore) || this.props.location.state?.startDate !== JSON.stringify(startDate))
) {
    this.props.history.push("/EmployeeTimeTrack", { selectedStore: JSON.stringify(selectedStore), startDate: JSON.stringify(startDate) });
}

// this function is called when browser back button is clicked. Put it inside componentDidMount
window.onpopstate = () => {
                if (this.props.location && this.props.location.state?.selectedStore && this.props.location.state?.startDate) {
                    let store = JSON.parse(this.props.location.state.selectedStore);
                    let startDate = JSON.parse(this.props.location.state.startDate);
                    this.setState({
                        startDate: moment(startDate),
                        selectedStore: store
                    }, this.onGetReportClick);
                }
            }
 */